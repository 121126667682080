import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormGroup } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { SkupremeModal } from '../../../components';
import {
  ControlledTextFormField,
  DropdownFormField,
  HoverBorderButton,
  NumberFormField,
  StyledButton,
} from '../../../elements';
import {
  CommonUtility,
  decimalMask,
  measurementOptions,
  ToastMessage,
  ZoneService,
} from '../../../utility';
import { WarehouseZoneTypesHook } from '../../../hooks/zone';

const ZoneSchema = yup.object().shape({
  type: yup.string().required('Type is required'),
  name: yup.string(),
  measurementUnit: yup.string().required('Measurement Unit is required'),
  length: yup
    .number()
    .positive('Length must be greater than 0')
    .typeError('Length is required')
    .required('Length is required'),
  width: yup
    .number()
    .positive('Width must be greater than 0')
    .typeError('Width is required')
    .required('Width is required'),
  height: yup
    .number()
    .positive('Height must be greater than 0')
    .typeError('Height is required')
    .required('Height is required'),
});

export function ZonePopup({
  warehouseId,
  warehouseData,
  warehouseDataLoading,
  refresh,
  open,
  onClose,
}) {
  const { data, loading: optionsLoading } = WarehouseZoneTypesHook();
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(ZoneSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (CommonUtility.isValidObject(warehouseData)) {
      if (data.dimensions?.measurementUnit) {
        setIsDefaultSet(true);
        setValue('measurementUnit', data.dimensions?.measurementUnit);
      }
    }
  }, [warehouseData]);

  const onSubmit = async formData => {
    try {
      setLoading(true);
      let payload = {
        warehouseId,
        name: formData.name,
        type: formData.type,
        dimensions: {
          length: formData.length,
          width: formData.width,
          height: formData.height,
          measurementUnit: formData.measurementUnit,
        },
      };
      payload = CommonUtility.removeEmptyFields(payload);
      await ZoneService.add(payload);
      ToastMessage.success('Zone successfully created.');
      onClose();
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkupremeModal
      size="small"
      open={open}
      onClose={onClose}
      title="Add New Zone"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup widths="equal">
          <DropdownFormField
            control={control}
            name="type"
            label="Type"
            error={errors.type}
            hint={errors.type?.message}
            loading={optionsLoading}
            options={data}
            selection
          />
          <DropdownFormField
            control={control}
            name="measurementUnit"
            label="Measurement Unit"
            error={errors.measurementUnit}
            hint={errors.measurementUnit?.message}
            options={measurementOptions}
            selection
            loading={warehouseDataLoading}
            disabled={isDefaultSet}
          />
          <ControlledTextFormField
            control={control}
            name="name"
            label="Name"
            error={errors.name}
            hint={errors.name?.message}
          />
        </FormGroup>
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            name="length"
            label="Length"
            maskOptions={decimalMask}
            error={errors.length}
            hint={errors.length?.message}
          />
          <NumberFormField
            control={control}
            name="width"
            label="Width"
            maskOptions={decimalMask}
            error={errors.width}
            hint={errors.width?.message}
          />
          <NumberFormField
            maskOptions={decimalMask}
            control={control}
            name="height"
            label="Height"
            error={errors.height}
            hint={errors.height?.message}
          />
        </FormGroup>
        <div className="d-flex justify-content-end">
          <HoverBorderButton type="button" onClick={onClose}>
            Cancel
          </HoverBorderButton>
          <StyledButton type="submit" loading={loading} className="ml-1">
            Create
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
