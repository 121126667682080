import styled from 'styled-components';
import { Plus } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { ShadowBox } from '../../elements';
import { CommonUtility } from '../../utility';

const AisleContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  padding: 10px;
  position: relative;
  display: flex;

  .name {
    position: absolute;
    background: white;
    top: -10px;
    padding: 0 5px;
  }

  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
`;

const RackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 80px;
  background: ${({ theme }) => theme.colors.box};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  padding: 0 5px;
  color: black;
`;

function AddRack(props) {
  return (
    <RackContainer {...props}>
      <Plus /> Rack
    </RackContainer>
  );
}

const AilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

export function RackViewer({
  data,
  addRackInAsile,
  aloneRacks,
  aisleView,
  loading,
}) {
  if (
    (!CommonUtility.isValidArray(data) &&
      !CommonUtility.isValidArray(aloneRacks)) ||
    loading
  ) {
    return (
      <ShadowBox className="mt-2">
        {loading ? <Loader inline active /> : 'Nothing to Show'}
      </ShadowBox>
    );
  }

  if (!aisleView) {
    return (
      <ShadowBox className="mt-2">
        <AilesContainer>
          <AisleContainer>
            {data?.map(aisle =>
              aisle.racks?.map(rack => (
                <Link to={`racks/${rack._id}`}>
                  <RackContainer>{rack.code}</RackContainer>
                </Link>
              )),
            )}
            {CommonUtility.isValidArray(aloneRacks) &&
              aloneRacks.map(rack => (
                <Link to={`racks/${rack._id}`}>
                  <RackContainer>{rack.code}</RackContainer>
                </Link>
              ))}
          </AisleContainer>
        </AilesContainer>
      </ShadowBox>
    );
  }

  return (
    <ShadowBox className="mt-2">
      <AilesContainer>
        {data?.map(aisle => (
          <AisleContainer>
            <span className="name">{aisle.code}</span>
            {CommonUtility.isValidArray(aisle.racks) &&
              aisle.racks?.map(rack => (
                <Link to={`racks/${rack._id}`}>
                  <RackContainer>{rack.code}</RackContainer>
                </Link>
              ))}
            <AddRack onClick={() => addRackInAsile(aisle)} />
          </AisleContainer>
        ))}
        {CommonUtility.isValidArray(aloneRacks) && (
          <AisleContainer>
            <span className="name">No Aisle</span>
            {aloneRacks.map(rack => (
              <Link to={`racks/${rack._id}`}>
                <RackContainer>{rack.code}</RackContainer>
              </Link>
            ))}
            <AddRack onClick={() => addRackInAsile()} />
          </AisleContainer>
        )}
      </AilesContainer>
    </ShadowBox>
  );
}
