import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';
import { useMemo } from 'react';
import { BodyContainer, HeaderBar, PageTitle } from '../../../elements';
import { CommonUtility, Tabs } from '../../../utility';
import { TabStyleMenu } from '../../../elements/Tab';
import { InventoryImportTab } from './import';
import { InventoryImportFeedTab } from './feed';

function Header() {
  return (
    <HeaderBar className="row tab-layout">
      <div className="col-6 pt-2">
        <PageTitle>Inventory</PageTitle>
      </div>
    </HeaderBar>
  );
}

const TabContainer = styled.div`
  margin-top: 30px;
`;

export function InventoryDetailScreen() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentTab = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 4);
    }
    return null;
  }, [pathname]);

  const tabClicked = tabName => {
    navigate(`/app/inventory/import/${tabName}`);
  };

  const menus = [
    {
      text: 'Import',
      value: Tabs.InventoryTabs.new,
    },
    {
      text: 'Feed',
      value: Tabs.InventoryTabs.feed,
    },
  ];

  return (
    <BodyContainer>
      <Header />
      <TabStyleMenu
        menus={menus}
        currentMenu={currentTab}
        onTabChange={tabClicked}
      />
      <TabContainer>
        <Routes>
          <Route index element={<Navigate to="new" />} />
          <Route path="new" element={<InventoryImportTab />} />
          <Route path="feed" element={<InventoryImportFeedTab />} />
        </Routes>
      </TabContainer>
    </BodyContainer>
  );
}
