import { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import { CommonConstant } from '../utility/constants';

export function SemanticImageWithFallback({
  fallback = CommonConstant.defaultImage,
  src,
  ...rest
}) {
  const [imgSrc, setImgSrc] = useState(src);
  const onError = () => setImgSrc(fallback);
  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return <Image alt="Skupreme" src={imgSrc || fallback} onError={onError} {...rest} />;
}
