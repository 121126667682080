import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Image, Label } from 'semantic-ui-react';
import * as yup from 'yup';
import styled from 'styled-components';
import OtpInput from 'react-otp-input';
import { Link, useNavigate } from 'react-router-dom';
import { FlexRow, ShadowBox } from '../elements/Common';
import { GreyLink, StyledButton } from '../elements/Button';
import {
  ControlledTextFormField,
  PasswordFormField,
} from '../elements/FormField';
import { ErrorMessageConstant } from '../utility/constants';
import { ToastMessage } from '../utility/toast';
import { UserService } from '../utility/services/user';
import { Images } from '../images';
import { useAuth } from '../contexts/auth';

const LoginContainer = styled.div`
  min-height: 100vh;
  position: relative;
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .verificationContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
  }

  .verificationContainer > div {
    flex: 1;
    padding: 4px;
  }

  .verificationInputContainer {
    display: flex;
    flex: 1;
    min-height: 60px;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 1.5em;
  }

  .verificationContainerMobile {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
  }

  .verificationContainerMobile > div {
    flex: 1;
    padding: 1px;
  }

  .verificationContainerMobile {
    display: flex;
    flex: 1;
    min-height: 60px;
    font-weight: bold;
    line-height: 23px;
    margin-bottom: 1.5em;
  }
`;

const PageContainer = styled.div`
  background: ${({ theme }) => theme.colors.box};
`;

const Box = styled(ShadowBox)`
  padding: 3em 2em;
  width: 432px;
`;

const LoginText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 1.5em;
`;

const Logo = styled(Image)`
  height: auto;
  width: 220px;
`;

const LogoContainer = styled.a`
  z-index: 9;
  margin-bottom: 50px;
`;

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Enter a valid email'),
  password: yup.string().required('Password is required'),
});

const LoginBottomRow = styled(FlexRow)`
  justify-content: space-between;
  margin: 1.5rem 0;
`;

function VerificationBox({
  verificationToken,
  handleVerificationChanged,
  formLoading,
  loading,
  handleVerificationSubmit,
  error,
  email,
  retry,
  verificationMessage,
}) {
  return (
    <Box>
      <LoginText>Further verification required</LoginText>
      <h5 className="mb-3">
        {verificationMessage || `A verification code has been sent to ${email}`}
      </h5>
      <Form loading={formLoading} onSubmit={handleVerificationSubmit}>
        <OtpInput
          value={verificationToken}
          onChange={handleVerificationChanged}
          numInputs={7}
          containerStyle="verificationContainer"
          inputStyle="verificationInputContainer"
          renderInput={props => <input {...props} />}
          renderSeparator={<span className="px-1" />}
        />
        <StyledButton loading={loading} fluid type="submit">
          Submit
        </StyledButton>

        {error && (
          <Label as="h4" color="red">
            {error}
          </Label>
        )}
      </Form>
      <LoginBottomRow>
        <GreyLink onClick={retry}>
          Did not receive an email? Try logging in again.
        </GreyLink>
      </LoginBottomRow>
    </Box>
  );
}

VerificationBox.displayName = 'VerificationBox';

function LoginBox({
  loading,
  loginClicked,
  errors,
  error,
  formLoading,
  control,
}) {
  return (
    <Box>
      <LoginText>Welcome Back</LoginText>
      <Form loading={formLoading} onSubmit={loginClicked}>
        <ControlledTextFormField
          error={errors.email}
          label="Email Address"
          name="email"
          control={control}
          hint={errors?.email?.message}
          required
        />
        <PasswordFormField
          error={errors.password}
          label="Password"
          name="password"
          control={control}
          hint={errors?.password?.message}
          required
        />

        {error && (
          <Label as="h4" color="red" data-testid="error">
            {error}
          </Label>
        )}

        <StyledButton loading={loading} fluid type="submit">
          Login
        </StyledButton>
      </Form>
      <div className="d-flex justify-content-center mt-3">
        <p>New to Skupreme?</p>
        <Link to="/register" className="ml-1">
          Sign Up
        </Link>
      </div>
    </Box>
  );
}
LoginBox.displayName = 'LoginBox';

export function LoginScreen() {
  const [formLoading, setFormLoading] = useState(true);
  const [verificationRequired, setVerificationRequired] = useState(false);
  const [error, setError] = useState('');
  const [logging, setLogging] = useState(false);
  const [email, setEmail] = useState('');
  const [verificationToken, setVerificationToken] = useState('');
  const [verificationMessage, setVerificationMessage] = useState('');
  const { setUserData } = useAuth();
  const navigate = useNavigate();

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(LoginSchema),
    shouldUnregister: true,
  });

  const onSubmit = async data => {
    try {
      setLogging(true);
      setError('');

      const response = await UserService.login(data);
      if (response.verificationRequired) {
        setEmail(data.email);
        setVerificationMessage(response.message);
        setVerificationRequired(true);
      } else {
        UserService.storeUser(response);
        setTimeout(() => {
          setUserData(response);
          navigate('/app/dashboard');
        }, 200);
      }
    } catch (error) {
      setError(error.error || ErrorMessageConstant.defaultAPIError);
      ToastMessage.error(error.error || ErrorMessageConstant.defaultAPIError);
    } finally {
      setLogging(false);
    }
  };

  const handleVerificationChanged = async otp => {
    setError('');
    setVerificationToken(otp.toUpperCase());
  };

  const handleVerificationSubmit = async () => {
    try {
      setLogging(true);
      setError('');

      const response = await UserService.verify({
        token: verificationToken,
      });

      UserService.storeUser(response);

      setTimeout(() => {
        setUserData(response);
        navigate('/app/dashboard');
      }, 200);
    } catch (error) {
      setError(error.error || ErrorMessageConstant.defaultAPIError);
    } finally {
      setLogging(false);
    }
  };

  const retry = () => {
    setError('');
    setLogging(false);
    setVerificationRequired(false);
  };

  useEffect(() => {
    if (UserService.isAuthenticated()) {
      navigate('/app/dashboard');
    }
    setFormLoading(false);
  }, []);

  return (
    <PageContainer>
      <LoginContainer>
        <LogoContainer>
          <Logo src={Images.blackLogo} />
        </LogoContainer>
        {!!verificationRequired && (
          <VerificationBox
            email={email}
            error={error}
            formLoading={formLoading}
            handleVerificationChanged={handleVerificationChanged}
            handleVerificationSubmit={handleVerificationSubmit}
            loading={logging}
            retry={retry}
            verificationMessage={verificationMessage}
            verificationToken={verificationToken}
          />
        )}
        {!verificationRequired && (
          <LoginBox
            control={control}
            errors={errors}
            loading={logging}
            loginClicked={handleSubmit(onSubmit)}
          />
        )}
      </LoginContainer>
    </PageContainer>
  );
}
