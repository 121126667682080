import { Navigate } from 'react-router-dom';
import { LoaderBar } from '../components/LoaderBar';
import { useAuth } from '../contexts/auth';

export function AuthGuard({ redirectPath = '/', children }) {
  const { loading, isAuthenticated } = useAuth();
  if (loading) {
    return <LoaderBar />;
  }
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
}
