import { TableCell, TableRow } from 'semantic-ui-react';
import styled from 'styled-components';
import { ArrowClockwise, Copy, Trash } from '@phosphor-icons/react';
import { ActionEditDots, ActionMenuItem } from '../../../components';
import { CommonUtility } from '../../../utility';

const ActionEditTableRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    img.m-auto {
      opacity: 0.3;
    }
    .action-container {
      opacity: 1 !important;
    }
  }
`;

export function InviteRow({ item, resendInvite, removeInvite }) {
  return (
    <ActionEditTableRow>
      <TableCell>{item.firstName}</TableCell>
      <TableCell>{item.email}</TableCell>
      <TableCell>{item.role}</TableCell>
      <TableCell>
        <ActionEditDots preventChangingBg on="hover" position="left center">
          <ActionMenuItem onClick={() => resendInvite(item._id)}>
            <ArrowClockwise />
            Resend Invitation
          </ActionMenuItem>
          <ActionMenuItem
            onClick={() => CommonUtility.copyToClipboard(item.link)}
          >
            <Copy />
            Copy Invitation Link
          </ActionMenuItem>
          <ActionMenuItem onClick={() => removeInvite(item)} className="danger">
            <Trash />
            Delete Invitation
          </ActionMenuItem>
        </ActionEditDots>
      </TableCell>
    </ActionEditTableRow>
  );
}
