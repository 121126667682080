import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonUtility } from '../utility';

const filterTypes = {
  is: 'EQUAL',
  isNot: 'NOT_EQUAL',
};

export const FilterHook = (filters, tableFilter) => {
  const navigate = useNavigate();

  const [finalValue, setFinalValue] = useState({});
  const [activeIndex, setActiveIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!CommonUtility.isValidArray(filters)) {
      return;
    }

    const urlObject = {};

    Object.keys(tableFilter).forEach(filter => {
      const findFilter = filters.find(x => x.name === filter);
      if (filter === 'sort') {
        const sortValue = JSON.stringify(tableFilter[filter]);
        if (sortValue !== JSON.stringify(findFilter?.ignoreDefault)) {
          urlObject[filter] = sortValue;
        }
      } else if (
        tableFilter[filter] &&
        !(filter.includes('op') && tableFilter[filter] === filterTypes.is) &&
        findFilter?.ignoreDefault !== tableFilter[filter]
      ) {
        urlObject[filter] = tableFilter[filter];
      }
    });

    navigate(
      {
        pathname: '',
        search: new URLSearchParams(urlObject).toString(),
      },
      {
        replace: true,
      },
    );
  }, [tableFilter, filters]);

  const removeFilter = name => {
    const filter = filters.find(x => x.name === name);
    const newUserValue = { ...finalValue };
    if (filter) {
      let value = '';
      if (filter.multiple) {
        value = [];
      }
      newUserValue[filter.name] = {
        value,
        type: filterTypes.is,
        options: filter.options,
        filterType: filterTypes.is,
        typeQuery: filter.typeQuery,
        skip: !!filter.skip,
      };
    }
    setFinalValue(newUserValue);
  };

  const openRelevantFilter = index => {
    setOpen(true);
    setActiveIndex(index);
  };

  const pillFilters = useMemo(
    () =>
      filters?.filter?.(x => {
        if (x.multiple) {
          return !!finalValue[x.name]?.value?.length > 0 && !x.skip;
        }
        return !!finalValue[x.name]?.value && !x.skip;
      }),
    [finalValue, filters],
  );

  return {
    finalValue,
    setFinalValue,
    activeIndex,
    setActiveIndex,
    open,
    setOpen,
    pillFilters,
    openRelevantFilter,
    removeFilter,
  };
};
