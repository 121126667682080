import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useSidebar } from '../contexts/sidebar';
import { useSmallScreenAndBelowMediaQuery } from './ResponsiveMedia';

const BoxContainer = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.colors.primary};
`;

const ContentContainer = styled.div`
  padding: ${({ padding }) => padding || 0}px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.2s;
  background: ${({ theme }) => theme.colors.box};
  border-radius: 0px 0px 10px 10px;
  margin-left: ${({ isCollapsed }) => (isCollapsed ? '50px' : '235px')};

  margin-top: 67px;
  max-height: calc(100vh - 87px);
  min-height: calc(100vh - 87px);

  &.tab-layout {
    max-height: calc(100vh - 120px);
    min-height: calc(100vh - 120px);
    margin-top: 100px;
  }

  &.no-header {
    border-radius: 10px;
    margin-top: 0px;
    max-height: calc(100vh - 20px);
    min-height: calc(100vh - 20px);
  }
`;

const SimpleLayout = styled.div`
  background: ${({ theme }) => theme.colors.box};
  min-height: 100vh;
`;

export function AppLayout({ hideSidebar }) {
  const { isCollapsed } = useSidebar();
  const isTabletAndBelow = useSmallScreenAndBelowMediaQuery();

  if (hideSidebar) {
    return (
      <SimpleLayout>
        <Outlet />
      </SimpleLayout>
    );
  }

  return (
    <BoxContainer>
      <ContentContainer isCollapsed={isCollapsed || isTabletAndBelow}>
        <Outlet />
      </ContentContainer>
    </BoxContainer>
  );
}
