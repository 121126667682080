import { APIPath } from '../constants';
import { CRUDService } from './crud';

class Account extends CRUDService {
  constructor() {
    super(APIPath.account);
  }
}

const AccountService = new Account();
Object.freeze(AccountService);
export { AccountService };
