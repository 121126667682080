import { toast } from 'react-toastify';
import { NotificationRenderer } from '../components/Notifications';
import { ErrorMessageConstant } from './constants';

export class ToastMessage {
  static defaultOptions = {};

  static success = (message, iconType, options = {}) => {
    const finalOptions = Object.assign(options, this.defaultOptions);
    const notificationProps = { message, iconType };
    toast.success(
      <NotificationRenderer {...notificationProps} />,
      finalOptions,
    );
  };

  static error = (message, iconType, options = {}) => {
    const finalOptions = Object.assign(options, this.defaultOptions);
    const notificationProps = { message, iconType };
    toast.error(<NotificationRenderer {...notificationProps} />, finalOptions);
  };

  static warning = (message, iconType, options = {}) => {
    const finalOptions = Object.assign(options, this.defaultOptions);
    const notificationProps = { message, iconType };
    toast.warn(<NotificationRenderer {...notificationProps} />, finalOptions);
  };

  static apiError = err => {
    const { error, message } = err;
    const errorMessage =
      typeof error === 'string'
        ? error
        : typeof message === 'string'
        ? message
        : ErrorMessageConstant.defaultAPIError;

    this.error(errorMessage);
  };

  static info = (message, iconType, options = {}) => {
    const finalOptions = Object.assign(options, this.defaultOptions);
    const notificationProps = { message, iconType };
    toast.info(
      <NotificationRenderer defaultNotification {...notificationProps} />,
      finalOptions,
    );
  };

  static clear = () => {
    toast.dismiss();
  };
}
