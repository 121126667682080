import { X } from '@phosphor-icons/react';
import { useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { FlexRow, HoverBorderButton, StyledButton } from '../elements';
import { getCropPreview } from '../utility/crop-file';

const ButtonRow = styled(FlexRow)`
  justify-content: flex-end;
  gap: 4px;
  margin-top: 12px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
`;

const ModalDescription = styled(Modal.Description)`
  padding: 12px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Img = styled.img`
  max-height: calc(100vh - 300px) !important;
  max-width: 100% !important;
  object-fit: contain !important;
`;

export function ImageCropModal({
  src,
  fileName,
  upload,
  open,
  setOpen,
  altText,
  initialCrop,
  aspect,
}) {
  const [crop, setCrop] = useState(initialCrop);
  const [completedCrop, setCompletedCrop] = useState(initialCrop);
  const ref = useRef();

  const handleSubmit = async () => {
    if (ref?.current) {
      try {
        const canvas = await getCropPreview(ref.current, completedCrop);
        canvas.toBlob(blob => {
          const file = new File([blob], fileName, { type: 'image/png' });
          setOpen(false);
          upload([file]);
          setCrop(initialCrop);
          setCompletedCrop(initialCrop);
        }, 'image/png');
      } catch (e) {
        console.log(e, 'Error while cropping an image');
      }
    }
  };

  const setFilteredCrop = crop => {
    if (!crop.width || !crop.height) return;

    setCrop(crop);
  };

  return (
    <Modal open={open}>
      <ModalDescription>
        <ModalHeader>
          <X
            className="cursor-pointer"
            size={20}
            onClick={() => setOpen(false)}
          />
        </ModalHeader>
        <ImageWrapper>
          <ReactCrop
            crop={crop}
            onComplete={c => setCompletedCrop(c)}
            onChange={c => setFilteredCrop(c)}
            aspect={aspect}
          >
            <Img ref={ref} src={src} alt={altText} />
          </ReactCrop>
        </ImageWrapper>
        <ButtonRow>
          <HoverBorderButton onClick={() => setOpen(false)}>
            Cancel
          </HoverBorderButton>
          <StyledButton onClick={async () => await handleSubmit()}>
            Save
          </StyledButton>
        </ButtonRow>
      </ModalDescription>
    </Modal>
  );
}
