import styled from 'styled-components';
import { DarkPopup } from '../../components';

export const DesktopSidebar = styled.div`
  position: fixed;
  display: flex;
  z-index: 999;
  flex-direction: column;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  transition: all 0.2s;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  flex: 1;
  padding-bottom: 202px;
  :not(.selectable-all) {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  span,
  a {
    text-align: center;
  }
`;

export const MenuIconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primaryLight};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey};
  border-radius: 5px;
  margin-bottom: 5px;
  &.active {
    background: ${({ theme }) => theme.colors.lightBlack};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const EmailContainer = styled.div`
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.grey};
`;

const PopupContainer = styled.div`
  white-space: nowrap;
`;

export function EmailDisplay({ email }) {
  if (email?.length < 30) {
    return <EmailContainer>{email}</EmailContainer>;
  }

  return (
    <DarkPopup
      trigger={
        <EmailContainer className="cursor-pointer">{email}</EmailContainer>
      }
    >
      <PopupContainer>{email}</PopupContainer>
    </DarkPopup>
  );
}
