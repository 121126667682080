import styled from 'styled-components';
import { GearSix, SignOut } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { MenuIconContainer } from './Menu';
import { DarkPopup } from '../../components';

const FooterBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 5px;
  padding: 10px;
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.black};
  width: 60px;
  overflow-x: hidden;
  box-sizing: border-box;
  transition: all 0.2s;
`;

export function Footer({ logoutClick }) {
  return (
    <FooterBar>
      <Link to="/app/settings">
        <MenuIconContainer>
          <GearSix size={28} />
        </MenuIconContainer>
      </Link>
      <DarkPopup
        trigger={
          <MenuIconContainer onClick={logoutClick}>
            <SignOut size={28} />
          </MenuIconContainer>
        }
      >
        Sign Out
      </DarkPopup>
    </FooterBar>
  );
}
