import { ShadowBox } from '../../elements';
import { BorderLessTabs } from '../../elements/Tab';
import { ShipmentInfo } from './RightTabs';

export function DetailsRightTab({ data, loading }) {
  const tabs = [
    {
      menuItem: 'Info',
      render: () => <ShipmentInfo data={data} loading={loading} />,
    },
  ];

  return (
    <ShadowBox className="tab-right p-0">
      <BorderLessTabs
        menu={{ secondary: true, pointing: true, attached: 'top' }}
        panes={tabs}
      />
    </ShadowBox>
  );
}
