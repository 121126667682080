import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { CommonUtility, InvitesService, ToastMessage } from '../../../utility';
import { SkupremeModal } from '../../../components';
import {
  ControlledTextFormField,
  DropdownFormField,
  HoverBorderButton,
  StyledButton,
} from '../../../elements';

const WarehouseSchema = yup.object().shape({
  firstName: yup.string().trim().required('Company Name is Required.'),
  lastName: yup.string().trim(),
  role: yup.string().required('Role is Required'),
  email: yup
    .string()
    .trim()
    .email('Enter a valid email')
    .required('Email is Required'),
});

export function AddUserPopup({ open, onClose, refresh }) {
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(WarehouseSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    setValue('role', 'admin');
  }, []);

  const [loading, setLoading] = useState(false);

  const onSubmit = async formValues => {
    try {
      setLoading(true);
      let payload = {
        ...formValues,
      };
      payload = CommonUtility.removeEmptyFields(payload);
      await InvitesService.add(payload);
      ToastMessage.success('User invited successfully');
      onClose();
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkupremeModal size="tiny" title="Add User" open={open} onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal" className="mb-1">
          <ControlledTextFormField
            error={errors.firstName}
            hint={errors.firstName?.message}
            label="First Name"
            name="firstName"
            control={control}
            required
          />
          <ControlledTextFormField
            error={errors.lastName}
            hint={errors.lastName?.message}
            label="Last Name"
            name="lastName"
            control={control}
          />
        </Form.Group>
        <Form.Group widths="equal" className="mb-1">
          <ControlledTextFormField
            error={errors.email}
            hint={errors.email?.message}
            label="Email Address"
            name="email"
            control={control}
            required
          />
          <DropdownFormField
            error={errors.role}
            hint={errors.role?.message}
            label="User Role"
            name="role"
            control={control}
            options={[
              {
                text: 'Admin',
                value: 'admin',
              },
            ]}
            required
          />
        </Form.Group>

        <div className="d-flex justify-content-end">
          <HoverBorderButton onClick={onClose} disabled={loading} type="button">
            Cancel
          </HoverBorderButton>
          <StyledButton loading={loading} className="ml-2" type="submit">
            Create
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
