import { useEffect, useState } from 'react';
import { ListService } from '../utility/services';
import { dropdownOptionsIncludeFlag } from '../elements/Flag';

export const countryListHook = inOrder => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetch = async () => {
    try {
      setLoading(true);
      let result = await ListService.country();
      if (inOrder) {
        result = result.sort((a, b) => (a.order || 500) - (b.order || 500));
      }
      const list = (result || []).map(x => ({
        key: x.code,
        text: x.name,
        value: x.code,
        flag: x.code.toLowerCase(),
      }));
      setData(dropdownOptionsIncludeFlag(list));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return { data, loading };
};
