export const unitConstants = {
  cmInInches: 2.5399986,
  KiloInlb: 2.20462,
  OuncesInGrams: 28.3495,
  FluidOuncesInMilliliters: 29.5735,
  GallonsInLiters: 3.78541,
};

export const units = {
  liters: 'L',
  units: 'units',
  gallons: 'gal',
  milliLiters: 'mL',
  fluidOunces: 'fl oz',
  kiloGrams: 'kg',
  pounds: 'lb',
  grams: 'g',
  ounces: 'oz',
  centimeters: 'cm',
  inches: 'in',
};
