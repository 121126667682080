import React, { createRef, useCallback, useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: '',
  allowDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
};

export function NumberMaskInput({
  maskOptions,
  minValue,
  maxValue,
  value: passedValue,
  onChange,
  ...inputProps
}) {
  const value = ['number', 'string'].includes(typeof passedValue)
    ? passedValue
    : 0;
  const ref = createRef();
  const numberMask = createNumberMask({
    ...defaultMaskOptions,
    ...(maskOptions || {}),
  });

  useEffect(() => {
    ref.current?.initTextMask();
  }, [maxValue, minValue]);

  const onPipe = useCallback(
    confirmedValue => {
      const numberToTest = confirmedValue.replace(/,/g, '');
      if (minValue === undefined && maxValue === undefined) {
        return confirmedValue;
      }
      if (
        minValue !== undefined &&
        maxValue !== undefined &&
        !isNaN(numberToTest) &&
        Number(numberToTest) >= minValue &&
        Number(numberToTest) <= maxValue
      ) {
        return confirmedValue;
      }
      if (
        minValue !== undefined &&
        maxValue === undefined &&
        !isNaN(numberToTest) &&
        Number(numberToTest) >= minValue
      ) {
        return confirmedValue;
      }
      if (
        maxValue !== undefined &&
        minValue === undefined &&
        !isNaN(numberToTest) &&
        Number(numberToTest) <= maxValue
      ) {
        return confirmedValue;
      }
      if (
        confirmedValue === ' ' ||
        confirmedValue === '-' ||
        confirmedValue === '_'
      ) {
        return confirmedValue;
      }
      return false;
    },
    [maxValue, minValue],
  );

  return (
    <MaskedInput
      ref={ref}
      mask={numberMask}
      value={value}
      onChange={e => onChange(e.target.value.replace(/[^0-9.]+/g, ''))}
      pipe={onPipe}
      {...inputProps}
    />
  );
}
