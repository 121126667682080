import { createContext, useContext, useMemo, useState } from 'react';
import {
  ChartLine,
  CheckSquareOffset,
  ShoppingCart,
  ProjectorScreenChart,
  CheckSquare,
  Warehouse,
  Stack,
  StackPlus,
  Truck,
  ListBullets,
} from '@phosphor-icons/react';
import { useAuth } from './auth';

const SidebarContext = createContext();

export function SidebarProvider({ children }) {
  const { isAuthenticated } = useAuth();
  // in some cases, we may need to hide the sidebar
  const [showSidebar, setShowSidebar] = useState(true);
  const [footerActive, setFooterActive] = useState(false);

  const toggleSidebarVisibility = (showSidebar = true) => {
    setShowSidebar(showSidebar);
  };

  const [isCollapsed, setIsCollapsed] = useState(true);

  const menus = useMemo(() => {
    const order = {
      text: 'Orders',
      parentLink: '/app/orders',
      phosphorIcon: <ShoppingCart className="sidebar-icon-main" />,
      menus: [
        {
          phosphorIcon: <CheckSquareOffset className="sidebar-icon" />,
          text: 'Pending Fulfillment',
          link: '/app/orders/pending-fulfillment/',
        },
        {
          phosphorIcon: <CheckSquare className="sidebar-icon" />,
          text: 'Shipped',
          link: '/app/orders/shipped/',
        },
      ],
    };

    const temp = [];
    temp.push(
      {
        text: 'Dash',
        link: '/app/dashboard',
        parentLink: '/app/dashboard',
        phosphorIcon: <ChartLine className="sidebar-icon-main" />,
        menus: [
          {
            phosphorIcon: <ProjectorScreenChart className="sidebar-icon" />,
            text: 'Analytics',
            link: '/app/dashboard/',
          },
        ],
      },
      {
        text: 'Ware-house',
        link: '/app/warehouses',
        parentLink: '/app/warehouses',
        phosphorIcon: <Warehouse className="sidebar-icon-main" />,
        menus: [
          {
            phosphorIcon: <Warehouse className="sidebar-icon" />,
            text: 'Warehouses',
            link: '/app/warehouses/',
          },
        ],
      },
      {
        text: 'Inven-tory',
        link: '/app/inventory',
        parentLink: '/app/inventory',
        phosphorIcon: <Stack className="sidebar-icon-main" />,
        menus: [
          {
            phosphorIcon: <StackPlus className="sidebar-icon" />,
            text: 'Inventory Import',
            link: '/app/inventory/import',
          },
          {
            phosphorIcon: <ListBullets className="sidebar-icon" />,
            text: 'Inventory List',
            link: '/app/inventory/list',
          },
        ],
      },
      {
        text: 'Ship-ments',
        link: '/app/shipments',
        parentLink: '/app/shipments',
        phosphorIcon: <Truck className="sidebar-icon-main" />,
        menus: [
          {
            phosphorIcon: <Truck className="sidebar-icon" />,
            text: 'Shipments',
            link: '/app/shipments/',
          },
        ],
      },
    );

    temp.push(order);

    return temp;
  }, [isAuthenticated]);

  return (
    <SidebarContext.Provider
      value={{
        menus,
        isCollapsed,
        setIsCollapsed,
        toggleSidebarVisibility,
        showSidebar,
        setFooterActive,
        footerActive,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export const useSidebar = () => useContext(SidebarContext);
