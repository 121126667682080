import { APIPath } from '../constants';
import { CRUDService } from './crud';

class Customers extends CRUDService {
  constructor() {
    super(APIPath.customers);
  }
}

const CustomersService = new Customers();
Object.freeze(CustomersService);
export { CustomersService };
