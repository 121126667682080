import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, Image } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  ControlledTextFormField,
  PasswordFormField,
  ShadowBox,
  StyledButton,
} from '../elements';
import { InvitesService, ToastMessage } from '../utility';
import { LoaderBar } from '../components';
import { Images } from '../images';

const LoginContainer = styled.div`
  position: relative;
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const PageContainer = styled.div`
  background: ${({ theme }) => theme.colors.box};
  display: flex;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
`;

const Box = styled(ShadowBox)`
  padding: 3em 2em;
  width: 432px;
`;

const LoginText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 1.5em;
`;

const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  margin-bottom: 1.5em;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
`;

const SuccessText = styled.div`
  color: ${({ theme }) => theme.colors.success};
  margin-bottom: 1.5em;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
`;

const Logo = styled(Image)`
  height: auto;
  width: 450px;
  margin: 0 auto;
`;

const LogoContainer = styled(Link)`
  margin: 50px auto 100px;
  z-index: 9;
`;

const LoginLinkStyle = styled.div`
  p {
    color: #acadb6;
    font-size: 1em;
    margin: 2em 0 0;

    a {
      color: #393948;
    }
  }
`;

const AcceptInviteSchema = yup.object().shape({
  firstName: yup.string().trim().required(),
  lastName: yup.string().trim().required(),
  password: yup
    .string()
    .required('Password must have at least 12 characters')
    .min(12, 'Password must have at least 12 characters')
    .matches(
      /[@$!%*#?&]/,
      'Password should contain at least one special character',
    )
    .matches(/[\d]/, 'Password should contain at least one number'),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export function AcceptInviteScreen() {
  const [searchQuery] = useSearchParams();
  const token = searchQuery.get('token');
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [valid, setValid] = useState(false);
  const [orgName, setOrgName] = useState('');

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(AcceptInviteSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    const validateToken = async () => {
      try {
        setLoading(true);
        const result = await InvitesService.check(token);
        if (result) {
          if (result.user_exists) {
            setValid(false);
            setSuccess(result.data);
          } else {
            reset({
              firstName: result?.firstName,
              lastName: result?.lastName,
            });
            if (result.organizationId?.name) {
              setOrgName(result.organizationId?.name);
            }
            setValid(true);
          }
        } else {
          setValid(false);
          setError('Invalid link, please contact your administrator');
        }
      } catch (error) {
        ToastMessage.apiError(error);
        setValid(false);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      validateToken();
    }
  }, [token]);

  const onSubmit = async data => {
    try {
      setError('');
      setSaving(true);
      const paylaod = {
        ...data,
      };
      delete paylaod.confirmPassword;
      await InvitesService.accept(token, paylaod);
      setSuccess('Password set successfully. Login to use the application');
    } catch (error) {
      setError('Invalid email and/or password is invalid');
      setSuccess('');
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <PageContainer>
        <LogoContainer to="/login">
          <Logo src={Images.blackLogo} />
        </LogoContainer>
        <LoginContainer>
          <Box className="position-relative">
            {(loading || saving) && (
              <LoaderBar content={loading ? 'Loading' : 'Saving'} />
            )}
            <LoginText>Accept Invitation from {orgName}</LoginText>

            {success ? (
              <>
                <SuccessText>{success}</SuccessText>
                <Link to="/login">
                  <StyledButton fluid>Go to Login</StyledButton>
                </Link>
              </>
            ) : (
              <>
                {valid ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <ControlledTextFormField
                      error={errors.firstName}
                      hint={errors.firstName?.message}
                      label="First Name"
                      name="firstName"
                      control={control}
                      required
                    />
                    <ControlledTextFormField
                      error={errors.lastName}
                      hint={errors.lastName?.message}
                      label="Last Name"
                      name="lastName"
                      control={control}
                    />

                    <PasswordFormField
                      error={errors.password}
                      label="Create a Password"
                      name="password"
                      control={control}
                      required
                      hint={errors?.password?.message}
                    />

                    <PasswordFormField
                      error={errors.confirmPassword}
                      label="Confirm Password"
                      name="confirmPassword"
                      control={control}
                      required
                    />

                    <StyledButton fluid type="submit">
                      Create Account
                    </StyledButton>

                    <LoginLinkStyle>
                      <p>
                        Already on SKUPREME? <Link to="/login">Log In</Link>
                      </p>
                    </LoginLinkStyle>
                  </Form>
                ) : (
                  <>
                    <ErrorText>{error}</ErrorText>
                    <Link to="/login">
                      <StyledButton fluid>Go to Login</StyledButton>
                    </Link>
                  </>
                )}
              </>
            )}
          </Box>
        </LoginContainer>
      </PageContainer>
    </>
  );
}
