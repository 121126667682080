import { CommonUtility } from '../common';
import { BaseService } from './base';

export class CRUDService {
  url = '';

  isLamdaRequest;

  isSecure;

  constructor(url,isLamdaRequest = false,isSecure = true) {
    this.url = url;
    this.isLamdaRequest = isLamdaRequest;
    this.isSecure = isSecure;
  }

  get(params,cancelToken) {
    const url = `${this.url}?${CommonUtility.objectToParams(params)}`;
    return BaseService.get(url,this.isLamdaRequest,this.isSecure,cancelToken);
  }

  getById(id) {
    const url = `${this.url}/${id}`;
    return BaseService.get(url,this.isLamdaRequest,this.isSecure);
  }

  add(data) {
    return BaseService.post(this.url,data,this.isLamdaRequest,this.isSecure);
  }

  update(id,data) {
    let url = `${this.url}`;
    if (id) {
      url += `/${id}`;
    }
    return BaseService.put(url,data,this.isLamdaRequest,this.isSecure);
  }

  remove(id) {
    const url = `${this.url}/${id}`;
    return BaseService.remove(url,this.isLamdaRequest,this.isSecure);
  }
}
