import styled from 'styled-components';
import { Form, TableCell, TableRow } from 'semantic-ui-react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Pencil, Plus } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import {
  MuteText,
  NumberFormField,
  ShadowBox,
  StyledButton,
} from '../../../elements';
import { WarehouseZonesHook } from '../../../hooks/zone';
import { AppTable } from '../../../components';
import { CommonUtility, decimalMask, ToastMessage } from '../../../utility';
import { ZonePopup } from '../zones/ZonePopup';
import { useWarehouseDetails } from '../../../screens/app/warehouse/id';
import { AlertFooterBar } from '../../../elements/FooterAlert';
import { WarehouseService } from '../../../utility/services/warehouse';

const TabContainer = styled.div`
  padding-top: 20px;
`;

const WarehouseSchema = yup.object().shape({
  length: yup
    .number()
    .positive('Length must be greater than 0')
    .typeError('Length is required')
    .required('Length is required'),
  width: yup
    .number()
    .positive('Width must be greater than 0')
    .typeError('Width is required')
    .required('Width is required'),
  height: yup
    .number()
    .positive('Height must be greater than 0')
    .typeError('Height is required')
    .required('Height is required'),
});

export function WarehouseZoneTab() {
  const { id } = useParams();
  const [zonePopup, setZonePopup] = useState(false);
  const { data, loading, refresh } = WarehouseZonesHook(id);
  const [saving, setSaving] = useState(false);
  const { warehouseData, warehouseDataLoading, refreshWarehouse } =
    useWarehouseDetails();

  const {
    control: dimensionControl,
    reset,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(WarehouseSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (CommonUtility.isValidObject(warehouseData?.dimensions)) {
      resetForm();
    }
  }, [warehouseData]);

  const resetForm = () => {
    reset({
      ...(warehouseData?.dimensions || {}),
    });
  };

  const onSubmit = async formData => {
    try {
      setSaving(true);

      const payload = {
        dimensions: {
          length: formData.length,
          width: formData.width,
          height: formData.height,
          measurementUnit: warehouseData?.dimensions?.measurementUnit,
        },
      };

      const res = await WarehouseService.updateWarehouse(id, payload);
      reset({ ...res.dimensions });
      refreshWarehouse();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setSaving(false);
    }
  };

  const columns = [
    {
      key: 'zoneCode',
      text: 'Zone Code',
      textAlign: 'left',
      width: '2',
    },
    {
      key: 'name',
      text: 'Name',
      width: '2',
      textAlign: 'left',
    },
    {
      key: 'type',
      text: 'Type',
      width: '2',
      textAlign: 'left',
    },
    {
      key: 'dimesion',
      text: 'Dimensions',
      width: '2',
      textAlign: 'left',
    },
    {
      key: 'action',
      text: '',
      width: '1',
    },
  ];

  const openPopup = () => {
    setZonePopup(true);
  };

  const closePopup = () => {
    setZonePopup(false);
  };

  return (
    <TabContainer>
      <ShadowBox>
        <Form>
          <h3>Dimensions</h3>
          <Form.Group>
            <NumberFormField
              control={dimensionControl}
              name="length"
              errors={errors.length}
              hint={errors.length?.message}
              label="Length"
              width={4}
              maskOptions={decimalMask}
            />
            <NumberFormField
              control={dimensionControl}
              name="width"
              errors={errors.width}
              hint={errors.width?.message}
              label="Width"
              maskOptions={decimalMask}
              width={4}
            />
            <NumberFormField
              maskOptions={decimalMask}
              control={dimensionControl}
              errors={errors.height}
              hint={errors.height?.message}
              name="height"
              label="Height"
              width={4}
            />
          </Form.Group>
        </Form>
      </ShadowBox>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <h3 className="m-0">Zones</h3>
        <StyledButton onClick={openPopup} className="flex-btn">
          <Plus className="mr-1" />
          New Zone
        </StyledButton>
      </div>
      <AppTable
        RowComponent={<ZoneRow />}
        loading={loading}
        columns={columns}
        data={data}
      />
      <ZonePopup
        warehouseId={id}
        warehouseData={warehouseData}
        warehouseDataLoading={warehouseDataLoading}
        open={zonePopup}
        onClose={closePopup}
        refresh={refresh}
      />
      {CommonUtility.isValidObject(dirtyFields) && (
        <AlertFooterBar
          message="Save Changes"
          cancel={() => resetForm()}
          save={handleSubmit(onSubmit)}
          loading={saving}
        />
      )}
    </TabContainer>
  );
}

const TypeName = styled.span`
  text-transform: capitalize;
`;

function ZoneRow({ item }) {
  return (
    <TableRow textAlign="left">
      <TableCell>{item.code}</TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>
        <TypeName>{item.type}</TypeName>
      </TableCell>
      <TableCell>
        <div>
          <span>{item.dimensions?.length}</span>
          <MuteText className="mx-1">x</MuteText>
          <span>{item.dimensions?.width}</span>
          <MuteText className="mx-1">x</MuteText>
          <span>{item.dimensions?.height}</span>
          <MuteText className="mx-1">
            ({item.dimensions?.measurementUnit})
          </MuteText>
        </div>
      </TableCell>
      <TableCell>
        <div className="d-flex justify-content-end">
          <Link to={item._id}>
            <StyledButton className="flex-btn text-nowrap">
              <Pencil className="mr-1" />
              Configure
            </StyledButton>
          </Link>
        </div>
      </TableCell>
    </TableRow>
  );
}
