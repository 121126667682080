import styled from 'styled-components';

const Pill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 7px;
  padding: 3px 13px;
  border-radius: 15px;
  white-space: nowrap;
  width: fit-content;
`;

export const GreyPill = styled(Pill)`
  background: ${({ theme }) => theme.colors.box};
`;

export const GreenPill = styled(Pill)`
  background: #ecfaed;
  color: ${({ theme }) => theme.colors.green};
`;
