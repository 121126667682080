import styled from 'styled-components';
import { Plus } from '@phosphor-icons/react';
import { useState } from 'react';
import {
  BodyContainer,
  HeaderBar,
  HoverBorderButton,
  PageTitle,
} from '../../../elements';
import { AppTable } from '../../../components';
import { WarehouseListHook } from '../../../hooks';
import { AddWarehousePopup, WarehouseRow } from '../../../page-components';

const StatusContainer = styled(PageTitle)`
  text-transform: capitalize;
`;

function Header({ openPopup }) {
  return (
    <HeaderBar className="row">
      <div className="col-6 pt-2">
        <StatusContainer>Warehouses</StatusContainer>
      </div>
      <div className="col-6 d-flex justify-content-end">
        <HoverBorderButton onClick={openPopup} className="flex-btn">
          <Plus className="mr-1" /> Warehouse
        </HoverBorderButton>
      </div>
    </HeaderBar>
  );
}

export function WarehouseScreen() {
  const [showCreatePopup, setShowCreatePopup] = useState(false);

  const { data, loading } = WarehouseListHook();

  const orderColumns = [
    {
      text: 'Warehouse Name',
      key: 'name',
      textAlign: 'left',
    },
    {
      text: 'Created On',
      key: 'created_on',
      textAlign: 'left',
    },
    {
      text: 'Address',
      key: 'address',
      textAlign: 'left',
    },
  ];

  const openPopup = () => {
    setShowCreatePopup(true);
  };

  const closePopup = () => {
    setShowCreatePopup(false);
  };

  return (
    <BodyContainer>
      <Header openPopup={openPopup} />
      <AppTable
        columns={orderColumns}
        loading={loading}
        RowComponent={<WarehouseRow />}
        data={data}
      />
      <AddWarehousePopup open={showCreatePopup} onClose={closePopup} />
    </BodyContainer>
  );
}
