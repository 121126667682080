import { useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { Check } from '@phosphor-icons/react';
import {
  BodyContainer,
  HeaderBar,
  MuteText,
  PageTitle,
} from '../../../elements';
import { OrderDetailHook } from '../../../hooks/order';
import {
  CustomerDeatils,
  FulfillOrderPopup,
  LabelDetails,
  PickingList,
} from '../../../page-components/order';
import { OrderService, ToastMessage } from '../../../utility';
import {
  ActionEditDots,
  ActionMenuItem,
  BackButton,
} from '../../../components';

function Header({ data, openPopup, showAction }) {
  return (
    <HeaderBar className="row">
      <div className="col-6 row">
        <BackButton />
        <div className="d-flex flex-column">
          <PageTitle>Order Details</PageTitle>
          {data?.marketplaceOrderDisplayId && (
            <MuteText>ID-{data.marketplaceOrderDisplayId}</MuteText>
          )}
        </div>
      </div>
      <div className="col-6 row justify-content-end">
        {showAction && (
          <ActionEditDots
            showByDefault
            inline
            basic
            preventChangingBg
            on="hover"
            position="bottom left"
          >
            <ActionMenuItem
              onClick={() => {
                openPopup();
              }}
            >
              <Check />
              Fulfill
            </ActionMenuItem>
          </ActionEditDots>
        )}
      </div>
    </HeaderBar>
  );
}

export function OrderDetailScreen() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const providerId = searchParams.get('providerId');
  const { data, refresh } = OrderDetailHook(id, providerId);

  const [openFulfill, setOpenFulfill] = useState(false);
  const [loading, setLoading] = useState(false);

  const fulfillOrder = async () => {
    try {
      setLoading(true);
      const payload = {
        action: 'fulfill',
      };
      await OrderService.orderAction(id, payload);
      closePopup();
      ToastMessage.success('Order fulfilled successfully.');
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const openPopup = () => {
    setOpenFulfill(true);
  };

  const closePopup = () => {
    setOpenFulfill(false);
  };

  return (
    <BodyContainer>
      <Header openPopup={openPopup} data={data} showAction />
      <CustomerDeatils data={data} />
      <LabelDetails data={data} />
      <PickingList data={data} />
      <FulfillOrderPopup
        open={openFulfill}
        onClose={closePopup}
        onConfirm={fulfillOrder}
        loading={loading}
      />
    </BodyContainer>
  );
}
