import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';
import { createContext, useContext, useMemo } from 'react';
import { WarehouseDetailHook } from '../../../hooks';
import {
  BodyContainer,
  HeaderBar,
  MuteText,
  PageTitle,
} from '../../../elements';
import { CommonUtility, Tabs } from '../../../utility';
import { TabStyleMenu } from '../../../elements/Tab';
import { WarehouseDetailTab, WarehouseZoneTab } from '../../../page-components';
import { ZoneDetailScreen } from './zones/id';
import { RackDetails } from './zones/racks/id';

function Header({ warehouse, loading }) {
  return (
    <HeaderBar className="row tab-layout">
      <div className="col-6">
        <div className="d-flex flex-column">
          {loading ? (
            <Loader active inline />
          ) : (
            <>
              <PageTitle>{warehouse.name}</PageTitle>
              <MuteText>
                {CommonUtility.getShortAddress(warehouse?.address || {})}
              </MuteText>
            </>
          )}
        </div>
      </div>
    </HeaderBar>
  );
}

const TabContainer = styled.div`
  margin-top: 20px;
`;

const WarehouseContext = createContext();

export function WarehouseDetailScreen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentTab = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 4);
    }
    return null;
  }, [pathname]);

  const { data, loading, refresh: refreshWarehouse } = WarehouseDetailHook(id);

  const tabClicked = tabName => {
    navigate(`/app/warehouses/${id}/${tabName}`);
  };

  const menus = [
    {
      text: 'Warehouse Details',
      value: Tabs.warehouseTabs.details,
    },
    {
      text: 'Warehouse Zones',
      value: Tabs.warehouseTabs.zones,
    },
  ];

  return (
    <WarehouseContext.Provider
      value={{
        warehouseData: data,
        warehouseLoading: loading,
        refreshWarehouse,
      }}
    >
      <BodyContainer>
        <Header loading={loading} warehouse={data} />
        <TabStyleMenu
          menus={menus}
          currentMenu={currentTab}
          onTabChange={tabClicked}
        />
        <TabContainer>
          <Routes>
            <Route index element={<Navigate to="details" />} />
            <Route path="details" element={<WarehouseDetailTab />} />
            <Route path="zones" element={<WarehouseZoneTab />} />
            <Route path="zones/:zoneId" element={<ZoneDetailScreen />} />
            <Route
              path="zones/:zoneId/racks/:rackId"
              element={<RackDetails />}
            />
          </Routes>
        </TabContainer>
      </BodyContainer>
    </WarehouseContext.Provider>
  );
}

export const useWarehouseDetails = () => useContext(WarehouseContext);
