import { useEffect, useState } from 'react';
import { useDebouncedEffect } from './debounce';
import { CommonUtility, MathUtility, ShipmentsService } from '../utility';

export const ShipmentListHook = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const temp = {
        ...filter,
      };
      const res = await ShipmentsService.get(temp);
      setData(res?.data);
      setTotalData(res?.totalCount);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      fetchData();
    },
    [filter, refreshKey],
    500,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const setSearch = search => {
    filterChanged('search', search);
  };

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  return {
    data,
    error,
    filter,
    filterChanged,
    loading,
    pageChanged,
    setSearch,
    totalData,
    totalPages,
    refresh,
  };
};

export const ShipmentDetailHook = id => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await ShipmentsService.getById(id);
      setData(res || {});
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error, refresh: fetchData };
};

const calculatePublicTotal = items => {
  const total = {
    cartons: 0,
    units: 0,
    retails: 0,
    supplierValue: 0,
    grossWeight: 0,
  };
  items?.forEach(item => {
    let latestAttribute = item.attributes;
    const isMultiple = CommonUtility.isValidArray(item.aliases);

    if (isMultiple) {
      let perItemUnits = 0;
      item.aliases.forEach(aliasItem => {
        latestAttribute = aliasItem.attributes;
        const units = aliasItem.qty;
        const ctns = aliasItem.qty / latestAttribute.units_per_carton;

        if (ctns) {
          total.cartons += ctns;
          total.units += +units || 0;
          perItemUnits += +units || 0;
          total.grossWeight += ctns * latestAttribute.carton_gross_weight.value;
        }
      });
      total.retails += (perItemUnits || 0) * (item.price || 0);
      total.supplierValue +=
        (perItemUnits || 0) *
        (item.supplierItemCost || item.supplierComponentCost || 0);
    } else if (item.qty && latestAttribute) {
      const cartons = (item.qty || 0) / (latestAttribute.units_per_carton || 1);
      total.cartons += cartons;
      total.units += +item.qty || 0;
      total.retails += (+item.qty || 0) * (item.price || 0);
      total.supplierValue +=
        (+item.qty || 0) *
        (item.supplierItemCost || item.supplierComponentCost || 0);
      total.grossWeight += cartons * latestAttribute.carton_gross_weight?.value;
    }
  });
  return total;
};

const calculatePublicCBM = items => {
  let tempCBM = 0;
  items?.forEach(item => {
    if (item.qty) {
      let latestAttribute = item.attributes || {};
      const isMultiple = CommonUtility.isValidArray(item.aliases);
      if (isMultiple) {
        item.aliases.forEach(aliasItem => {
          latestAttribute = aliasItem.attributes;
          const ctns = aliasItem.qty / latestAttribute.units_per_carton;
          let calc = 0;
          if (ctns) {
            if (latestAttribute.carton_length?.unit === 'inch') {
              calc = MathUtility.cbf(latestAttribute, ctns);
            } else {
              calc = MathUtility.cbm(latestAttribute, ctns);
            }
          }
          tempCBM += calc;
        });
      } else {
        let calc = 0;
        if (latestAttribute.carton_length.unit === 'inch') {
          calc = MathUtility.cbf(
            latestAttribute,
            item.qty / (latestAttribute?.units_per_carton || 0),
          );
        } else {
          calc = MathUtility.cbm(
            latestAttribute,
            item.qty / (latestAttribute?.units_per_carton || 0),
          );
        }
        tempCBM += calc;
      }
    }
  });
  return tempCBM || 0;
};

const summaryKeys = [
  'cartons',
  'units',
  'retails',
  'supplierValue',
  'grossWeight',
];

export const shipmentDetailsExtractor = data => {
  const [cbm, setCBM] = useState(0);
  const [summary, setSummary] = useState({
    cartons: 0,
    units: 0,
    retails: 0,
    grossWeight: 0,
  });

  useDebouncedEffect(
    () => {
      if (CommonUtility.isValidObject(data)) {
        const newCBM = calculatePublicCBM(data.items);
        const newTotal = calculatePublicTotal(data.items);
        const newComponentTotal = calculatePublicTotal(data.components);
        const newComponentCBM = calculatePublicCBM(data.components);

        const combinedTotal = {};
        summaryKeys.forEach(key => {
          combinedTotal[key] =
            (newTotal[key] || 0) + newComponentTotal[key] || 0;
        });

        setCBM((newCBM || 0) + (newComponentCBM || 0));
        setSummary(combinedTotal);
      }
    },
    [data],
    200,
  );

  return { summary, cbm };
};
