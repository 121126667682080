import { CommonUtility } from '../common';
import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Organizations extends CRUDService {
  constructor() {
    super(APIPath.organizations);
  }

  logoSignedUrl(params) {
    const url = `${APIPath.organizationsLogoUrl}?${CommonUtility.objectToParams(
      params,
    )}`;
    return BaseService.get(url);
  }

  udpateIntegration(orgId, data) {
    return BaseService.put(`${APIPath.organizationIntegration}/${orgId}`, data);
  }

  getIntegrationSettings(orgId) {
    return BaseService.get(`${APIPath.organizationIntegration}/${orgId}`);
  }
}

const OrganizationsService = new Organizations();
Object.freeze(OrganizationsService);
export { OrganizationsService };
