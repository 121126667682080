import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';
import { useMemo } from 'react';
import { BodyContainer, HeaderBar, PageTitle } from '../../../elements';
import { CommonUtility, Tabs } from '../../../utility';
import { TabStyleMenu } from '../../../elements/Tab';
import { GeneralSettingsTab } from './integration';
import { OrganisationDetailTab } from './details';
import { UsersTab } from './users';

function Header() {
  return (
    <HeaderBar className="row tab-layout">
      <div className="col-6 pt-2">
        <PageTitle>Settings</PageTitle>
      </div>
    </HeaderBar>
  );
}

const TabContainer = styled.div`
  margin-top: 20px;
`;

export function SettingsDetailScreen() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentTab = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 3);
    }
    return null;
  }, [pathname]);

  const tabClicked = tabName => {
    navigate(`/app/settings/${tabName}`);
  };

  const menus = [
    {
      text: 'Organization Settings',
      value: Tabs.settingsTab.organisation,
    },
    {
      text: 'Integration Settings',
      value: Tabs.settingsTab.integration,
    },
    {
      text: 'Users',
      value: Tabs.settingsTab.users,
    },
  ];

  return (
    <BodyContainer>
      <Header />
      <TabStyleMenu
        menus={menus}
        currentMenu={currentTab}
        onTabChange={tabClicked}
      />
      <TabContainer>
        <Routes>
          <Route index element={<Navigate to="organisation" />} />
          <Route path="integration" element={<GeneralSettingsTab />} />
          <Route path="organisation" element={<OrganisationDetailTab />} />
          <Route path="users" element={<UsersTab />} />
        </Routes>
      </TabContainer>
    </BodyContainer>
  );
}
