import {
  Barcode,
  Bell,
  Confetti,
  Database,
  LinkSimple,
  ShoppingBag,
  Storefront,
  UserCircle,
} from '@phosphor-icons/react';

import { isValidElement } from 'react';
import styled from 'styled-components';

export const notificationIcons = {
  order: 'order',
  catalog: 'catalog',
  scm: 'scm',
  crm: 'crm',
  vendor: 'vendor',
  dataStudio: 'data-studio',
  cheers: 'cheers',
};

export const iconColors = {
  yellow: {
    iconColor: '#FFC700',
    backgroundColor: 'rgba(255, 199, 0, 0.1)',
  },
  red: {
    iconColor: '#FF4218',
    backgroundColor: 'rgba(255, 66, 24, 0.1)',
  },
  green: {
    iconColor: '#3EC247',
    backgroundColor: 'rgba(62, 194, 71, 0.1)',
  },
  default: {
    iconColor: '#393948',
    backgroundColor: '#F6F6F6',
  },
  notificationIconColor: {
    iconColor: 'white',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
};

const Container = styled.div({
  background: ({ bgColor }) => bgColor,
});

const IconContainer = styled(Container)`
  border-radius: 50% !important;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotificationBody = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export function NotificationRenderer({
  defaultNotification,
  message,
  iconType,
  colors = iconColors.notificationIconColor,
}) {
  if (defaultNotification) colors = iconColors.default;

  return (
    <div className="w-100 d-flex align-items-center">
      <IconContainer bgColor={colors.backgroundColor}>
        <Icon iconType={iconType} color={colors.iconColor} />
      </IconContainer>
      <NotificationBody className="ml-2">{message}</NotificationBody>
    </div>
  );
}

export function Icon({ iconType, color, ...rest }) {
  if (isValidElement(iconType)) return iconType;

  switch (iconType) {
    case notificationIcons.catalog:
      return <Barcode color={color} size={24} {...rest} />;

    case notificationIcons.crm:
      return <UserCircle color={color} size={24} {...rest} />;

    case notificationIcons.dataStudio:
      return <Database color={color} size={24} {...rest} />;

    case notificationIcons.order:
      return <ShoppingBag color={color} size={24} {...rest} />;

    case notificationIcons.scm:
      return <LinkSimple color={color} size={24} {...rest} />;

    case notificationIcons.vendor:
      return <Storefront color={color} size={24} {...rest} />;

    case notificationIcons.cheers:
      return <Confetti color={color} size={24} {...rest} />;

    default:
      return <Bell color={color} size={24} {...rest} />;
  }
}
