export const theme = {
  colors: {
    primary: '#393948',
    primaryLight: '#3F3F4F',
    accent: '#4AADA7',
    grey: '#ACADB6',
    white: '#FFFFFF',
    danger: '#FF4218',
    success: '#4AAD84',
    purple: '#67388F',
    black: '#393948',
    lightBlack: '#4D4D60',
    green: '#3EC247',
    box: '#F6F6F6',
    orange: '#FFA51E',
    lightGrey: '#525268',
    lighterGrey: '#878787',
    background: '#C7C7C7',
    red: '#FF4218',
    yellow: '#FFC700',
    lightBlueGrey: '#545469',
    link: '#0DA8EA',
    linkVisited: '#086187',
    semanticBorder: 'rgba(34,36,38,.15)',
  },
};
