import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormGroup } from 'semantic-ui-react';
import { useState } from 'react';
import { SkupremeModal } from '../../components';
import {
  DropdownFormField,
  HoverBorderButton,
  NumberFormField,
  StyledButton,
} from '../../elements';
import { decimalMask, ToastMessage, ZoneService } from '../../utility';
import { RackTypesHook } from '../../hooks';
import { useWarehouseDetails } from '../../screens/app/warehouse/id';

const ZoneSchema = yup.object().shape({
  type: yup.string().required('Type is required'),
  quantity: yup
    .number()
    .positive('Length must be greater than 0')
    .typeError('Length is required')
    .required('Length is required'),
  length: yup
    .number()
    .positive('Length must be greater than 0')
    .typeError('Length is required')
    .required('Length is required'),
  width: yup
    .number()
    .positive('Width must be greater than 0')
    .typeError('Width is required')
    .required('Width is required'),
});

export function CreateRackPopup({ onClose, zoneId, open, refreshData, aisle }) {
  const [loading, setLoading] = useState(false);
  const { data, loading: optionsLoading } = RackTypesHook();
  const { warehouseData } = useWarehouseDetails();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(ZoneSchema),
    shouldUnregister: true,
  });

  const onSubmit = async formData => {
    if (!warehouseData?.dimensions?.measurementUnit) {
      ToastMessage.error('No Measurement Unit found for warehouse');
      return;
    }
    try {
      setLoading(true);
      const payload = {
        type: formData.type,
        quantity: formData.quantity,
        dimensions: {
          length: formData.length,
          width: formData.width,
          measurementUnit: warehouseData.dimensions.measurementUnit,
        },
      };

      if (aisle?._id) {
        payload.aisleId = aisle._id;
      }

      await ZoneService.addRacks(zoneId, payload);
      ToastMessage.success('Racks successfully created.');
      onClose();
      refreshData();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkupremeModal
      size="tiny"
      title={aisle?.code ? `Create Rack in ${aisle.code}` : 'Create Rack'}
      open={open}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup widths="equal">
          <DropdownFormField
            control={control}
            name="type"
            label="Type"
            error={errors.type}
            hint={errors.type?.message}
            loading={optionsLoading}
            options={data}
            selection
          />
          <NumberFormField
            control={control}
            name="quantity"
            label="Quantity"
            error={errors.quantity}
            hint={errors.quantity?.message}
          />
        </FormGroup>
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            name="length"
            label="Length"
            maskOptions={decimalMask}
            error={errors.length}
            hint={errors.length?.message}
          />
          <NumberFormField
            control={control}
            name="width"
            label="Width"
            maskOptions={decimalMask}
            error={errors.width}
            hint={errors.width?.message}
          />
        </FormGroup>
        <div className="d-flex justify-content-end">
          <HoverBorderButton type="button" onClick={onClose}>
            Cancel
          </HoverBorderButton>
          <StyledButton type="submit" loading={loading} className="ml-1">
            Create
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
