import styled from 'styled-components';

export const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

export const ImageContainer = styled.div`
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid;
  height: 40px;
  min-width: 40px;
  border-color: ${({ theme }) => theme.colors.semanticBorder};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  &:hover {
    .drop-zone {
      display: flex;
      opacity: 1;
    }
  }
`;

export const HiddenFileAccepter = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;
