import styled from 'styled-components';

const StepContainer = styled.div`
  padding: 0 12px;
`;

export function DetailForm() {
  return (
    <StepContainer>
      <span>Pending Storage</span>
    </StepContainer>
  );
}
