import { useMemo, useRef, useState } from 'react';
import { FileCsv, Scroll, UploadSimple } from '@phosphor-icons/react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { MuteText, ShadowBox } from '../../../elements/Common';
import { CenterDropdownStyle } from '../../../elements/Dropdown';
import { CustomerListHook } from '../../../hooks';
import { HoverBorderButton, StyledButton } from '../../../elements/Button';
import { AlertFooterBar } from '../../../elements/FooterAlert';
import { InventoryService, ToastMessage } from '../../../utility';
import { UploadService } from '../../../utility/services/upload';

const FileInput = styled.input`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`;

const FileContainer = styled.div`
  max-width: 100px;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 5px;
  margin-bottom: 10px;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    min-width: 22px;
  }
`;

export function InventoryImportTab() {
  const { data: customers } = CustomerListHook();
  const [customer, setCustomer] = useState('');
  const [provider, setProvider] = useState('');
  const [file, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fileRef = useRef(null);

  const customerOptions = useMemo(
    () =>
      customers?.map(customer => ({
        text: customer.organizationName,
        value: customer._id,
      })) || [],
    [customers],
  );

  const selectedCustomer = useMemo(
    () => customers?.find(x => x._id === customer),
    [customer, customers],
  );

  const providerOptions = useMemo(
    () =>
      selectedCustomer?.providers?.map(provider => ({
        value: provider?.wmsMetadata?.wmsWarehouseId,
        text: provider?.warehouseName,
      })),
    [selectedCustomer],
  );

  const selectFiles = e => {
    setFiles(e?.target?.files[0] || null);
  };

  const selectFile = () => {
    fileRef?.current?.click();
  };

  const onCancel = () => {
    reset();
  };

  const onConfirm = async () => {
    try {
      setLoading(true);
      const params = {
        customerOrganizationId: customer,
        warehouseId: provider,
      };
      const res = await InventoryService.generateUploadLInk(
        'inventory-import',
        params,
      );
      if (res.uploadUrl) {
        await UploadService.media(res.uploadUrl, file);
        ToastMessage.success(
          'File uploaded successfully, check the feed tab for progress',
        );
        reset();
        navigate('/app/inventory/feed');
      } else {
        ToastMessage.error('There was some error uploading file.');
      }
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    setFiles(null);
    setProvider('');
    setCustomer('');
  };
  return (
    <ShadowBox className="col-6">
      <MuteText>Search Customers</MuteText>
      <CenterDropdownStyle
        placeholder="Search Customer by name"
        options={customerOptions}
        selection
        search
        value={customer}
        onChange={(e, { value }) => setCustomer(value)}
      />
      {selectedCustomer && (
        <div className="mt-2">
          <MuteText>Search Providers</MuteText>
          <CenterDropdownStyle
            placeholder="Search Provider by name"
            options={providerOptions}
            selection
            search
            value={provider}
            onChange={(e, { value }) => setProvider(value)}
          />
        </div>
      )}
      {selectedCustomer && (
        <div className="mt-4 py-4">
          <div className="d-flex align-items-center">
            <Scroll size={18} />
            <b className="ml-1">
              Import inventory for {selectedCustomer?.organizationName}
            </b>
          </div>
          <div className="mt-4 d-flex flex-column">
            <p className="m-0">You can dowload a template from here</p>
            <a
              href={`${process.env.PUBLIC_URL}/inventory.csv`}
              download
              target="_blank"
              rel="noreferrer"
            >
              <HoverBorderButton>Download Template</HoverBorderButton>
            </a>
          </div>
          <div className="d-flex flex-column mt-4">
            <MuteText>Import from file</MuteText>
            {file && (
              <FileContainer>
                <FileCsv size={22} className="icon" />
                <span>{file.name}</span>
              </FileContainer>
            )}
            <FileInput
              accept=".csv"
              onChange={selectFiles}
              ref={fileRef}
              type="file"
            />
            <StyledButton
              onClick={selectFile}
              className="flex-btn justify-content-center"
            >
              <UploadSimple size={18} className="mr-1" />
              Select File
            </StyledButton>
          </div>
        </div>
      )}
      {file && (
        <AlertFooterBar
          message="Upload File"
          saveButtonText="Upload"
          loading={loading}
          save={onConfirm}
          cancel={onCancel}
        />
      )}
    </ShadowBox>
  );
}
