import { CommonUtility } from '../common';
import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Inventory extends CRUDService {
  constructor() {
    super(APIPath.inventory);
  }

  generateUploadLInk(feedType, params) {
    const query = CommonUtility.objectToParams(params);
    return BaseService.get(
      `${APIPath.inventoryFeedUploadUrl}/${feedType}/?${query}`,
    );
  }

  getFeeds(params) {
    const query = CommonUtility.objectToParams(params);
    return BaseService.get(`${APIPath.inventoryFeed}/?${query}`);
  }

  updateInventory(id, payload) {
    return BaseService.patch(`${APIPath.inventory}/${id}`, payload);
  }
}

const InventoryService = new Inventory();
Object.freeze(InventoryService);
export { InventoryService };
