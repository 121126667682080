export class MathUtility {
  static cbm = (attribute, qty) =>
    ((attribute?.carton_length?.value || 0) / 100) *
    ((attribute?.carton_width?.value || 0) / 100) *
    ((attribute?.carton_height?.value || 0) / 100) *
    qty;

  static cbf = (attribute, qty) =>
    ((attribute?.carton_length?.value || 0) / 12) *
    ((attribute?.carton_width?.value || 0) / 12) *
    ((attribute?.carton_height?.value || 0) / 12) *
    qty;
}
