import { useState } from 'react';
import { InventoryService } from '../utility';
import { useDebouncedEffect } from './debounce';

export const InventoryFeedsHook = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const temp = {
        ...filter,
      };
      const res = await InventoryService.getFeeds(temp);
      setData(res?.data?.feeds);
      setTotalData(res?.totalCount);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      fetchData();
    },
    [filter, refreshKey],
    500,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const setSearch = search => {
    filterChanged('search', search);
  };

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  return {
    data,
    error,
    filter,
    filterChanged,
    loading,
    pageChanged,
    setSearch,
    totalData,
    totalPages,
    refresh,
  };
};

export const InventoryListHook = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const temp = {
        ...filter,
      };
      const res = await InventoryService.get(temp);
      setData(res?.data);
      setTotalData(res?.totalCount);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      fetchData();
    },
    [filter, refreshKey],
    500,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const setSearch = search => {
    filterChanged('search', search);
  };

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  return {
    data,
    error,
    filter,
    filterChanged,
    loading,
    pageChanged,
    setSearch,
    totalData,
    totalPages,
    refresh,
  };
};
