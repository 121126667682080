import React, { useState } from 'react';
import styled from 'styled-components';
import { Check, Copy } from '@phosphor-icons/react';
import { theme, ToastMessage } from '../utility';
import { DarkPopup } from './Popup';

const TextToCopy = styled.div`
  display: flex;
  align-items: center;

  .copy-icons.position-right svg,
  .copy-icons.position-right img {
    margin-left: 4px;
  }

  .copy-icons.position-left svg,
  .copy-icons.position-left img {
    margin-right: 4px;
  }

  .copy-icons svg,
  .copy-icons img {
    font-size: 120%;
  }

  .copy-icons {
    display: flex;
    align-items: center;
    opacity: 0;
    cursor: copy;
  }

  .icon {
    min-width: 16px;
  }

  &:hover {
    .copy-icons {
      opacity: 1;
    }
  }
`;

export function TextCopyToClipboard({
  text,
  copiedText,
  iconSize = 16,
  position = 'right',
  className = '',
  copyText = true,
  showText = true,
  showIcon = true,
  copyTextValue = '',
}) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard() {
    if ('clipboard' in navigator) {
      if (copyTextValue.length) {
        return await navigator.clipboard.writeText(copyTextValue);
      }
      return await navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }

  const handleCopyClick = (e, preventCopy = true) => {
    if (!preventCopy) return;

    e.stopPropagation();
    e.preventDefault();
    copyTextToClipboard()
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(() => {
        ToastMessage.error('Something went wrong while copying.');
      });
  };

  const copyIcons = text ? (
    <DarkPopup open={isCopied}>
      <div
        className={`copy-icons position-${position}`}
        onClick={e => handleCopyClick(e)}
      >
        {!isCopied && <Copy className="cursor-pointer icon" size={iconSize} />}
        {isCopied && (
          <Check
            className="cursor-pointer icon"
            style={{ color: theme.colors.success }}
            size={iconSize}
          />
        )}
      </div>
    </DarkPopup>
  ) : (
    <></>
  );

  return (
    <TextToCopy
      className={className}
      onClick={e => handleCopyClick(e, copyText)}
      onMouseLeave={() => setIsCopied(false)}
    >
      {showIcon && position === 'left' && copyIcons}
      {showText ? (copiedText && isCopied ? copiedText : text) : ''}
      {showIcon && position === 'right' && copyIcons}
    </TextToCopy>
  );
}

export default TextCopyToClipboard;
