import defaultLogo from './default-image.svg';
import blackLogo from './skupreme-black.svg';
import catImage from './cat.svg';
import tacticalLogo from './logo-tactical.svg';
import smallWhiteLogo from './small-logo-white.svg';

export const Images = {
  default: defaultLogo,
  blackLogo,
  cat: catImage,
  tacticalLogo,
  smallWhiteLogo,
};
