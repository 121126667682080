import { unitConstants, units } from './constants';

export const conversionMap = {
  [units.liters]: units.gallons,
  [units.milliLiters]: units.fluidOunces,
  [units.kiloGrams]: units.pounds,
  [units.grams]: units.ounces,
  [units.centimeters]: units.inches,
  [units.gallons]: units.liters,
  [units.fluidOunces]: units.milliLiters,
  [units.pounds]: units.kiloGrams,
  [units.ounces]: units.grams,
  [units.inches]: units.centimeters,
};

export class ConversionUtility {
  static toggleUnit = (imperial, unit) => {
    let needsConversion = false;
    if (imperial) {
      if (
        ![
          units.fluidOunces,
          units.ounces,
          units.pounds,
          units.gallons,
        ].includes(unit)
      ) {
        needsConversion = true;
      }
    } else if (
      ![units.milliLiters, units.grams, units.kiloGrams, units.liters].includes(
        unit,
      )
    ) {
      needsConversion = true;
    }

    if (needsConversion) {
      return conversionMap[unit];
    }

    return unit;
  };

  // inch centi conversion
  static inchToCenti = inch => {
    const updated = inch * unitConstants.cmInInches;
    return updated;
  };

  static centiToInch = cm => {
    const updated = cm / unitConstants.cmInInches;
    return updated;
  };

  // pound kilo conversion
  static poundToKilo = lb => {
    const updated = lb / unitConstants.KiloInlb;
    return updated;
  };

  static kiloToPound = kg => {
    const updated = kg * unitConstants.KiloInlb;
    return updated;
  };

  // gram ounce conversion
  static gramsToOunces = grams => {
    const updated = grams / unitConstants.OuncesInGrams;
    return updated;
  };

  static ouncesToGrams = ounces => {
    const updated = ounces * unitConstants.OuncesInGrams;
    return updated;
  };

  // gal liter conversion
  static gallonsToLiters = gallons => {
    const updated = gallons * unitConstants.GallonsInLiters;
    return updated;
  };

  static litersToGallons = liters => {
    const updated = liters / unitConstants.GallonsInLiters;
    return updated;
  };

  // fluid ounce milliliter conversion
  static millitersToFluidOunces = milliters => {
    const updated = milliters / unitConstants.FluidOuncesInMilliliters;
    return updated;
  };

  static fluidOuncesToMilliters = fluidOunces => {
    const updated = fluidOunces * unitConstants.FluidOuncesInMilliliters;
    return updated;
  };

  static cbmTocbf = cbm => 35.3147 * cbm;

  static cbfTocbm = cbf => cbf / 35.3147;

  static convertItemToMetric = (attribute, toFixed = true) => {
    const temp = {};
    Object.keys(attribute || {}).forEach(key => {
      if (['inch', units.inches].includes(attribute[key].unit)) {
        const value = ConversionUtility.inchToCenti(
          attribute[key].value || 0,
          toFixed,
        );
        temp[key] = { unit: units.centimeters, value };
      } else if (attribute[key].unit === units.pounds) {
        const value = ConversionUtility.poundToKilo(
          attribute[key].value || 0,
          toFixed,
        );
        temp[key] = { unit: units.kiloGrams, value };
      } else if (attribute[key].unit === units.fluidOunces) {
        const value = ConversionUtility.fluidOuncesToMilliters(
          attribute[key].value,
        );
        temp[key] = { unit: units.milliLiters, value };
      } else if (attribute[key].unit === units.gallons) {
        const value = ConversionUtility.gallonsToLiters(attribute[key].value);
        temp[key] = { unit: units.liters, value };
      } else {
        temp[key] = attribute[key];
      }
    });
    return temp;
  };

  static convertItemToImperial = (attribute, toFixed = true) => {
    const temp = {};
    Object.keys(attribute || {}).forEach(key => {
      if (attribute[key].unit === units.centimeters) {
        const value = ConversionUtility.centiToInch(
          attribute[key].value || 0,
          toFixed,
        );
        temp[key] = { unit: units.inches, value };
      } else if (attribute[key].unit === units.kiloGrams) {
        const value = ConversionUtility.kiloToPound(
          attribute[key].value || 0,
          toFixed,
        );
        temp[key] = { unit: units.pounds, value };
      } else if (attribute[key].unit === units.milliLiters) {
        const value = ConversionUtility.millitersToFluidOunces(
          attribute[key].value,
        );
        temp[key] = { unit: units.fluidOunces, value };
      } else if (attribute[key].unit === units.liters) {
        const value = ConversionUtility.litersToGallons(attribute[key].value);
        temp[key] = { unit: units.gallons, value };
      } else {
        temp[key] = attribute[key];
      }
    });
    return temp;
  };
}
