import axios from 'axios';
import { UserService } from './user';

const lamdaAPIPath = process.env.REACT_APP_API_PATH;
const lamdaBaseURL = lamdaAPIPath;

const apiPath = process.env.REACT_APP_API_PATH;

const onSuccess = response => response.data;

const onError = async error => {
  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx

    if (error.response.status === 403) {
      window.localStorage.clear();
      window.location.href = '/';
      return Promise.reject(error);
    }
  }

  return Promise.reject({
    code: error?.response?.data?.code || error?.response?.code || '',
    error: error?.response?.data?.error || error?.response?.data || '',
    status: error?.response?.status || '',
  });
};

const refreshToken = async error => {
  const originalRequest = error.config;
  if (error?.response?.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refresh_token = UserService.getRefreshToken();
    if (refresh_token) {
      try {
        const params = {
          token: refresh_token,
        };
        const result = await UserService.refreshToken(params);
        UserService.storeUser(result);
        originalRequest.headers.Authorization = `Bearer ${result.accessToken}`;
        return originalRequest;
      } catch (error) {
        window.location.href = '/';
      }
    } else {
      window.location.href = '/';
    }
  }
  return Promise.reject(error);
};

const request = async (
  options,
  isLamdaRequest,
  isSecure,
  setBaseUrl,
  cancelToken,
) => {
  const headers = {};

  if (isSecure) {
    const token = UserService.getToken();
    headers.Authorization = `Bearer ${token}`;
  }

  const client = axios.create({
    baseURL: setBaseUrl || (isLamdaRequest ? lamdaBaseURL : apiPath),
    headers: { ...headers },
    cancelToken,
  });

  client.interceptors.response.use(
    response => response,
    async error => {
      const refreshReq = await refreshToken(error);
      return client(refreshReq);
    },
  );

  return client(options).then(onSuccess).catch(onError);
};

const uploadFiles = (url, data, headers) => {
  const client = axios({
    url,
    method: 'PUT',
    headers: { ...headers },
    data,
  });

  return client.then(onSuccess).catch(onError);
};

const localFiles = url => {
  const client = axios({
    url,
    method: 'GET',
  });

  return client.then(onSuccess).catch(onError);
};

export class BaseService {
  static get = (
    url,
    isLamdaRequest = false,
    isSecure = true,
    cancelToken,
    setBaseUrl,
  ) =>
    request(
      {
        url,
        method: 'GET',
      },
      isLamdaRequest,
      isSecure,
      setBaseUrl,
      cancelToken,
    );

  static post = (
    url,
    data,
    isLamdaRequest = false,
    isSecure = true,
    setBaseUrl,
  ) =>
    request(
      {
        url,
        method: 'POST',
        data,
      },
      isLamdaRequest,
      isSecure,
      setBaseUrl,
    );

  static patch = (
    url,
    data,
    isLamdaRequest = false,
    isSecure = true,
    setBaseUrl,
  ) =>
    request(
      {
        url,
        method: 'PATCH',
        data,
      },
      isLamdaRequest,
      isSecure,
      setBaseUrl,
    );

  static put = (
    url,
    data,
    isLamdaRequest = false,
    isSecure = true,
    setBaseUrl,
  ) =>
    request(
      {
        url,
        method: 'PUT',
        data,
      },
      isLamdaRequest,
      isSecure,
      setBaseUrl,
    );

  static remove = (url, isLamdaRequest = false, isSecure = true) =>
    request(
      {
        url,
        method: 'DELETE',
      },
      isLamdaRequest,
      isSecure,
    );

  static upload = (url, data, header) => uploadFiles(url, data, header);

  static localFiles = url => localFiles(url);
}
