import { useEffect, useMemo, useState } from 'react';
import { OrderService } from '../utility/services';
import { useDebouncedEffect } from './debounce';
import { CommonConstant, OrderUrlKeys } from '../utility/constants';
import { CommonUtility } from '../utility/common';

export const OrderHook = ({ type }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: 50,
    page: 1,
    search: '',
    sort: {
      sortBy: 'timeAcknowledged',
      sortOrder: CommonConstant.sortDirection.descending,
    },
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const temp = {
        ...filter,
        ...filter.sort,
        sort: null,
        search: (filter.search || '').trim(),
        status: OrderUrlKeys[type],
      };
      const res = await OrderService.get(temp);
      setData(res?.data);
      setTotalData(res?.totalCount);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (type) {
        fetchData();
      }
    },
    [filter, type, refreshKey],
    500,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const setSearch = search => {
    filterChanged('search', search);
  };

  const onSortChange = sortObject => {
    if (!CommonUtility.isValidObject(sortObject)) return;
    const sortKey = Object.keys(sortObject)[0];
    const sortBy = sortObject[sortKey];

    const temp = {
      ...filter,
      page: 1,
      sort: {
        sortBy: sortKey,
        sortOrder: sortBy,
      },
    };
    setFilter({ ...temp });
  };

  const parsedSort = useMemo(
    () => ({
      [filter.sort.sortBy]: filter.sort.sortOrder,
    }),
    [filter],
  );

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  const updateAllFilter = obj => {
    setFilter(filter => ({
      ...filter,
      page: 1,
      ...obj,
    }));
  };

  return {
    data,
    error,
    filter,
    filterChanged,
    loading,
    onSortChange,
    pageChanged,
    setSearch,
    totalData,
    totalPages,
    parsedSort,
    refresh,
    updateAllFilter,
  };
};

export const OrderDetailHook = (id, providerId) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await OrderService.orderDetail(id, { providerId });
      setData(res?.data || {});
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && providerId) {
      fetchData();
    }
  }, [id, providerId]);

  return { data, loading, error, refresh: fetchData };
};
