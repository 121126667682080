import { User } from '@phosphor-icons/react';
import { MuteText, ShadowBox } from '../../../elements';
import { CommonUtility } from '../../../utility/common';

export function CustomerDeatils({ data }) {
  return (
    <ShadowBox>
      <div className="d-flex align-items-center">
        <User weight="bold" />
        <b className="ml-1">Customer</b>
      </div>
      <div className="mt-2">
        <div className="d-flex">
          <MuteText>Name</MuteText>
          <span className="ml-2">{data?.shippingAddress?.name}</span>
        </div>
        {data?.shippingAddress?.email && (
          <div className="d-flex">
            <MuteText>Email</MuteText>
            <span className="ml-2">{data?.shippingAddress?.email}</span>
          </div>
        )}
        <div className="d-flex">
          <MuteText>Address</MuteText>
          <span className="ml-2">
            {CommonUtility.getLongAddress(data?.shippingAddress || [])}
          </span>
        </div>
      </div>
    </ShadowBox>
  );
}
