import { useMediaQuery } from 'react-responsive';

export const useDesktopMediaQuery = () =>
  useMediaQuery({ query: '(min-width: 769px)' });

export const useTabletAndBelowMediaQuery = () =>
  useMediaQuery({ query: '(max-width: 768px)' });

export const useTabletAndBelowHeightMediaQuery = () =>
  useMediaQuery({ query: '(max-height: 890px)' });

export const useLaptopAndBelowMediaQuery = () =>
  useMediaQuery({ query: '(max-width: 1440px)' });

export const useSmallScreenAndBelowMediaQuery = () =>
  useMediaQuery({ query: '(max-width: 1024px)' });

export const useMobileAndBelowMediaQuery = () =>
  useMediaQuery({ query: '(max-width: 425px)' });

export const useSmallerLaptopAndBelowMediaQuery = () =>
  useMediaQuery({ query: '(max-width: 1199px)' });

export function DesktopMode({ children }) {
  const isDesktop = useDesktopMediaQuery();

  return isDesktop ? children : null;
}

export function MobileMode({ children }) {
  const isTabletAndBelow = useTabletAndBelowMediaQuery();

  return isTabletAndBelow ? children : null;
}
