import { Image } from 'semantic-ui-react';
import styled from 'styled-components';
import { Images } from '../images';
import { PublicLayout } from '../layouts/PublicLayout';

const Header = styled.div`
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
`;

const PageContainer = styled.div({
  minHeight: 'calc(100vh - 80px)',
  paddingBottom: '1em',
  justifyContent: 'center',
  alignContent: 'center',
  paddingTop: '10em',
  paddingLeft: '-7em',
});

const TextContainer = styled.div`
  text-align: center;
`;

function PageNotFound() {
  return (
    <PageContainer className="row">
      <TextContainer className="col-12 text-center px-4">
        <Header>Sorry, we couldn&apos;t find that page.</Header>
      </TextContainer>
      <div className="col-12 d-flex justify-content-center">
        <Image src={Images.cat} alt="Image" />
      </div>
    </PageContainer>
  );
}

export function Error404() {
  return (
    <PublicLayout>
      <PageNotFound />
    </PublicLayout>
  );
}
