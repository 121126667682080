import styled from 'styled-components';
import { Info } from '@phosphor-icons/react';
import { StyledSliderCheckbox } from '../elements';
import { DarkPopup } from './Popup';
import { tooltipTexts } from '../utility';

const Wrapper = styled.div`
  > img.ui.image.ml-2 {
    height: 17px;
    width: 17px;
  }
  > div.checkbox {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    > label {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      &::after,
      &::before {
        top: 2px !important;
      }
    }
  }
`;

export function MetricImperialToggle({
  flexed = true,
  labelClassName = 'mb-0',
  wrapperClassName = '',
  ...rest
}) {
  return (
    <Wrapper
      className={[
        flexed ? 'd-flex align-items-center' : '',
        wrapperClassName || '',
      ]
        .filter(d => !!d)
        .join(' ')}
    >
      <label className={`${labelClassName}`}>Metric</label>
      <StyledSliderCheckbox {...rest} className="mx-3" />
      <label className={`${labelClassName}`}>Imperial</label>
      <DarkPopup element={<Info />}>
        {tooltipTexts.unitConversionInfo}
      </DarkPopup>
    </Wrapper>
  );
}
