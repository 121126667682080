import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useDebounce } from '../hooks/debounce';

const Popper = styled.div`
  opacity: 0;
  pointer-events: none;
  &.show {
    opacity: 1;
    pointer-events: all;
  }
`;

const DarkPopupContent = styled.div`
  background-color: ${({ theme, primaryDark }) =>
    primaryDark ? theme.colors.primary : theme.colors.lightBlack};
  padding: ${({ padding }) => padding ?? '1rem'};
  color: ${({ theme }) => theme.colors.white};
  ${({ contentWidth }) =>
    contentWidth > 0 && `max-width: ${({ contentWidth }) => contentWidth}px;`}
  max-height: ${({ maxHight }) => maxHight || '350px'};
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
`;

const StyledDarkPopup = styled(Popup)`
  &:before {
    background: ${({ theme, primaryDark }) =>
      primaryDark ? theme.colors.primary : theme.colors.lightBlack} !important;
  }
`;

export function DarkPopup({
  children,
  contentWidth,
  contentMaxHight,
  padding,
  setOpen,
  primaryDark = false,
  ...props
}) {
  const [show, setShow] = useState();
  const finalShow = useDebounce(show, 50);
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setShow });
    }
    return child;
  });

  const handleOpen = () => {
    setShow(true);
    typeof setOpen === 'function' && setOpen?.(true);
  };

  const handleClose = () => {
    setShow(false);
    typeof setOpen === 'function' && setOpen?.(false);
  };

  return (
    <StyledDarkPopup
      inverted
      style={{
        padding: 0,
      }}
      onOpen={handleOpen}
      onClose={handleClose}
      primaryDark={primaryDark}
      popper={<Popper className={finalShow ? 'show' : ''} />}
      {...props}
    >
      <DarkPopupContent
        padding={padding}
        primaryDark={primaryDark}
        contentWidth={contentWidth}
        maxHight={contentMaxHight}
      >
        {childrenWithProps}
      </DarkPopupContent>
    </StyledDarkPopup>
  );
}
