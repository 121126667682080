import { ArrowRight, Check } from '@phosphor-icons/react';
import {
  cloneElement,
  Fragment,
  isValidElement,
  useEffect,
  useState,
} from 'react';
import styled, { keyframes } from 'styled-components';
import { CommonUtility, theme, ToastMessage } from '../../utility';
import { DangerText, ShadowBox } from '../../elements';
import { LoaderBar } from '../../components/LoaderBar';
import { DetailForm } from './LeftTabs';

const Steps = styled.div`
  padding: 15px;
  display: flex;
  column-gap: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.box};
  position: relative;
`;

const CheckMark = styled(Check)`
  color: ${({ theme }) => theme.colors.green};
  opacity: 0.7;
`;

const ActiveStep = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
`;

const AnimateCheck = styled.div`
  color: ${({ theme }) => theme.colors.green};
  position: absolute;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Step = styled.div`
  color: ${({ theme, active }) =>
    active === 1 ? theme.colors.black : theme.colors.grey};
  ${({ allowed }) => (allowed ? `cursor: pointer;` : `cursor: not-allowed;`)}
  display: flex;
  align-items: center;
  column-gap: 5px;
  .active {
    font-weight: bold;
  }

  &.single-stepper {
    cursor: auto;
  }
`;

const nextStepAnimation = keyframes`
  0%{
    left: 43%;
  }
  90%{
    left: 100%;
  }
  100%{
    left: 100%;
    width: 1px;
  }
`;

const WhiteOverlay = styled.div`
  background: ${({ theme }) => theme.colors.white};
  position: absolute;
  width: 100px;
  height: 100px;
  transform: translateY(-50%);
  left: 43%;
  top: 50%;
  animation-name: ${nextStepAnimation};
  animation-duration: 2s;
`;

const WhiteBgLoader = styled(LoaderBar)`
  background: ${({ theme }) => theme.colors.white} !important;
`;

export function DetailsLeftTab() {
  const [activeTab, setActiveTab] = useState(2);

  const panes = [
    {
      stepName: 'info',
      stepText: 'Info',
      fetchNeeded: false,
      render: <DetailForm />,
    },
    {
      stepName: 'contents',
      stepText: 'Contents',
      fetchNeeded: false,
      render: <DetailForm />,
    },
    {
      stepName: 'storage',
      stepText: 'Storage',
      fetchNeeded: false,
      render: <DetailForm />,
    },
  ];

  return (
    <ShadowBox className="tab-left p-0">
      <Stepper
        steps={panes}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </ShadowBox>
  );
}

function Stepper({ steps, setActiveTab, activeTab, submit, loading }) {
  const [tabNames, setTabNames] = useState({});
  const errors = {};

  useEffect(() => {
    const leftTabs = {
      stepName: {
        text: 'bla bla',
      },
    };
    setTabNames(leftTabs);
  }, []);

  const [animate, setAnimate] = useState(false);

  const moveToCurrentStep = index => {
    if (index <= activeTab) {
      setActiveTab(index);
    }
  };

  const moveToNextStep = () => {
    if (activeTab + 1 === steps.length || steps[activeTab]?.submit) {
      submit && submit();
    } else {
      playAnimation();
      setActiveTab(activeTab => activeTab + 1);
    }
  };

  const playAnimation = () => {
    if (steps[activeTab].toastMessage) {
      ToastMessage.success(steps[activeTab].toastMessage);
    }
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 1000);
  };

  return (
    <div className="w-100 h-100 position-relative">
      <Steps>
        {steps?.map((step, index) => (
          <Fragment key={step.stepName}>
            <Step
              onClick={() => !steps[index].disabled && moveToCurrentStep(index)}
              allowed={index <= activeTab && !steps[index].disabled ? 1 : 0}
              active={activeTab === index ? 1 : 0}
            >
              <span className={activeTab === index && 'active'}>
                {(tabNames && tabNames[step.stepName]?.text) || step.stepText}
              </span>
              {index < activeTab ? <CheckMark size={20} /> : <></>}
            </Step>
            {index + 1 < steps.length ? (
              <ArrowRight color={theme.colors.grey} size={20} />
            ) : (
              <></>
            )}
          </Fragment>
        ))}
      </Steps>
      <ActiveStep>
        {loading && <WhiteBgLoader content="Loading" />}
        {!steps[activeTab]?.fetchNeeded &&
          isValidElement(steps[activeTab]?.render) &&
          cloneElement(steps[activeTab].render, {
            ...steps[activeTab].render.props,
            moveToNextStep,
          })}
        {CommonUtility.isValidArray(errors) && (
          <div className="mx-3 mb-3">
            {errors.map(error => (
              <DangerText>{error.text}</DangerText>
            ))}
          </div>
        )}
        {steps[activeTab]?.fetchNeeded && <StepRenderer />}
      </ActiveStep>
      {animate && (
        <AnimateCheck>
          <Check size={60} />
          <WhiteOverlay />
        </AnimateCheck>
      )}
    </div>
  );
}

function StepRenderer() {
  return <div>step</div>;
}
