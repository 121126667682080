import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';
import { BodyContainer } from '../../../elements';
import {
  ShipmentDetailHook,
  shipmentDetailsExtractor,
} from '../../../hooks/shipments';
import {
  DetailsLeftTab,
  DetailsRightTab,
  ReadOnlyShipmentItems,
} from '../../../page-components/shipments';
import { MetricImperialToggle } from '../../../components/MetricImperialToggle';
import { SearchInput } from '../../../components';
import { ShipmentDetailHeader } from '../../../page-components/shipments/Headers/DetailHeader';
import { MarkRecievedPopup } from '../../../page-components/shipments/Popups';

const TabsContainer = styled.div`
  column-gap: 15px;
  row-gap: 15px;

  .tab-right,
  .tab-left {
    flex: 1;
  }
`;

export function ShipmentDetailScreen() {
  const { id } = useParams();
  const [itemRecieving, setItemRecieving] = useState(null);
  const { data, loading, refresh } = ShipmentDetailHook(id);
  const [imperial, setImperial] = useState(false);
  const [search, setSearch] = useState('');
  const { summary, cbm } = shipmentDetailsExtractor(data);

  const closeRecievingPopup = () => {
    setItemRecieving(null);
  };

  const openRecievingPopup = (item, attribute) => {
    setItemRecieving({
      item,
      attribute,
    });
  };

  return (
    <BodyContainer>
      <ShipmentDetailHeader
        data={data}
        imperial={imperial}
        cbm={cbm}
        summary={summary}
      />
      <TabsContainer className="row">
        <DetailsLeftTab data={data} loading={loading} />
        <DetailsRightTab data={data} loading={loading} />
      </TabsContainer>
      <div className="d-flex justify-content-between mt-4">
        <MetricImperialToggle
          checked={imperial}
          onChange={() => setImperial(!imperial)}
        />
        <SearchInput
          search={search}
          setSearch={setSearch}
          placeholder="Search by Title, SKU, UPC"
        />
      </div>
      <ReadOnlyShipmentItems
        loading={loading}
        search={search}
        imperial={imperial}
        data={data}
        openRecievingPopup={openRecievingPopup}
      />
      <MarkRecievedPopup
        itemRecieving={itemRecieving}
        open={!!itemRecieving}
        onClose={closeRecievingPopup}
        shipmentData={data}
        refresh={refresh}
      />
    </BodyContainer>
  );
}
