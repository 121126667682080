import { useState } from 'react';
import { AppTable } from '../../../components/AppTable';
import { BodyContainer, HeaderBar, PageTitle } from '../../../elements';
import { InventoryListHook } from '../../../hooks/inventory';
import {
  InventoryAdjustmentPopup,
  InventoryItemRow,
} from '../../../page-components';

function Header() {
  return (
    <HeaderBar className="row">
      <div className="col-6 pt-2">
        <PageTitle>Inventory List</PageTitle>
      </div>
    </HeaderBar>
  );
}

export function InventoryListScreen() {
  const [adjustmentItem, setAdjusmentItem] = useState(null);

  const openAdjustmentPopup = item => {
    setAdjusmentItem(item);
  };

  const closeAdjustmentPopup = () => {
    setAdjusmentItem(null);
  };

  const {
    data,
    loading,
    totalData,
    totalPages,
    filter,
    filterChanged,
    pageChanged,
    refresh,
  } = InventoryListHook();

  const columns = [
    {
      text: 'Product Description',
      key: 'description',
      width: 2,
    },
    {
      text: '',
      key: 'sku',
      width: 4,
    },
    {
      text: 'Customer',
      key: 'cusomter',
      width: 2,
    },
    {
      text: 'Warehoue',
      key: 'warehouse',
      width: 2,
    },
    {
      text: 'Location',
      key: 'location',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Quantity',
      key: 'quantity',
      width: 2,
      textAlign: 'left',
    },
  ];

  return (
    <BodyContainer>
      <Header />
      <AppTable
        columns={columns}
        data={data}
        loading={loading}
        totalData={totalData}
        totalPages={totalPages}
        RowComponent={<InventoryItemRow openPopup={openAdjustmentPopup} />}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
      />
      <InventoryAdjustmentPopup
        onClose={closeAdjustmentPopup}
        open={!!adjustmentItem}
        item={adjustmentItem}
        refresh={refresh}
      />
    </BodyContainer>
  );
}
