import { Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledDimmer = styled(Dimmer)`
  margin: 12px 10px;
  margin-left: 65px;
  height: calc(100% - 24px) !important;
`;

export function LoaderBar({ content = 'Loading', ...rest }) {
  return (
    <Dimmer active inverted {...rest}>
      <Loader content={content} />
    </Dimmer>
  );
}

export function LoaderBarFullScreen({ content = 'Loading', ...rest }) {
  return (
    <StyledDimmer active inverted {...rest}>
      <Loader content={content} />
    </StyledDimmer>
  );
}
