import { BaseService } from './base';

class Upload {
  media(url, file, contentType) {
    const header = {
      'Content-type': contentType,
    };
    return BaseService.upload(url, file, header);
  }
}

const UploadService = new Upload();
Object.freeze(UploadService);
export { UploadService };
