import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Client extends CRUDService {
  constructor() {
    super(APIPath.organizationDashboard);
  }

  export() {
    return BaseService.get(`${APIPath.organizationDashboard}/export`);
  }
}

const ClientService = new Client();
Object.freeze(ClientService);
export { ClientService };
