import { TableCell, TableRow } from 'semantic-ui-react';
import { Trash } from '@phosphor-icons/react';
import styled from 'styled-components';
import { ActionEditDots, ActionMenuItem } from '../../../components';

const ActionEditTableRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    img.m-auto {
      opacity: 0.3;
    }
    .action-container {
      opacity: 1 !important;
    }
  }
`;

export function UserRow({ item, remove }) {
  return (
    <ActionEditTableRow>
      <TableCell>{item.firstName}</TableCell>
      <TableCell>{item.email}</TableCell>
      <TableCell>{item.role}</TableCell>
      <TableCell>
        <ActionEditDots preventChangingBg on="hover" position="left center">
          <ActionMenuItem className="danger" onClick={() => remove(item)}>
            <Trash />
            Delete
          </ActionMenuItem>
        </ActionEditDots>
      </TableCell>
    </ActionEditTableRow>
  );
}
