import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Plus } from '@phosphor-icons/react';
import { Loader } from 'semantic-ui-react';
import {
  MuteText,
  StyledButton,
  StyledSliderCheckbox,
} from '../../../../elements';
import { ZoneDetailHook, ZoneHook } from '../../../../hooks/zone';
import {
  CreateAislePopup,
  CreateRackPopup,
  RackViewer,
  ZoneDimensionsDetails,
} from '../../../../page-components/zones';
import { CommonUtility } from '../../../../utility';
import { BackButton } from '../../../../components';

export function ZoneDetailScreen() {
  const { zoneId } = useParams();
  const [rackPopup, setRackPopup] = useState(false);
  const [aislePopup, setAislePopup] = useState(false);
  const [aisle, setAisle] = useState(null);
  const [hasIsles, setHasIsles] = useState(false);

  const { data, refreshData, count, aloneRacks, loading } = ZoneHook(
    zoneId,
    hasIsles,
  );
  const {
    data: ZoneData,
    loading: ZoneLoading,
    refresh: refreshZoneData,
  } = ZoneDetailHook(zoneId);

  useEffect(() => {
    if (CommonUtility.isValidArray(data)) {
      // has aisles
      setHasIsles(true);
    }
  }, [data]);

  const toggleAisles = () => {
    setHasIsles(!hasIsles);
  };

  const openAislePopup = () => {
    setAislePopup(true);
  };

  const closeAislePopup = () => {
    setAislePopup(false);
  };

  const openRackPopup = () => {
    setRackPopup(true);
  };

  const closeRackPopup = () => {
    setRackPopup(false);
    setAisle(null);
  };

  const addRackInAsile = aisle => {
    setAisle(aisle);
    setRackPopup(true);
  };

  return (
    <div className="py-3">
      <div className="d-flex mb-2 justify-content-between">
        <div className="d-flex align-items-center">
          <BackButton />
          {ZoneLoading ? (
            <Loader active inline />
          ) : (
            <h3 className="m-0 mr-3">
              {ZoneData?.code}: {ZoneData?.type}
            </h3>
          )}
          <div className="d-flex align-items-center ml-4">
            <MuteText>No Aisles</MuteText>
            <StyledSliderCheckbox
              onChange={toggleAisles}
              checked={hasIsles}
              className="mx-2"
            />
            <MuteText>Aisles</MuteText>
          </div>
        </div>
        {hasIsles ? (
          <StyledButton onClick={openAislePopup} className="flex-btn">
            <Plus className="mr-1" /> Aisles
          </StyledButton>
        ) : (
          <StyledButton onClick={openRackPopup} className="flex-btn">
            <Plus className="mr-1" /> Rack
          </StyledButton>
        )}
      </div>
      <div className="d-flex">
        {hasIsles && (
          <div className="d-flex mr-4">
            <MuteText className="mr-2">Total Aisles</MuteText>
            <span>{count?.aisles}</span>
          </div>
        )}
        <div className="d-flex">
          <MuteText className="mr-2">Total Racks</MuteText>
          <span>{count?.racks}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-7 pl-0">
          <RackViewer
            aloneRacks={aloneRacks}
            data={data}
            addRackInAsile={addRackInAsile}
            aisleView={hasIsles}
            loading={loading}
          />
        </div>
        <div className="col-5 p-0 mt-2">
          <ZoneDimensionsDetails
            refresh={refreshZoneData}
            detailLoading={loading}
            data={ZoneData}
          />
        </div>
      </div>
      <CreateRackPopup
        zoneId={zoneId}
        open={rackPopup}
        onClose={closeRackPopup}
        refreshData={refreshData}
        aisle={aisle}
      />
      <CreateAislePopup
        zoneId={zoneId}
        open={aislePopup}
        onClose={closeAislePopup}
        refreshData={refreshData}
      />
    </div>
  );
}
