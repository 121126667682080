import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormGroup } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { SkupremeModal } from '../../components';
import {
  DropdownFormField,
  HoverBorderButton,
  NumberFormField,
  StyledButton,
} from '../../elements';
import {
  bayOptions,
  CommonUtility,
  RackService,
  ToastMessage,
} from '../../utility';

const ZoneSchema = yup.object().shape({
  bay: yup.number(),
  level: yup.number(),
  type: yup.string().required('Type is required'),
});

export function AddBayPopup({ onClose, rackId, open, refreshData }) {
  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(ZoneSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (CommonUtility.isValidObject(open)) {
      reset({
        level: open.level,
        bay: Number(open.bay || 0) + 1,
      });
    }
  }, [open]);

  const onSubmit = async formData => {
    try {
      setLoading(true);
      const payload = {
        rackId,
        locations: [
          {
            level: formData.level,
            bay: formData.bay,
            position: 1,
            type: formData.type,
          },
        ],
      };
      await RackService.addLocations(payload);
      ToastMessage.success('Aisles successfully created.');
      onClose();
      refreshData();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkupremeModal size="tiny" title="Create Bay" open={open} onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            name="level"
            label="Level"
            error={errors.level}
            hint={errors.level?.message}
            disabled
          />
          <NumberFormField
            control={control}
            name="bay"
            label="Bay"
            error={errors.bay}
            hint={errors.bay?.message}
            disabled
          />
          <DropdownFormField
            control={control}
            name="type"
            label="Type"
            error={errors.type}
            hint={errors.type?.message}
            options={bayOptions}
          />
        </FormGroup>
        <div className="d-flex justify-content-end">
          <HoverBorderButton type="button" onClick={onClose}>
            Cancel
          </HoverBorderButton>
          <StyledButton type="submit" loading={loading} className="ml-1">
            Create
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
