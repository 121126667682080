import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Warehouse extends CRUDService {
  constructor() {
    super(APIPath.warehouses);
  }

  updateWarehouse(id, payload) {
    return BaseService.patch(`${APIPath.warehouses}/${id}`, payload);
  }
}

const WarehouseService = new Warehouse();
Object.freeze(WarehouseService);
export { WarehouseService };
