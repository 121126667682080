import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Invites extends CRUDService {
  constructor() {
    super(APIPath.invites);
  }

  getUsers() {
    return BaseService.get(APIPath.users);
  }

  resendInvite(inviteId) {
    return BaseService.post(`${APIPath.invites}/${inviteId}/resend`);
  }

  removeUser(userId) {
    return BaseService.remove(`${APIPath.users}/${userId}`);
  }

  check(token) {
    return BaseService.get(`${APIPath.invites}/${token}/check`);
  }

  accept(token, data) {
    return BaseService.post(`${APIPath.invites}/${token}/accept`, data);
  }
}

const InvitesService = new Invites();
Object.freeze(InvitesService);
export { InvitesService };
