import { TableCell, TableRow } from 'semantic-ui-react';
import styled from 'styled-components';
import { useMemo } from 'react';
import { Eye, MapPin } from '@phosphor-icons/react';
import { AppTable, ItemImage } from '../../../components';
import { MuteText, ShadowBox } from '../../../elements';
import { CommonUtility } from '../../../utility/common';
import { OrderStatus } from '../../../utility';

export function LabelDetails({ data }) {
  const columns = [
    {
      text: 'Tracking Id',
      key: 'tracking id',
      textAlign: 'left',
      width: 4,
    },
    {
      text: 'Carrier',
      key: 'carrier',
      textAlign: 'left',
      width: 1,
    },
    {
      text: 'Service',
      key: 'service',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Dimensions',
      key: 'dimensions',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'File',
      key: 'file',
      textAlign: 'left',
      width: 2,
    },
  ];

  return (
    <ShadowBox className="mt-3">
      <b>Labels</b>
      <AppTable
        columns={columns}
        noShadow
        data={data?.labels}
        RowComponent={
          <LabelRow
            totalLabels={data?.labels?.length}
            itemsData={data?.items}
          />
        }
      />
    </ShadowBox>
  );
}

const ItemsHeading = styled.b`
  margin-bottom: -12px;
  margin-top: 15px;
  font-size: 14px;
`;

const LabelTableRow = styled(TableRow)`
  .first-cell {
    padding-left: 0px !important;
  }
  .last-cell {
    padding-right: 0px !important;
  }
`;

const FileHref = styled.a`
  display: flex;
  align-items: center;
`;

const CarrierCode = styled.span`
  text-transform: uppercase;
`;

function LabelRow({ item, itemsData, totalLabels, index }) {
  const isLastLabel = totalLabels === index + 1;

  return (
    <>
      <LabelTableRow>
        <TableCell className="first-cell">
          <div className="d-flex flex-column pt-4">
            <span>{item.tracking_id}</span>
            <ItemsHeading>Items</ItemsHeading>
          </div>
        </TableCell>
        <TableCell>
          <CarrierCode>{item.carrier_code}</CarrierCode>
        </TableCell>
        <TableCell>{item.service?.value}</TableCell>
        <TableCell>
          <div className="d-flex justify-content-left">
            <span>
              {CommonUtility.roundNumber(item?.dimensions?.length?.value)}
            </span>
            <MuteText className="mx-1">x</MuteText>
            <span>
              {CommonUtility.roundNumber(item?.dimensions?.width?.value)}
            </span>
            <MuteText className="mx-1">x</MuteText>
            <span>
              {CommonUtility.roundNumber(item?.dimensions?.height?.value)}
            </span>
            <MuteText className="ml-1">
              ({item?.dimensions?.width?.unit}.)
            </MuteText>
          </div>
        </TableCell>
        <TableCell className="last-cell">
          <FileHref href={item.url} download target="_blank">
            <Eye className="mr-2" size={18} />
            <span>View Label</span>
          </FileHref>
        </TableCell>
      </LabelTableRow>
      {item?.items?.map((itemDetail, index) => (
        <ItemRow
          showBorder={!isLastLabel && item.items?.length === index + 1}
          item={itemDetail}
          itemsData={itemsData}
        />
      ))}
    </>
  );
}

const ItemTableRow = styled(TableRow)`
  td {
    background-color: ${({ theme }) => theme.colors.box};
  }

  &.show-border {
    td {
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
    }
  }
`;

function ItemRow({ item, itemsData, showBorder }) {
  const itemDetail = useMemo(
    () => itemsData.find(x => x.item_id === item.item_id),
    [item, itemsData],
  );

  return (
    <ItemTableRow className={showBorder ? 'show-border' : ''}>
      <TableCell className="first-cell">
        <div>
          <b>{itemDetail?.title}</b>
          <div>
            <MuteText>SKU</MuteText>
            <span className="ml-1">{itemDetail?.sku}</span>
          </div>
        </div>
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell>
        <div>
          <MuteText>Price</MuteText>
          <span className="ml-1">
            {CommonUtility.currencyFormat(itemDetail?.itemPrice)}
          </span>
        </div>
      </TableCell>
      <TableCell className="last-cell">
        <div>
          <MuteText>Qty</MuteText>
          <span className="ml-1">{itemDetail?.qty}</span>
        </div>
      </TableCell>
    </ItemTableRow>
  );
}

const PickingItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 17px;
`;

const Border = styled.div`
  background: ${({ theme }) => theme.colors.box};
  height: 1px;
  width: 100%;
  margin: 10px 0;
`;

const LocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.box};
  row-gap: 15px;
  padding: 7px;
  border-radius: 5px;
  margin-top: 7px;
`;

export function PickingList({ data }) {
  const pickingList = useMemo(
    () =>
      data?.items?.filter(x => x.state === OrderStatus.pendingFulfillment) ||
      [],
    [data],
  );

  if (!CommonUtility.isValidArray(pickingList)) {
    return <></>;
  }

  return (
    <ShadowBox className="mt-4">
      <b>Picking List</b>
      <PickingItemsContainer>
        {pickingList.map((item, index) => (
          <>
            <div>
              <div className="d-flex">
                <ItemImage size="mini" item={item} />
                <div className="ml-2">
                  <span>{item.title}</span>
                  <div className="d-flex">
                    <MuteText>SKU</MuteText>
                    <span className="ml-2">{item.sku}</span>
                  </div>
                </div>
              </div>
              <LocationsContainer>
                {item.inventory.map(inventory => (
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <MapPin className="mr-2" />
                      <span>{inventory?.location?.code}</span>
                    </div>
                    <div className="d-flex">
                      <MuteText>Available</MuteText>
                      <span className="ml-2">
                        {inventory.quantity?.available}
                      </span>
                    </div>
                  </div>
                ))}
              </LocationsContainer>
            </div>
            {index + 1 !== data?.items.length && <Border />}
          </>
        ))}
      </PickingItemsContainer>
    </ShadowBox>
  );
}
