import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { countryListHook } from '../../../hooks';
import {
  ControlledPhoneFormField,
  ControlledTextFormField,
  CountryDropdownFormField,
  DropdownFormField,
  NumberFormField,
  ShadowBox,
} from '../../../elements';
import {
  CommonUtility,
  decimalMask,
  measurementOptions,
  ToastMessage,
} from '../../../utility';
import { LoaderBar } from '../../../components';
import { dropdownOptionsIncludeFlag } from '../../../elements/Flag';
import { AlertFooterBar } from '../../../elements/FooterAlert';
import { WarehouseService } from '../../../utility/services/warehouse';
import { useWarehouseDetails } from '../../../screens/app/warehouse/id';

const TabContainer = styled.div`
  padding-top: 20px;
`;

const WarehouseSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Enter a valid Email'),
  phone: yup.string(),
  addressLineOne: yup.string().required('Address Line 1 is required'),
  addressLineTwo: yup.string(),
  city: yup.string().required('City is required'),
  countryCode: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  zipCode: yup.string().required('Zip Code is required'),
  length: yup
    .number()
    .positive('Length must be greater than 0')
    .typeError('Length is required')
    .required('Length is required'),
  width: yup
    .number()
    .positive('Width must be greater than 0')
    .typeError('Width is required')
    .required('Width is required'),
  height: yup
    .number()
    .positive('Height must be greater than 0')
    .typeError('Height is required')
    .required('Height is required'),
  measurementUnit: yup.string().required('Measurement Unit is required'),
});

export function WarehouseDetailTab() {
  const { id } = useParams();
  const { warehouseData, warehouseDataLoading, refreshWarehouse } =
    useWarehouseDetails();
  const { data: countryData } = countryListHook();
  const [saving, setSaving] = useState(false);

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(WarehouseSchema),
  });

  useEffect(() => {
    if (!CommonUtility.isValidObject(warehouseData)) return;
    resetForm(warehouseData);
  }, [warehouseData]);

  const resetForm = data => {
    reset({
      ...data.address,
      ...data.dimensions,
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
    });
  };

  const onSubmit = async formData => {
    try {
      setSaving(true);

      let payload = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        address: {
          addressLineOne: formData.addressLineOne,
          addressLineTwo: formData.addressLineTwo,
          city: formData.city,
          state: formData.state,
          zipCode: formData.zipCode,
          countryCode: formData.countryCode,
        },
        dimensions: {
          length: formData.length,
          width: formData.width,
          height: formData.height,
          measurementUnit: formData.measurementUnit,
        },
      };

      payload = CommonUtility.removeEmptyFields(payload);

      const res = await WarehouseService.updateWarehouse(id, payload);
      resetForm(res);
      refreshWarehouse();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <TabContainer>
      {warehouseDataLoading && <LoaderBar />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ShadowBox>
          <h3>Information</h3>
          <Form.Group>
            <ControlledTextFormField
              control={control}
              name="name"
              error={errors.name}
              label="Name"
              hint={errors.name?.message}
              required
              width={4}
            />
            <ControlledTextFormField
              control={control}
              name="email"
              error={errors.email}
              label="E-mail"
              required
              hint={errors.email?.message}
              width={4}
            />
            <ControlledPhoneFormField
              error={errors.phone}
              hint={errors.phone?.message}
              label="Phone"
              width={4}
              name="phone"
              control={control}
              inputContainerClass="input-height"
            />
          </Form.Group>
        </ShadowBox>
        <ShadowBox className="mt-3">
          <h3>Dimensions</h3>
          <Form.Group>
            <NumberFormField
              control={control}
              name="length"
              error={errors.length}
              label="Length"
              required
              hint={errors.length?.message}
              width={4}
              maskOptions={decimalMask}
            />
            <NumberFormField
              control={control}
              name="width"
              error={errors.width}
              label="Width"
              hint={errors.width?.message}
              required
              maskOptions={decimalMask}
              width={4}
            />
            <NumberFormField
              maskOptions={decimalMask}
              control={control}
              name="height"
              error={errors.height}
              label="Height"
              required
              hint={errors.height?.message}
              width={4}
            />
            <DropdownFormField
              control={control}
              label="Measurement Unit"
              error={errors.measurementUnit}
              name="measurementUnit"
              hint={errors.measurementUnit?.message}
              width={4}
              options={measurementOptions}
            />
          </Form.Group>
        </ShadowBox>
        <ShadowBox className="mt-3">
          <h3>Address</h3>
          <Form.Group>
            <ControlledTextFormField
              control={control}
              label="Address Line 1"
              error={errors.addressLineOne}
              name="addressLineOne"
              hint={errors.addressLineOne?.message}
              required
              width={4}
            />
            <ControlledTextFormField
              control={control}
              label="Address Line 2"
              error={errors.addressLineTwo}
              name="addressLineTwo"
              hint={errors.addressLineTwo?.message}
              width={4}
            />
            <ControlledTextFormField
              control={control}
              name="city"
              error={errors.city}
              label="City"
              hint={errors.city?.message}
              required
              width={4}
            />
            <CountryDropdownFormField
              control={control}
              name="countryCode"
              error={errors.countryCode}
              label="Country"
              hint={errors.countryCode?.message}
              width={4}
              required
              options={dropdownOptionsIncludeFlag(countryData)}
            />
          </Form.Group>
          <Form.Group>
            <ControlledTextFormField
              control={control}
              name="state"
              error={errors.state}
              label="State"
              hint={errors.state?.message}
              required
              width={4}
            />
            <ControlledTextFormField
              control={control}
              name="zipCode"
              error={errors.zipCode}
              label="Zip Code"
              width={4}
              hint={errors.zipCode?.message}
              required
            />
          </Form.Group>
        </ShadowBox>
        {CommonUtility.isValidObject(dirtyFields) && (
          <AlertFooterBar
            message="Save Changes"
            cancel={() => resetForm()}
            save={handleSubmit(onSubmit)}
            loading={saving}
          />
        )}
      </Form>
    </TabContainer>
  );
}
