import { CommonUtility } from '../common';
import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Order extends CRUDService {
  constructor() {
    super(APIPath.orders);
  }

  orderAction = (orderId, data) =>
    BaseService.put(`${APIPath.orders}/${orderId}/action`, data);

  orderDetail = (orderId, params) => {
    const url = `orders/${orderId}/?${CommonUtility.objectToParams(params)}`;
    return BaseService.get(url);
  };
}

const OrderService = new Order();
Object.freeze(OrderService);
export { OrderService };
