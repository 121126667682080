import { cloneElement, isValidElement } from 'react';
import styled from 'styled-components';
import { Info } from '@phosphor-icons/react';
import {
  ConversionUnitViewer,
  ConversionViewer,
  DarkPopup,
} from '../../components';
import { BoldText, MuteText } from '../../elements';
import { calculationToolTips, CommonUtility } from '../../utility';

const CBMContainer = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  .value {
    color: ${({ theme, dark }) =>
      dark ? theme.colors.primary : theme.colors.grey};
  }
`;

function CBMPopupText({ imperial, cbm, cbf }) {
  return (
    <div>
      <div>{imperial ? calculationToolTips.cbf : calculationToolTips.cbm}</div>
      <br />
      {imperial ? (
        <div>
          CBM ={' '}
          {CommonUtility.decimalWithCommas(CommonUtility.roundNumber(cbm))}
        </div>
      ) : (
        <div>
          CBF ={' '}
          {CommonUtility.decimalWithCommas(CommonUtility.roundNumber(cbf))}
        </div>
      )}
    </div>
  );
}

export function CBMRenderer({ cbm, cbf, imperial, className, dark = false }) {
  return (
    <div className={`d-flex ${className} align-items-center `}>
      <DarkPopup
        trigger={
          <CBMContainer className="d-flex align-items-center" dark={dark}>
            <span>{imperial ? 'CBF ' : 'CBM '}</span>
            <BoldText className="mx-1 value">
              {CommonUtility.roundNumber(imperial ? cbf : cbm)}
            </BoldText>
            <Info />
          </CBMContainer>
        }
      >
        <CBMPopupText imperial={imperial} cbm={cbm} cbf={cbf} />
      </DarkPopup>
    </div>
  );
}

export const calculateListTotal = items => {
  const total = {
    cartons: 0,
    units: 0,
    retails: 0,
    supplierValue: 0,
    grossWeight: 0,
  };
  items.forEach(item => {
    let latestAttribute = item.attributes?.find?.(x =>
      CommonUtility.isValidObject(x.attributes),
    )?.attributes;
    const isMultiple = CommonUtility.isValidArray(item.aliases);

    if (isMultiple) {
      let cartons = 0;
      let unitsTotal = 0;
      item.aliases.forEach(aliasItem => {
        latestAttribute = aliasItem.attributes;
        const units = aliasItem.qty;
        const ctns = aliasItem.qty / latestAttribute.units_per_carton;

        if (ctns) {
          cartons += ctns;
          total.cartons += ctns;
          total.units += +units || 0;
          unitsTotal += +units || 0;
          total.grossWeight += ctns * latestAttribute.carton_gross_weight.value;
        }
      });
      total.retails += (cartons || 0) * (item.price || 0);
      total.supplierValue +=
        (unitsTotal || 0) *
        (item.supplierItemCost || item.supplierComponentCost || 0);
    } else if (item.qty && latestAttribute) {
      const cartons = (item.qty || 0) / (latestAttribute.units_per_carton || 1);
      total.cartons += cartons;
      total.units += +item.qty || 0;
      total.retails += (+item.qty || 0) * (item.price || 0);
      total.supplierValue +=
        (+item.qty || 0) *
        (item.supplierItemCost || item.supplierComponentCost || 0);
      total.grossWeight += cartons * latestAttribute.carton_gross_weight?.value;
    }
  });
  return total;
};

export function OriginRow({ origins }) {
  return (origins || []).length === 0 ? (
    <span />
  ) : origins.length > 1 ? (
    <DarkPopup trigger={<span>Multiple</span>} hoverable>
      <div>
        {(origins || []).map(item => (
          <div key={item}>{item}</div>
        ))}
      </div>
    </DarkPopup>
  ) : (
    <span>{origins[0]}</span>
  );
}

export const ShipmentStatus = {
  reject: 'rejected',
  accept: 'accepted',
  draft: 'draft',
  preparing: 'preparing',
  receiving: 'receiving',
  completed: 'completed',
  in_transit: 'in_transit',
  ready_for_shipment: 'ready_for_shipment',
  deleted: 'deleted',
  shipped: 'shipped',
  prediction: 'prediction',
  DELETED: 'DELETED',
  cancelled: 'cancelled',
  CLOSED: 'CLOSED',
};

const Row = styled.div`
  justify-content: space-between;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`;

export function ProductPropertiesConverted({
  attributes,
  isMultiple,
  AliasRenderer,
  showAlias = true,
  imperial = false,
}) {
  return (
    <>
      {showAlias ? (
        <Row>
          <MuteText>Alias</MuteText>
          <div className="text-right">
            {!attributes?.alias ? (
              '-'
            ) : (
              <span>
                {isValidElement(AliasRenderer)
                  ? cloneElement(AliasRenderer)
                  : isMultiple
                  ? 'Multiple'
                  : attributes.alias}
              </span>
            )}
          </div>
        </Row>
      ) : (
        <></>
      )}
      <Row>
        <MuteText>Carton Size</MuteText>
        <div className="text-right">
          {!attributes?.carton_length ? (
            '-'
          ) : (
            <span>
              {isMultiple ? (
                '-'
              ) : (
                <>
                  <ConversionViewer
                    showUnit={false}
                    value={attributes.carton_length?.value}
                    imperial={imperial}
                    originalUnit={attributes?.carton_height?.unit}
                  />
                  <span className="px-1">x</span>
                  <ConversionViewer
                    showUnit={false}
                    value={attributes.carton_width?.value}
                    imperial={imperial}
                    originalUnit={attributes?.carton_height?.unit}
                  />
                  <span className="px-1">x</span>
                  <ConversionViewer
                    showUnit={false}
                    value={attributes.carton_height?.value}
                    imperial={imperial}
                    originalUnit={attributes?.carton_height?.unit}
                  />
                  <MuteText className="pl-1">
                    <ConversionUnitViewer
                      originalUnit={attributes?.carton_height?.unit}
                      imperial={imperial}
                    />
                  </MuteText>
                </>
              )}
            </span>
          )}
        </div>
      </Row>
      <Row>
        <MuteText>Quantity Per Carton</MuteText>
        <div className="text-right">{attributes?.units_per_carton}</div>
      </Row>
      <Row>
        <MuteText>Gross Weight (Carton)</MuteText>
        <div className="text-right">
          {isMultiple ? (
            '-'
          ) : (
            <ConversionViewer
              value={attributes?.carton_gross_weight?.value}
              imperial={imperial}
              originalUnit={attributes?.carton_gross_weight?.unit}
            />
          )}
        </div>
      </Row>
    </>
  );
}
