import styled from 'styled-components';
import { WarehouseSetup } from '../../../page-components';

const BodyContainer = styled.div`
  padding: 0 100px;
  padding-top: 67px;
`;

export function OnboardingScreen() {
  return (
    <BodyContainer>
      <WarehouseSetup />
    </BodyContainer>
  );
}
