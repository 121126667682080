import { Image, Flag as UIFlag } from 'semantic-ui-react';
import styled from 'styled-components';

const FlagImage = styled(Image)`
  display: inline !important;
  margin-right: 7px;
  min-width: ${({ width }) => width}px !important;
  min-height: ${({ height }) => height}px !important;
  max-width: ${({ width }) => width}px !important;
  max-height: ${({ height }) => height}px !important;
`;

export const dropdownOptionsIncludeFlag = (arr = []) =>
  arr.map(opt => {
    const flagSource =
      flagImages?.[opt.name?.toUpperCase?.()] ||
      flagImages?.[opt.value?.toUpperCase?.()] ||
      flagImages?.[opt.flag?.toUpperCase?.()];
    return {
      ...opt,
      image: { src: flagSource || flagImages.WW },
      flag: null,
    };
  });

export function Flag({ width = 20, height = 15, name, title, className = '' }) {
  return flagImages[name?.toUpperCase()] ? (
    <FlagImage
      width={width}
      height={height}
      className={className}
      src={flagImages[name?.toUpperCase()]}
      title={title}
    />
  ) : (
    <UIFlag name={name?.toLowerCase()} className={className} />
  );
}

export const flagImages = {
  AD: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AD.png',
  AE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AE.png',
  AF: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AF.png',
  AG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AG.png',
  AI: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AI.png',
  AM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AM.png',
  AL: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AL.png',
  AN: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AN.png',
  AU: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AU.png',
  AO: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AO.png',
  AR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AR.png',
  AT: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AT.png',
  AW: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AW.png',
  AX: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AX.png',
  AZ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/AZ.png',
  BA: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BA.png',
  BB: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BB.png',
  BD: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BD.png',
  BE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BE.png',
  BF: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BF.png',
  BG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BG.png',
  BH: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BH.png',
  BI: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BI.png',
  BJ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BJ.png',
  BM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BM.png',
  BN: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BN.png',
  BO: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BO.png',
  BR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BR.png',
  BS: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BS.png',
  BT: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BT.png',
  BW: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BW.png',
  BY: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BY.png',
  BZ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/BZ.png',
  CAF: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CAF.png',
  CA: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CA.png',
  CD: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CD.png',
  CF: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CF.png',
  CG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CG.png',
  CH: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CH.png',
  CI: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CI.png',
  CL: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CL.png',
  CM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CM.png',
  CN: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CN.png',
  CO: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CO.png',
  COC: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/COC.png',
  CR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CR.png',
  CSA: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CSA.png',
  CU: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CU.png',
  CV: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CV.png',
  CY: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CY.png',
  CZ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/CZ.png',
  DE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/DE.png',
  DJ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/DJ.png',
  DK: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/DK.png',
  DM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/DM.png',
  DO: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/DO.png',
  DZ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/DZ.png',
  EC: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/EC.png',
  EE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/EE.png',
  EG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/EG.png',
  ER: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/ER.png',
  ES: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/ES.png',
  ET: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/ET.png',
  EU: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/EU.png',
  FI: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/FI.png',
  FJ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/FJ.png',
  FK: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/FK.png',
  FM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/FM.png',
  FR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/FR.png',
  GA: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GA.png',
  GB: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GB.png',
  GD: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GD.png',
  GE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GE.png',
  GG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GG.png',
  GH: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GH.png',
  GI: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GI.png',
  GM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GM.png',
  GN: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GN.png',
  GP: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GP.png',
  GQ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GQ.png',
  GR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GR.png',
  GT: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GT.png',
  GW: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GW.png',
  GY: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/GY.png',
  HK: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/HK.png',
  HN: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/HN.png',
  HR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/HR.png',
  HT: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/HT.png',
  HU: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/HU.png',
  ID: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/ID.png',
  IE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/IE.png',
  IL: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/IL.png',
  IM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/IM.png',
  IN: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/IN.png',
  IR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/IR.png',
  IS: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/IS.png',
  IT: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/IT.png',
  JE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/JE.png',
  JM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/JM.png',
  JO: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/JO.png',
  JP: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/JP.png',
  KE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/KE.png',
  KG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/KG.png',
  KH: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/KH.png',
  KM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/KM.png',
  KN: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/KN.png',
  KP: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/KP.png',
  KR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/KR.png',
  KW: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/KW.png',
  KY: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/KY.png',
  KZ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/KZ.png',
  LA: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/LA.png',
  LB: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/LB.png',
  LC: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/LC.png',
  LI: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/LI.png',
  LK: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/LK.png',
  LR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/LR.png',
  LS: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/LS.png',
  LT: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/LT.png',
  LU: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/LU.png',
  LV: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/LV.png',
  LY: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/LY.png',
  MA: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MA.png',
  MC: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MC.png',
  MD: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MD.png',
  ME: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/ME.png',
  MG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MG.png',
  MK: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MK.png',
  ML: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/ML.png',
  MM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MM.png',
  MN: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MN.png',
  MO: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MO.png',
  MR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MR.png',
  MS: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MS.png',
  MT: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MT.png',
  MU: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MU.png',
  MV: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MV.png',
  MW: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MW.png',
  MX: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MX.png',
  MY: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MY.png',
  MZ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/MZ.png',
  NA: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/NA.png',
  NE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/NE.png',
  NG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/NG.png',
  NI: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/NI.png',
  NL: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/NL.png',
  NO: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/NO.png',
  NP: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/NP.png',
  NZ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/NZ.png',
  OM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/OM.png',
  PA: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/PA.png',
  PE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/PE.png',
  PF: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/PF.png',
  PG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/PG.png',
  PH: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/PH.png',
  PK: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/PK.png',
  PL: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/PL.png',
  PR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/PR.png',
  PT: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/PT.png',
  PW: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/PW.png',
  PY: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/PY.png',
  QA: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/QA.png',
  RO: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/RO.png',
  RS: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/RS.png',
  RU: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/RU.png',
  RW: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/RW.png',
  SA: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SA.png',
  SB: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SB.png',
  SC: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SC.png',
  SD: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SD.png',
  SE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SE.png',
  SG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SG.png',
  SH: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SH.png',
  SI: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SI.png',
  SK: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SK.png',
  SL: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SL.png',
  SM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SM.png',
  SN: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SN.png',
  SO: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SO.png',
  SR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SR.png',
  ST: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/ST.png',
  SV: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SV.png',
  SY: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SY.png',
  SZ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/SZ.png',
  TC: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TC.png',
  TD: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TD.png',
  TG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TG.png',
  TH: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TH.png',
  TJ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TJ.png',
  TL: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TL.png',
  TM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TM.png',
  TN: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TN.png',
  TO: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TO.png',
  TQ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TQ.png',
  TR: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TR.png',
  TT: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TT.png',
  TW: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TW.png',
  TZ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/TZ.png',
  UA: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/UA.png',
  US: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/USA.png',
  UG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/UG.png',
  UZ: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/UZ.png',
  VC: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/VC.png',
  VE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/VE.png',
  VG: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/VG.png',
  VN: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/VN.png',
  VU: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/VU.png',
  WS: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/WS.png',
  WW: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/WW.png',
  YE: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/YE.png',
  YU: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/YU.png',
  ZA: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/ZA.png',
  ZM: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/ZM.png',
  ZW: 'https://skupreme-artifacts.s3.us-east-1.amazonaws.com/public/flags/ZW.png',
};
