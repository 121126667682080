import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Plus } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { RackDetailHook } from '../../../../../hooks';
import { MuteText, ShadowBox, StyledButton } from '../../../../../elements';
import { CommonUtility } from '../../../../../utility';
import {
  AddBayPopup,
  EditBayPopup,
  RackDimensionsDetails,
} from '../../../../../page-components';
import { BackButton } from '../../../../../components';

const LocationRenderer = styled.div`
  padding: 10px;
  position: relative;
  display: flex;
  padding-bottom: 28px;
  overflow: hidden;

  .name {
    background: ${({ theme }) => theme.colors.primary};
    position: absolute;
    padding: 1px 0;
    bottom: 4px;
    left: 0;
    color: ${({ theme }) => theme.colors.white};
    width: 100%;
    text-align: center;
  }

  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
`;

const LocationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 80px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  padding: 0 5px;
  color: black;
  position: relative;
  z-index: 2;
`;

const LevelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 50px;
`;

const LeftStand = styled.div`
  position: absolute;
  height: calc(100% + 50px);
  top: 0px;
  width: 4px;
  left: -2px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
`;

const RightStand = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.primary};
  height: calc(100% + 50px);
  top: 0px;
  width: 4px;
  border-radius: 5px;
  right: -2px;
`;

const LevelBackground = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.box};
  width: 100%;
  height: calc(100% - 10px);
  left: 0;
  top: 6px;
`;

function AddBay(props) {
  return (
    <LocationContainer {...props}>
      <Plus /> Bay
    </LocationContainer>
  );
}

export function RackDetails() {
  const { rackId } = useParams();
  const { data, locationData, refreshData, loading } = RackDetailHook(rackId);
  const [bayPopup, setBayPopup] = useState(false);
  const [levelsData, setLevelsData] = useState([]);
  const [location, setLocation] = useState(null);

  const openLocationPopup = loc => {
    setLocation(loc);
  };

  const closeLocationPopup = () => {
    setLocation(null);
  };

  useEffect(() => {
    if (CommonUtility.isValidArray(locationData)) {
      setLevelsData(locationData.reverse());
    }
  }, [locationData]);

  const createNewBay = (level, bay) => {
    setBayPopup({
      level,
      bay,
    });
  };

  const closePopup = () => {
    setBayPopup(null);
  };

  const addLevel = () => {
    let levels = [...levelsData];
    levels.unshift({
      level: levelsData[0]?.level + 1,
    });

    setLevelsData(levels);
  };

  return (
    <div className="py-3">
      <div className="mb-2 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <BackButton />
          <h3 className="m-0">
            {data?.code} {loading && <Loader size="small" active inline />}
          </h3>
        </div>
        <StyledButton onClick={addLevel} className="flex-btn">
          <Plus className="mr-1" /> Level
        </StyledButton>
      </div>
      <div>
        <MuteText>Dimensions</MuteText>
        <span className="ml-2">{data.dimensions?.length}</span>
        <MuteText className="mx-1">x</MuteText>
        <span>{data.dimensions?.width}</span>
        <MuteText className="mx-1">
          ({data.dimensions?.measurementUnit})
        </MuteText>
      </div>
      <div className="row mt-2">
        <ShadowBox className="col-7">
          <LevelsContainer>
            {levelsData?.map(location => (
              <LocationRenderer>
                <LevelBackground />
                <div className="name">Level {location.level}</div>
                {location.locations?.map(location => (
                  <LocationContainer
                    onClick={() => openLocationPopup(location)}
                  >
                    {location?.code}
                  </LocationContainer>
                ))}
                <AddBay
                  onClick={() =>
                    createNewBay(
                      location.level,
                      location?.locations?.[location?.locations?.length - 1]
                        ?.coordinates?.bay,
                    )
                  }
                />
              </LocationRenderer>
            ))}
            {CommonUtility.isValidArray(levelsData) && (
              <>
                <LeftStand />
                <RightStand />
              </>
            )}
          </LevelsContainer>
        </ShadowBox>
        <div className="col-5">
          <RackDimensionsDetails detailLoading={loading} data={data} />
        </div>
      </div>
      <AddBayPopup
        refreshData={refreshData}
        open={bayPopup}
        rackId={rackId}
        onClose={closePopup}
      />
      <EditBayPopup
        open={!!location}
        onClose={closeLocationPopup}
        locationData={location}
        refreshData={refreshData}
      />
    </div>
  );
}
