import { X } from '@phosphor-icons/react';
import { Modal } from 'semantic-ui-react';
import { HoverBorderButton, StyledButton } from '../elements';

export function SkupremeModal({ open, title, onClose, children, size }) {
  return (
    <Modal size={size} onClose={onClose} open={open}>
      <Modal.Content>
        <div className="d-flex justify-content-between">
          <h3>{title}</h3>
          <X size={20} className="cursor-pointer" onClick={onClose} />
        </div>
        <div className="mt-2">{children}</div>
      </Modal.Content>
    </Modal>
  );
}

export function ConfirmationPopup({
  open,
  onClose,
  closeText = 'Cancel',
  confirmText = 'Confrim',
  title = 'Are you sure?',
  message = 'Message',
  onConfirm,
  loading = false,
}) {
  return (
    <SkupremeModal size="mini" open={open} onClose={onClose} title={title}>
      <div className="mb-4">{message}</div>
      <div className="d-flex justify-content-end">
        <HoverBorderButton disabled={loading} onClick={onClose}>
          {closeText}
        </HoverBorderButton>
        <StyledButton loading={loading} onClick={onConfirm} className="ml-2">
          {confirmText}
        </StyledButton>
      </div>
    </SkupremeModal>
  );
}
