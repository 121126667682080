import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormGroup } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { SkupremeModal } from '../../components';
import {
  ControlledTextFormField,
  HoverBorderButton,
  NumberFormField,
  StyledButton,
} from '../../elements';
import {
  CommonUtility,
  decimalMask,
  ToastMessage,
  ZoneService,
} from '../../utility';

const ZoneSchema = yup.object().shape({
  code: yup.string().required('Code is required'),
  weight: yup
    .number()
    .positive('Weight must be greater than 0')
    .typeError('Weight is required')
    .required('Weight is required'),
  length: yup
    .number()
    .positive('Length must be greater than 0')
    .typeError('Length is required')
    .required('Length is required'),
  width: yup
    .number()
    .positive('Width must be greater than 0')
    .typeError('Width is required')
    .required('Width is required'),
  height: yup
    .number()
    .positive('Height must be greater than 0')
    .typeError('Height is required')
    .required('Height is required'),
});

export function EditBayPopup({ onClose, open, refreshData, locationData }) {
  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(ZoneSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (CommonUtility.isValidObject(locationData)) {
      reset({
        code: locationData?.code,
        weight: locationData?.capacity?.maxWeight,
        ...locationData?.capacity?.dimensions,
      });
    }
  }, [locationData]);

  const onSubmit = async formData => {
    try {
      setLoading(true);
      const payload = {
        locationId: locationData._id,
        code: formData.code,
        capacity: {
          maxWeight: formData.weight,
          dimensions: {
            length: formData.length,
            width: formData.width,
            height: formData.height,
          },
        },
      };

      await ZoneService.updateBay(payload);
      ToastMessage.success('Bay updated successfully.');
      onClose();
      refreshData();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkupremeModal
      size="tiny"
      title={`Edit Location in Level ${locationData?.coordinates?.level} at Bay ${locationData?.coordinates?.bay}`}
      open={open}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup widths="equal">
          <ControlledTextFormField
            control={control}
            name="code"
            label="Code"
            error={errors.code}
            hint={errors.code?.message}
          />
          <NumberFormField
            control={control}
            name="weight"
            label="Weight Capacity"
            error={errors.weight}
            hint={errors.weight?.message}
          />
        </FormGroup>
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            name="length"
            label="Length"
            maskOptions={decimalMask}
            error={errors.length}
            hint={errors.length?.message}
          />
          <NumberFormField
            control={control}
            name="width"
            label="Width"
            maskOptions={decimalMask}
            error={errors.width}
            hint={errors.width?.message}
          />
          <NumberFormField
            control={control}
            name="height"
            label="Height"
            maskOptions={decimalMask}
            error={errors.height}
            hint={errors.height?.message}
          />
        </FormGroup>
        <div className="d-flex justify-content-end">
          <HoverBorderButton type="button" onClick={onClose}>
            Cancel
          </HoverBorderButton>
          <StyledButton type="submit" loading={loading} className="ml-1">
            Update
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
