import { Check, Info } from '@phosphor-icons/react';
import styled from 'styled-components';
import { TableCell, TableRow } from 'semantic-ui-react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  ActionEditDots,
  ActionMenuItem,
  DarkPopup,
  ItemImage,
} from '../../components';
import { CommonUtility } from '../../utility/common';
import { BoldText, ColorText, FlexRow, MuteText } from '../../elements';
import { DateFormat, DateUtility } from '../../utility/date';
import { OrganisationAvatar } from '../../components/ImageAvatar';
import { theme } from '../../utility/theme';

const InfoBoxContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  width: 500px;
  max-height: 400px;
  overflow: auto;
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;

  img {
    width: 33px !important;
    height: 33px !important;
  }

  ${FlexRow} {
    padding: 0 10px;
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
  }
`;

const ActionEditTableRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    img.m-auto {
      opacity: 0.3;
    }
    .action-container {
      opacity: 1 !important;
    }
  }
`;

function ItemBox({ items }) {
  return (
    <InfoBoxContainer>
      <div className="row mx-0 mb-2 align-items-center">
        <div className="col-8 px-0">
          <MuteText>Product Information</MuteText>
        </div>
        <div className="col-2 text-center px-0">
          <MuteText>Quantity</MuteText>
        </div>
        <div className="col-2 text-center px-0">
          <MuteText>Total</MuteText>
        </div>
      </div>
      {(items || []).map(item => (
        <div key={item.item_id} className="row mx-0 mb-2 align-items-center">
          <div className="col-2 px-0">
            <ItemImage size="small" item={item} extraClasses="m-0" />
          </div>
          <div className="col-6">
            <div>
              <span>{item.title}</span>
            </div>
            <div>
              <BoldText>{item.sku}</BoldText>
            </div>
          </div>
          <div className="col-2 text-center px-0">
            {item.available ? (
              <span>x{item.qty}</span>
            ) : (
              <span>x{CommonUtility.numberWithCommas(item.qty)}</span>
            )}
          </div>
          <div className="col-2 text-center px-0">
            <span>{CommonUtility.currencyFormat(item.itemPrice)}</span>
          </div>
        </div>
      ))}
    </InfoBoxContainer>
  );
}

const Trigger = styled.div`
  max-width: fit-content;
`;

export function ItemInfoPopup({ total_items, items, position }) {
  return (
    <>
      <DarkPopup
        trigger={
          <Trigger
            className="d-flex align-items-center justify-content-center cursor-pointer"
            onClick={e => e.stopPropagation()}
          >
            {CommonUtility.numberWithCommas(items.length)} item(s)
            <Info className="ml-1" />
          </Trigger>
        }
        hoverable
        position={position}
        style={{ padding: 0 }}
      >
        <ItemBox items={items} />
      </DarkPopup>
      <Trigger>
        <MuteText>
          ({CommonUtility.numberWithCommas(total_items)} unit(s))
        </MuteText>
      </Trigger>
    </>
  );
}

function OrderTime({ time, isEnabled }) {
  let timeColor = '';
  const noOfDays = DateUtility.noOfDays(time);
  if (isEnabled) {
    if (noOfDays < 1) {
      timeColor = theme.colors.green;
    } else if (noOfDays <= 2) {
      timeColor = theme.colors.yellow;
    } else if (noOfDays <= 5) {
      timeColor = theme.colors.orange;
    } else if (noOfDays > 5) {
      timeColor = theme.colors.danger;
    }
  }
  return (
    <ColorText color={timeColor}>
      <b>{DateUtility.hourMinuteTime(time)}</b>
    </ColorText>
  );
}

export function OrderRow({ item, openPopup, showAction }) {
  const totalItems = useMemo(() => {
    let qty = 0;
    item.items.forEach(item => {
      qty += item.qty;
    });
    return qty;
  }, [item]);

  const provider = useMemo(
    () => item?.items?.[0]?.providerDetails?.[0],
    [item],
  );

  return (
    <ActionEditTableRow>
      <TableCell>
        {provider ? (
          <Link
            to={`/app/orders/details/${item._id}/?providerId=${provider?._id}`}
          >
            {item.marketplaceOrderDisplayId}
          </Link>
        ) : (
          item.marketplaceOrderDisplayId
        )}
      </TableCell>
      <TableCell textAlign="center">
        {item.timeAcknowledged && (
          <>
            <OrderTime time={item.timeAcknowledged} isEnabled />
            <div>
              <MuteText>
                {DateUtility.formatDate(item.timeAcknowledged, DateFormat.date)}
              </MuteText>
            </div>
          </>
        )}
      </TableCell>
      <TableCell textAlign="center">
        <div className="d-flex justify-content-center flex-column align-items-center">
          <DarkPopup
            trigger={
              <OrganisationAvatar
                image={item.logo}
                name={item.organizationName}
              />
            }
            position="top center"
          >
            {item.organizationName}
          </DarkPopup>
        </div>
      </TableCell>
      <TableCell textAlign="center">
        {provider?.warehouseName && (
          <div className="d-flex justify-content-center flex-column align-items-center">
            <DarkPopup
              trigger={<OrganisationAvatar name={provider?.warehouseName} />}
              position="top center"
            >
              {provider?.warehouseName}
            </DarkPopup>
          </div>
        )}
      </TableCell>
      <TableCell textAlign="center">
        <div className="d-flex justify-content-center align-items-center flex-column">
          <ItemInfoPopup
            items={item.items}
            position="top left"
            total_items={CommonUtility.numberWithCommas(totalItems)}
          />
        </div>
        {showAction && (
          <ActionEditDots preventChangingBg on="hover" position="left center">
            <ActionMenuItem
              onClick={() => {
                openPopup(item);
              }}
            >
              <Check />
              Fulfill
            </ActionMenuItem>
          </ActionEditDots>
        )}
      </TableCell>
    </ActionEditTableRow>
  );
}
