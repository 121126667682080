import { Form, Input, TextArea } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { ItemImage, SkupremeModal } from '../../components';
import { NumberMaskInput } from '../../elements/CustomMaskInput';
import { HoverBorderButton, MuteText, StyledButton } from '../../elements';
import { InventoryService, ToastMessage } from '../../utility';

export function InventoryAdjustmentPopup({ onClose, open, item, refresh }) {
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(0);
  const [reason, setReason] = useState('');

  const onConfirm = async () => {
    try {
      if (!reason) {
        ToastMessage.error('Reason is require field');
        return;
      }
      setLoading(true);
      const payload = {
        quantity: {
          available,
          uom: 'ea',
        },
        note: reason,
      };
      await InventoryService.updateInventory(
        item.locationSummary?.[0]?.inventoryId,
        payload,
      );
      refresh();
      ToastMessage.success('Inventory adjusted successfully');
      onClose();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setAvailable(item?.quantity?.available || 0);
    setReason('');
  }, [item]);

  return (
    <SkupremeModal
      size="tiny"
      title="Inventory Adjustment"
      onClose={onClose}
      open={open}
    >
      <div className="d-flex align-items-center mb-3">
        <ItemImage
          imageKey="p_images"
          displayImageKey="display_image"
          item={item?.product}
          size="mini"
        />
        <b className="ml-2">{item?.product?.p_sku}</b>
      </div>
      <div className="d-flex flex-column">
        <MuteText>Quantity</MuteText>
        <Input>
          <NumberMaskInput
            value={available}
            onChange={setAvailable}
            placeholder="Quantity"
          />
        </Input>
      </div>
      <div className="d-flex flex-column mt-1">
        <MuteText>Reason</MuteText>
        <Form>
          <TextArea
            onChange={e => setReason(e.target.value)}
            value={reason}
            placeholder="Reason of adjustment"
          />
        </Form>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <HoverBorderButton disabled={loading} onClick={onClose}>
          Cancel
        </HoverBorderButton>
        <StyledButton loading={loading} onClick={onConfirm} className="ml-2">
          Update
        </StyledButton>
      </div>
    </SkupremeModal>
  );
}
