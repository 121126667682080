import { TableCell } from 'semantic-ui-react';
import styled from 'styled-components';
import { cloneElement, isValidElement, useMemo } from 'react';
import {
  AmazonLogo,
  ArrowDown,
  Factory,
  MapPin,
  Package,
} from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import {
  BorderTableCell,
  BorderTableRow,
  ProductRow,
  TextCopyToClipboard,
} from '../../components';
import { BoldText, MuteText } from '../../elements';
import {
  CommonUtility,
  DateFormat,
  DateUtility,
  ShippingTypesIcons,
  StorageIntegrationTypes,
} from '../../utility';
import { calculateListTotal, OriginRow } from './common';
import { ConversionUtility } from '../../utility/conversions';
import { Flag } from '../../elements/Flag';

const ItemsImageContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
  .mx-2 {
    width: 33%;
    max-width: 80px;
  }
  img {
    width: 100% !important;
  }
`;

const Row = styled.div`
  margin-bottom: 3px;
  text-align: start;
`;

const ElipsesRow = styled.div`
  display: flex;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 30px);
  }
  .icon {
    min-width: 18px;
  }
`;

const ShippingType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .icon {
    min-width: 22px;
  }
`;

const Storage = styled.div`
  font-size: 0.9rem;
  text-align: left;
  max-width: 10vw;
`;

const LineRenderer = styled.div`
  margin-left: 2px;
  margin-top: 2px;
`;
const Line = styled.div`
  background: ${({ theme }) => theme.colors.black};
  width: 1px;
  height: 8px;
  margin-bottom: -2px;
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.black};
`;

const FBAContainer = styled.div`
  .icon {
    height: 12px;
  }
  padding: 3px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.black};
`;

export function ShipmentRow({ item }) {
  const allItems = item?.items || [];
  const navigate = useNavigate();

  const summary = useMemo(() => {
    const itemTotal = calculateListTotal(item.items);
    const componentTotal = calculateListTotal(item.components || []);
    const total = {
      cartons: itemTotal.cartons + componentTotal.cartons,
      units: itemTotal.units + componentTotal.units,
      retails: itemTotal.retails + componentTotal.retails,
      supplierValue: itemTotal.supplierValue + componentTotal.supplierValue,
      grossWeight: itemTotal.grossWeight + componentTotal.grossWeight,
    };
    return total;
  }, [item]);

  const weightValue = useMemo(() => {
    if (item.origins?.length === 1) {
      if (
        item.destination?.address?.country === 'US' &&
        item.origins?.[0]?.address?.country === 'US'
      ) {
        if (summary.grossWeight) {
          return ConversionUtility.kiloToPound(summary.grossWeight);
        }
      }
    }
    return summary.grossWeight;
  }, [summary, item]);

  const weightUnit = useMemo(() => {
    if (item.origins?.length === 1) {
      if (
        item.destination?.address?.country === 'US' &&
        item.origins?.[0]?.address?.country === 'US'
      ) {
        return 'lb';
      }
    }
    return 'kg';
  }, [item]);

  const onlyComponents = useMemo(
    () => !!item.components?.length && !item.items?.length,
    [item],
  );

  const currency = useMemo(() => item?.origins?.[0]?.currency || 'USD', [item]);

  const onClick = () => {
    navigate(`/app/shipments/${item._id}`);
  };

  return (
    <BorderTableRow onClick={onClick} className="cursor-pointer">
      <BorderTableCell
        data-field-value="shipment-products"
        textAlign="left"
        className="pl-4"
      >
        <div className="border" />
        <ItemsImageContainer>
          <ProductRow
            showDetail={false}
            containerClass="justify-content-start"
            products={allItems}
          />
        </ItemsImageContainer>
        {allItems?.length > 3 && (
          <MuteText className="pl-2">{allItems?.length} total items</MuteText>
        )}
      </BorderTableCell>
      <TableCell data-field-value="shipment-details" verticalAlign="top">
        <Row className="d-flex align-items-center ">
          <MuteText className="mr-2 text-nowrap">ID</MuteText>
          <BoldText>
            <TextCopyToClipboard
              text={item?.display_id || item?.shipment_id || ''}
            />
          </BoldText>
        </Row>
        {item.FBA_id && (
          <Row className="d-flex">
            <MuteText className="mr-2">FBA ID</MuteText>
            <BoldText>
              <TextCopyToClipboard text={item?.FBA_id} />
            </BoldText>
          </Row>
        )}
        <Row>
          <MuteText>Customer</MuteText> &nbsp;
          <BoldText>{item.organization?.company_name}</BoldText>
        </Row>
        <Row>
          <MuteText>Cartons</MuteText> &nbsp;
          <BoldText>
            {CommonUtility.numberWithCommas(summary?.cartons?.toFixed())}
          </BoldText>
        </Row>
        <Row>
          <MuteText>Units</MuteText> &nbsp;
          <BoldText>
            {CommonUtility.numberWithCommas(summary.units.toFixed())}
          </BoldText>
        </Row>
        <Row>
          <MuteText>Gross Weight</MuteText> &nbsp;
          <BoldText>
            {CommonUtility.numberWithCommas(weightValue.toFixed(2))}
            &nbsp;
            {weightUnit}
          </BoldText>
        </Row>
        {!!item.total_pallets && (
          <Row>
            <MuteText>Confirmed Pallets</MuteText> &nbsp;
            <BoldText>
              {CommonUtility.numberWithCommas(item.total_pallets)}
            </BoldText>
          </Row>
        )}
      </TableCell>
      <TableCell data-field-value="shipment-value" verticalAlign="top">
        <Row>
          <MuteText>Retail Value</MuteText> &nbsp;
          <BoldText>
            {onlyComponents
              ? 'N/A'
              : CommonUtility.currencyFormat(summary.retails)}
          </BoldText>
        </Row>
        <Row>
          <MuteText>Supplier Cost Value</MuteText> &nbsp;
          <BoldText>
            {CommonUtility.currencyFormat(summary.supplierValue, currency)}
          </BoldText>
        </Row>
      </TableCell>
      <TableCell data-field-value="shipment-type" verticalAlign="top">
        <ShippingType>
          <div className="d-flex align-items-center">
            {isValidElement(ShippingTypesIcons[item.shipping_type]) &&
              cloneElement(ShippingTypesIcons[item.shipping_type], {
                size: '23',
              })}
            <span className="ml-1">{item.shipping_type}</span>
          </div>
          <Storage className="mt-2">
            <ElipsesRow className="align-items-center mb-1">
              {item.origin_type === 'Purchase Order' ? (
                <Factory className="mr-2 icon" size={18} />
              ) : (
                <Package className="mr-2 icon" size={18} />
              )}
              <Flag name={item.origins?.[0]?.address?.country} />
              <OriginRow origins={item.origins?.map(x => x.name)} />
            </ElipsesRow>
            <LineRenderer>
              <Line />
              <ArrowDown size={13} />
            </LineRenderer>
            <ElipsesRow className="align-items-center">
              <MapPin className="icon" size={18} />
              <Flag
                className="ml-1 icon"
                name={item.destination?.address?.country}
              />
              {item.destination?.destination_type !==
                StorageIntegrationTypes.FBA && (
                <span>{item.destination?.name}</span>
              )}
              {item.destination?.destination_type ===
                StorageIntegrationTypes.FBA && (
                <FBAContainer>
                  <AmazonLogo className="icon" />
                </FBAContainer>
              )}
            </ElipsesRow>
          </Storage>
        </ShippingType>
      </TableCell>
      <TableCell data-field-value="shipment-status" verticalAlign="top">
        <Row>
          <MuteText>Status </MuteText>
          <BoldText>
            {CommonUtility.removeUnderscoreAndCapitalize(item.status)}
          </BoldText>
        </Row>
        <Row>
          <MuteText>ETD </MuteText> &nbsp;
          <BoldText>
            {DateUtility.formatDate(item.ETD, DateFormat.date)}
          </BoldText>
        </Row>
        <Row>
          <MuteText>ETA </MuteText> &nbsp;
          <BoldText>
            {DateUtility.formatDate(item.ETA, DateFormat.date)}
          </BoldText>
        </Row>
      </TableCell>
    </BorderTableRow>
  );
}
