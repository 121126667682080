export const tooltipTexts = {
  orderId: 'This is the marketplace order id as synced from the source',
  orderAge:
    'This is the time elapsed from when the order was first  created at the source marketplace',
  marketplace:
    'The marketplace source where the order originated. If the order was manually created, we will display the Skupreme icon.',
  organization:
    'The organization source where the order originated. If the order was manually created, we will display the Skupreme icon.',
  totalItems: 'The total number of units in the order',
  totalPrice:
    'The subtotal price of items in the order. This does not include shipping, taxes, fees, etc.',
  unitConversionInfo:
    'You can view information in your preferred measurement system. SKUPREME automatically converts measurements sent depending on destination location.',
};

export const calculationToolTips = {
  cbm: 'It is the total volume of all cartons summed up in Cubic Meters',
  cbf: 'It is the total volume of all cartons summed up in Cubic Feet',
};
