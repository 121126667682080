import { useMemo } from 'react';
import { CommonUtility, units, unitsTextMap } from '../utility';
import { conversionMap, ConversionUtility } from '../utility/conversions';
import { MuteText } from '../elements';

export function ConversionViewer({
  brackets = false,
  fullText = false,
  hideOnNoConversion = false,
  imperial,
  originalUnit,
  showUnit = true,
  value,
}) {
  const conversionFunctionsMap = {
    [units.liters]: ConversionUtility.litersToGallons,
    [units.milliLiters]: ConversionUtility.millitersToFluidOunces,
    [units.kiloGrams]: ConversionUtility.kiloToPound,
    [units.grams]: ConversionUtility.gramsToOunces,
    [units.centimeters]: ConversionUtility.centiToInch,
    [units.gallons]: ConversionUtility.gallonsToLiters,
    [units.fluidOunces]: ConversionUtility.fluidOuncesToMilliters,
    [units.pounds]: ConversionUtility.poundToKilo,
    [units.ounces]: ConversionUtility.ouncesToGrams,
    [units.inches]: ConversionUtility.inchToCenti,
  };

  const needsConversion = useMemo(() => {
    if (originalUnit === units.units || !originalUnit) return false;

    if (imperial) {
      if (
        ![
          units.fluidOunces,
          units.ounces,
          units.pounds,
          units.gallons,
          units.inches,
        ].includes(originalUnit)
      ) {
        return true;
      }
    } else if (
      ![
        units.milliLiters,
        units.grams,
        units.kiloGrams,
        units.liters,
        units.centimeters,
      ].includes(originalUnit)
    ) {
      return true;
    }
    return false;
  }, [imperial, originalUnit]);

  const convertedValue = useMemo(() => {
    let toReturn = value;
    if (needsConversion) {
      const calculated = conversionFunctionsMap[originalUnit]?.(value);
      if (calculated !== undefined) {
        toReturn = calculated;
      }
    }
    toReturn = CommonUtility.numberWithCommas(
      CommonUtility.roundNumber(toReturn),
    );
    return toReturn;
  }, [needsConversion, value]);

  const convertedUnit = useMemo(() => {
    let calUnit = originalUnit;
    if (needsConversion) {
      calUnit = conversionMap[originalUnit];
    }

    if (fullText) {
      calUnit = unitsTextMap[calUnit] || calUnit;
    }

    return calUnit;
  }, [needsConversion, originalUnit]);

  if (!value) return '';

  if (!originalUnit) return value;

  if (!needsConversion) {
    if (hideOnNoConversion) {
      return '';
    }

    return (
      <>
        {!!brackets && '('}
        {convertedValue}{' '}
        {showUnit && <MuteText>{convertedUnit || 'pieces'}</MuteText>}
        {!!brackets && ')'}
      </>
    );
  }

  return (
    <>
      {!!brackets && '('}
      {convertedValue}{' '}
      {showUnit && <MuteText>{convertedUnit || 'pieces'}</MuteText>}
      {!!brackets && ')'}
    </>
  );
}

export function ConversionUnitViewer({
  imperial,
  originalUnit,
  fullText = false,
}) {
  const needsConversion = useMemo(() => {
    if (originalUnit === units.units) return false;

    if (imperial) {
      if (
        ![
          units.fluidOunces,
          units.ounces,
          units.pounds,
          units.gallons,
          units.inches,
        ].includes(originalUnit)
      ) {
        return true;
      }
    } else if (
      ![
        units.milliLiters,
        units.grams,
        units.kiloGrams,
        units.liters,
        units.centimeters,
      ].includes(originalUnit)
    ) {
      return true;
    }
    return false;
  }, [imperial, originalUnit]);

  const convertedUnit = useMemo(() => {
    let calUnit = originalUnit;
    if (needsConversion) {
      calUnit = conversionMap[originalUnit];
    }

    if (fullText) {
      calUnit = unitsTextMap[calUnit] || calUnit;
    }

    return calUnit;
  }, [needsConversion, originalUnit]);

  if (!originalUnit) return '';
  if (!needsConversion) {
    return convertedUnit;
  }

  return convertedUnit;
}
