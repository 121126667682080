import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useMemo, useState } from 'react';
import { Warehouse } from '@phosphor-icons/react';
import {
  BodyContainer,
  HeaderBar,
  MuteText,
  PageTitle,
} from '../../../elements';
import { OrderUrlKeys, tooltipTexts } from '../../../utility/constants';
import {
  AppTable,
  FilterButton,
  FilterPill,
  SearchInput,
  TableHeaderToolTip,
} from '../../../components';
import {
  FilterHook,
  OrderHook,
  useDebouncedMemo,
  WarehouseListHook,
} from '../../../hooks';
import { FulfillOrderPopup, OrderRow } from '../../../page-components';
import { CommonUtility, OrderService, ToastMessage } from '../../../utility';

const StatusContainer = styled(PageTitle)`
  text-transform: capitalize;
`;

const FilterPillContainer = styled.div`
  position: absolute;
  width: calc(70% - 60px);
  margin-top: 5px;
`;

function Header({ type, loading, filters, updateAllFilter, finalFilter }) {
  const {
    finalValue,
    setFinalValue,
    activeIndex,
    setActiveIndex,
    open,
    setOpen,
    pillFilters,
    removeFilter,
    openRelevantFilter,
  } = FilterHook(filters, finalFilter);

  return (
    <>
      <HeaderBar className="row">
        <div className="col-6 pt-2">
          <StatusContainer>{OrderUrlKeys[type] || type}</StatusContainer>
        </div>
        <div className="col-6 row justify-content-end">
          <FilterButton
            setFinalFilter={updateAllFilter}
            loading={loading}
            filters={filters}
            finalFilter={finalFilter}
            setFinalvalue={setFinalValue}
            finalValue={finalValue}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            open={open}
            setOpen={setOpen}
          />
        </div>
      </HeaderBar>
      <FilterPillContainer>
        {pillFilters?.length > 0 && (
          <div className="d-flex align-items-center">
            <MuteText>Filters</MuteText>
            {filters.map(filter => (
              <FilterPill
                data={filter}
                userValue={finalValue}
                removeFilter={removeFilter}
                openRelevantFilter={openRelevantFilter}
              />
            ))}
          </div>
        )}
      </FilterPillContainer>
    </>
  );
}

export function OrderList() {
  const { type } = useParams();
  const [searchParams] = useSearchParams();

  const {
    data: orderData,
    filter: tableFilter,
    filterChanged,
    loading: orderLoading,
    onSortChange,
    pageChanged,
    totalData,
    totalPages,
    parsedSort,
    refresh,
    updateAllFilter,
  } = OrderHook({ type });

  const { data: warehouseList } = WarehouseListHook();

  const [loading, setLoading] = useState(false);

  const warehouseOptions = useMemo(
    () =>
      warehouseList?.map(warehouse => ({
        value: warehouse._id,
        text: warehouse.name,
      })),
    [warehouseList],
  );

  const [orderToFulfill, setOrderToFulfill] = useState(null);

  const orderColumns = [
    {
      text: 'Order Id',
      key: 'order_id',
      textAlign: 'left',
      width: 3,
      component: (
        <TableHeaderToolTip
          label="Order Id"
          helpText={tooltipTexts.orderId}
          noInfoRightPadding
          wrapperClass="d-flex align-items-center"
        />
      ),
    },
    {
      text: 'Order Age',
      key: 'order_date',
      textAlign: 'center',
      sortKey: 'timeAcknowledged',
      width: 3,

      component: (
        <TableHeaderToolTip
          label="Order Age"
          helpText={tooltipTexts.orderAge}
          noInfoRightPadding
          wrapperClass="d-flex align-items-center float-left"
        />
      ),
    },
    {
      text: 'Organization',
      key: 'organization',
      width: 3,

      component: (
        <TableHeaderToolTip
          label="Organization"
          helpText={tooltipTexts.organization}
          noInfoRightPadding
        />
      ),
    },
    {
      text: 'Warehouse',
      key: 'warehouse',
      width: 3,
    },
    {
      text: 'Total Items',
      key: 'total_items',
      width: 3,

      component: (
        <TableHeaderToolTip
          label="Total Items"
          helpText={tooltipTexts.totalItems}
          noInfoRightPadding
        />
      ),
    },
  ];

  const fulfillOrder = async () => {
    try {
      setLoading(true);
      const payload = {
        action: 'fulfill',
      };

      await OrderService.orderAction(orderToFulfill, payload);
      closePopup();
      ToastMessage.success('Order fulfilled successfully.');
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const openPopup = order => {
    setOrderToFulfill(order._id);
  };

  const closePopup = () => {
    setOrderToFulfill(null);
  };

  const filters = useDebouncedMemo(
    () => {
      const query = Object.fromEntries(searchParams.entries());
      const temp = { ...query };
      Object.keys(temp).forEach(key => {
        const final = CommonUtility.tryParseJSONObject(temp[key]);
        if (final) {
          temp[key] = final;
        }
      });

      const defaultValues = {
        ...temp,
      };

      const arr = [
        {
          name: 'warehouseId',
          label: 'Warehouse',
          icon: <Warehouse />,
          options: warehouseOptions,
          multiple: true,
          search: true,
          defaultValue: (defaultValues && defaultValues.warehouseId) || null,
        },
        {
          skip: true,
          name: 'page',
          must: true,
          defaultValue: (defaultValues && defaultValues.page) || 1,
          ignoreDefault: 1,
        },
        {
          skip: true,
          name: 'limit',
          must: true,
          defaultValue:
            defaultValues && CommonUtility.isValidNumber(defaultValues.limit)
              ? Number(defaultValues.limit)
              : 50,
          ignoreDefault: 50,
        },
      ];

      return arr;
    },
    [warehouseOptions, type],
    500,
  );

  return (
    <BodyContainer>
      <Header
        filters={filters}
        finalFilter={tableFilter}
        loading={loading}
        updateAllFilter={updateAllFilter}
        type={type}
      />
      <div className="d-flex justify-content-end">
        <SearchInput
          search={tableFilter.search}
          setSearch={search => filterChanged('search', search)}
          placeholder="Search by Order Id"
        />
      </div>
      <AppTable
        columns={orderColumns}
        loading={orderLoading}
        RowComponent={
          <OrderRow
            showAction={type === 'pending-fulfillment'}
            openPopup={openPopup}
          />
        }
        data={orderData}
        totalData={totalData}
        totalPages={totalPages}
        pageChanged={pageChanged}
        sort={parsedSort}
        currentPage={tableFilter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={tableFilter.limit}
        sortChanged={sort => onSortChange(sort)}
      />
      <FulfillOrderPopup
        open={!!orderToFulfill}
        onClose={closePopup}
        onConfirm={fulfillOrder}
        loading={loading}
      />
    </BodyContainer>
  );
}
