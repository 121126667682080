import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { AuthGuard } from './guards';
import {
  AcceptInviteScreen,
  Error404,
  HomeScreen,
  InventoryDetailScreen,
  LoginScreen,
  OrderDetailScreen,
  OrderList,
  ShipmentDetailScreen,
  ShipmentListScreen,
  WarehouseDetailScreen,
  WarehouseScreen,
} from './screens';
import { AppLayout, SecureMenu } from './layouts';
import { SidebarProvider } from './contexts/sidebar';
import { RegisterScreen } from './screens/register';
import { OnboardingScreen } from './screens/app/onboarding';
import { SettingsDetailScreen } from './screens/app/settings';
import { InventoryListScreen } from './screens/app/inventory/list';

function App() {
  const { pathname } = useLocation();
  const blockedUrls = ['/app/onboarding', '/app/onboarding/'];

  const hideSidebar = useMemo(() => blockedUrls.includes(pathname), [pathname]);

  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route path="/accept-invite" element={<AcceptInviteScreen />} />
      <Route path="/" exact element={<Navigate to="/login" replace />} />
      <Route
        path="app"
        element={
          <AuthGuard>
            <SidebarProvider>
              <SecureMenu hideSidebar={hideSidebar} />
              <AppLayout hideSidebar={hideSidebar} />
            </SidebarProvider>
          </AuthGuard>
        }
      >
        <Route path="onboarding" element={<OnboardingScreen />} />
        <Route path="warehouses" element={<WarehouseScreen />} />
        <Route path="settings/*" element={<SettingsDetailScreen />} />
        <Route path="inventory">
          <Route path="import/*" element={<InventoryDetailScreen />} />
          <Route path="list" element={<InventoryListScreen />} />
        </Route>
        <Route path="shipments">
          <Route index element={<ShipmentListScreen />} />
          <Route path=":id" element={<ShipmentDetailScreen />} />
        </Route>
        <Route path="warehouses/:id/*" element={<WarehouseDetailScreen />} />
        <Route path="orders">
          <Route path=":type" element={<OrderList />} />
          <Route path="details/:id" element={<OrderDetailScreen />} />
        </Route>
        <Route path="dashboard" element={<HomeScreen />} />
        <Route index element={<Navigate to="dashboard" replace />} />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

export default App;
