import { useMemo } from 'react';
import styled from 'styled-components';
import { ConversionViewer, TextCopyToClipboard } from '../../../components';
import { BoldText, HeaderBar, MuteText, PageTitle } from '../../../elements';
import { CommonUtility } from '../../../utility';
import { ConversionUtility } from '../../../utility/conversions';
import { CBMRenderer } from '../common';

const TextHeader = styled(PageTitle)`
  max-width: 100%;
  display: flex;
  overflow: hidden;
  .origin-name {
    display: inline-block;
    max-width: 35vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export function ShipmentDetailHeader({ data, imperial, summary, cbm }) {
  const cbf = useMemo(() => ConversionUtility.cbmTocbf(cbm), [cbm]);

  const destinationName = useMemo(() => {
    if (!data) return '';
    return data.destination?.name;
  }, [data]);

  const originName = useMemo(() => {
    if (!data) return '';
    return data.origins?.map(x => x.name)?.join(', ');
  }, [data]);

  return (
    <HeaderBar className="row">
      <div className="col-10 d-flex flex-column">
        <div>
          <TextHeader>
            <span className="origin-name">{originName}</span>{' '}
            <span className="ml-2">to {destinationName}</span>
          </TextHeader>
          <div className="d-flex">
            <div className="mr-3">
              <BoldText>
                {CommonUtility.removeUnderscoreAndCapitalize(data?.status)}
              </BoldText>
            </div>
            <MuteText className="mr-1 d-flex">
              <span className="mr-1">ID</span>
              <b>
                <TextCopyToClipboard text={data?.display_id} />
              </b>
            </MuteText>
            <MuteText className="mr-4">
              <CBMRenderer imperial={imperial} cbf={cbf} cbm={cbm} />
            </MuteText>
            <MuteText>
              Total Weight{' '}
              <b>
                <ConversionViewer
                  value={CommonUtility.roundNumber(summary?.grossWeight, 2)}
                  imperial={imperial}
                  originalUnit="kg"
                />
              </b>
            </MuteText>
            <MuteText className="mx-4">
              Cartons{' '}
              <b>
                {CommonUtility.numberWithCommas(summary?.cartons?.toFixed())}
              </b>
            </MuteText>
            <MuteText>
              Unit Quantity{' '}
              <b>{CommonUtility.numberWithCommas(summary.units)}</b>
            </MuteText>
          </div>
        </div>
      </div>
    </HeaderBar>
  );
}
