import { useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import {
  ControlledTextAreaFormField,
  ControlledTextFormField,
  DropdownFormField,
  ShadowBox,
} from '../../../elements';
import {
  CommonUtility,
  OrganizationsService,
  publicStatusOptions,
  ToastMessage,
} from '../../../utility';
import { useAuth } from '../../../contexts/auth';
import { AlertFooterBar } from '../../../elements/FooterAlert';
import { OrganizationDetailHook } from '../../../hooks';
import { LoaderBar } from '../../../components/LoaderBar';

const OrganisationSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email('Enter Valid Email'),
  publicStatus: yup.string(),
});

export function OrganisationDetailTab() {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { data: organizationData, loading: orgLoading } =
    OrganizationDetailHook(user.organizationId);

  const {
    formState: { errors, dirtyFields },
    control,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(OrganisationSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (CommonUtility.isValidObject(organizationData)) {
      resetData(organizationData);
    }
  }, [organizationData]);

  const resetData = organizationData => {
    reset({ ...organizationData });
  };

  const onSubmit = async formValues => {
    try {
      setLoading(true);
      let payload = {
        name: formValues.name,
        email: formValues.email,
        publicStatus: formValues.publicStatus,
      };
      payload = CommonUtility.removeEmptyFields(payload);
      const res = await OrganizationsService.update(
        user.organizationId,
        payload,
      );
      resetData(res);
      ToastMessage.success('Integration settings updated.');
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="py-3 position-relative">
      {orgLoading && <LoaderBar />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ShadowBox className="mt-4">
          <h3>General Settings</h3>
          <Form.Group className="mt-2 mb-1">
            <ControlledTextFormField
              error={errors.name}
              hint={errors.name?.message}
              label="Name"
              name="name"
              control={control}
              width={4}
              required
            />
            <ControlledTextAreaFormField
              error={errors.email}
              hint={errors.email?.message}
              label="Email"
              name="email"
              control={control}
              width={4}
              rows={1}
            />
          </Form.Group>
          <Form.Group>
            <DropdownFormField
              error={errors.publicStatus}
              hint={errors.publicStatus?.message}
              label="Public Status"
              name="publicStatus"
              control={control}
              options={publicStatusOptions}
              width={4}
            />
          </Form.Group>
        </ShadowBox>
        {CommonUtility.isValidObject(dirtyFields) && (
          <AlertFooterBar
            loading={loading}
            message="Save Changes"
            submitButton
            cancel={() => resetData(organizationData)}
          />
        )}
      </Form>
    </div>
  );
}
