import styled from 'styled-components';
import { Form, Header as Label, Image } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SpinnerGap } from '@phosphor-icons/react';
import { Link, useNavigate } from 'react-router-dom';
import {
  ControlledTextFormField,
  PasswordFormField,
  PrimaryLink,
  ShadowBox,
  StyledButton,
  StyledLink,
} from '../elements';
import { UserService } from '../utility/services';
import { ToastMessage } from '../utility/toast';
import { CommonUtility } from '../utility/common';
import { ErrorMessageConstant } from '../utility/constants';
import { LoaderBar } from '../components';
import { Images } from '../images';
import { useAuth } from '../contexts/auth';

const LogoContainer = styled(StyledLink)`
  margin-top: 10px;
  margin-bottom: 100px;
`;

const Logo = styled(Image)`
  height: auto;
  width: 450px;
`;

const PageContainer = styled.div`
  padding-top: 12vh;
  padding-bottom: 12vh;
  min-height: 100vh;
  justify-content: start;
  align-items: center;
  background: ${({ theme }) => theme.colors.box};
  display: flex;
  flex-direction: column;

  .form-visible {
    max-height: 500px;
    transition: max-height 0.45s ease-in;
  }

  .form-hidden {
    max-height: 0;
    transition: max-height 0.45s ease-out;
    overflow: hidden;
  }
`;

const Box = styled(ShadowBox)`
  padding: 2em;
  width: 80%;
  min-width: 310px;
  max-width: 432px;
  position: relative;
  z-index: 2;
`;

const FooterAction = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  a.login {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const RegisterSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Enter a valid email'),
  token: yup.string().trim().required('Code is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(12, 'Password must have at least 12 characters')
    .matches(
      /[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~".]/,
      'Password should contain at least one special character',
    )
    .matches(
      /^(?=.*[A-Z])/,
      'Password should contain at least one Upper case letter',
    )
    .matches(
      /^(?=.*[a-z])/,
      'Password should contain at least one lower case letter',
    )
    .matches(/[\d]/, 'Password should contain at least one number'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  firstName: yup.string().trim().max(40).required('Name is required'),
});

const CodeAction = styled.div`
  position: absolute;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  > a {
    text-decoration: none;
    font-size: 14px;
  }
`;

function SendCodeButton(props) {
  const { email, token, trigger, errors, error: registerError } = props;

  const intervalRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [hidden, setHidden] = useState(false);

  const isDisabled = seconds > 0 || isLoading || submitCount > 3;

  const handleSubmit = async () => {
    if (!email) {
      trigger('email');
      return null;
    }

    if (isDisabled || errors?.email?.message) return null;

    try {
      setLoading(true);
      await UserService.authVerifyEmail({ email });
      setSubmitCount(prev => prev + 1);
      setSeconds(30);
      ToastMessage.success('Verification email sent successfully');
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(prev => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    intervalRef.current = timer;
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [submitCount]);

  useEffect(() => {
    if (seconds < 1) {
      clearInterval(intervalRef.current);
    }
  }, [seconds]);

  useEffect(() => {
    setHidden(!!token && submitCount > 0);
  }, [token]);

  useEffect(() => {
    if (registerError) {
      setHidden(false);
      setSubmitCount(1);
    }
  }, [registerError]);

  return (
    <CodeAction className={hidden ? 'd-none' : ''}>
      {isLoading && <SpinnerGap size={16} className="spin mr-2" />}
      <PrimaryLink onClick={handleSubmit} disabled={isDisabled}>
        {submitCount > 0 ? 'Resend' : 'Send'}
        {isLoading ? 'ing' : ''} Code{' '}
        {seconds > 0 ? `in 00:${seconds > 9 ? '' : '0'}${seconds}` : ''}
      </PrimaryLink>
    </CodeAction>
  );
}

export function RegisterScreen() {
  const [isEmailTouched, setEmailTouched] = useState(false);
  const navigate = useNavigate();
  const { setUserData } = useAuth();

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { dirtyFields, submitCount, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(RegisterSchema),
    shouldUnregister: true,
  });

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const password = watch('password');
  const email = watch('email');
  const token = watch('token');

  useEffect(() => {
    trigger('confirmPassword');
  }, [password, trigger]);

  const onSubmit = async data => {
    try {
      setSaving(true);
      setError('');
      const response = await UserService.register({
        ...data,
        firstName: CommonUtility.toTitleCase(data.firstName.trim()),
        confirmPassword: undefined,
      });

      UserService.storeUser(response);

      setTimeout(() => {
        setUserData(response);

        navigate('/app/dashboard');
      }, 200);
    } catch (error) {
      setSaving(false);
      setError(error.error || ErrorMessageConstant.defaultAPIError);
    }
  };

  const confirmPasswordError =
    dirtyFields?.confirmPassword || (submitCount && submitCount > 0)
      ? errors?.confirmPassword?.message
      : null;

  const isFieldsVisible = (submitCount && submitCount > 0) || isEmailTouched;

  useEffect(() => {
    if (UserService.isAuthenticated()) {
      navigate('/app/dashboard');
    }
  }, []);

  const emailWrapperClassName = isFieldsVisible
    ? 'form-visible position-relative'
    : 'form-hidden';

  const SendBtn = (
    <SendCodeButton
      token={token}
      email={email}
      error={error}
      errors={errors}
      trigger={trigger}
    />
  );

  return (
    <PageContainer>
      <LogoContainer>
        <Logo src={Images.blackLogo} />
      </LogoContainer>
      <Box>
        <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          {saving && <LoaderBar />}
          <ControlledTextFormField
            error={errors.email}
            label="Email Address"
            name="email"
            control={control}
            hint={errors?.email?.message}
            required
            onFocus={() => setEmailTouched(true)}
          />
          <div className={emailWrapperClassName}>
            {isFieldsVisible && (
              <ControlledTextFormField
                control={control}
                error={errors.token}
                hint={errors?.token?.message}
                inputRight={SendBtn}
                label="Email Verification Code"
                name="token"
                required
              />
            )}
            {isFieldsVisible && (
              <ControlledTextFormField
                error={errors.firstName}
                label="Name"
                name="firstName"
                control={control}
                className="text-capitalize"
                hint={errors?.firstName?.message}
                required
              />
            )}
          </div>
          <PasswordFormField
            error={errors.password}
            control={control}
            label="Password"
            name="password"
            required
            hint={errors?.password?.message}
          />
          <PasswordFormField
            error={confirmPasswordError && errors.confirmPassword}
            control={control}
            label="Confirm Password"
            name="confirmPassword"
            required
            hint={confirmPasswordError}
          />
          {error && (
            <Label className="w-100" as="h4" color="red">
              {error}
            </Label>
          )}
          <StyledButton loading={saving} fluid type="submit">
            Create Account
          </StyledButton>
          <FooterAction>
            Already on SKUPREME?
            <Link to="/login" className="pl-1 login">
              Log In
            </Link>
          </FooterAction>
        </Form>
      </Box>
    </PageContainer>
  );
}
