import { Loader, TabPane } from 'semantic-ui-react';
import styled from 'styled-components';
import { cloneElement, isValidElement, useMemo } from 'react';
import {
  DateFormat,
  DateUtility,
  ShipmentTypeKeys,
  ShippingTypes,
} from '../../utility';
import { BoldText, MuteText } from '../../elements';
import { Flag } from '../../elements/Flag';

const LoaderBarContainer = styled.div`
  margin-top: 150px;
`;

const InfoContainer = styled.div`
  flex-wrap: nowrap;
  margin-bottom: 20px;
  row-gap: 20px;
  display: flex;
  column-gap: 30px;
  flex-wrap: wrap;
  > div:nth-child(1) > span {
    white-space: nowrap;
  }
  .date-column input {
    width: 100% !important;
  }
  .field {
    margin: 0px !important;
    margin-bottom: 0px !important;
  }
  @media only screen and (max-width: 1514px) {
    flex-wrap: wrap;
  }
`;

export function ShipmentInfo({ data, isFBA, loading }) {
  const destination = useMemo(
    () => ({
      name: data?.destination?.name,
      region:
        data?.destination?.address?.country || data?.destination_region?.name,
    }),
    [data],
  );

  const provider = useMemo(() => {
    if (data?.origin_type === ShipmentTypeKeys.PO) {
      return {
        name: data?.origins?.map(x => x.name).join(','),
        region: data?.origins?.length > 0 && data?.origins[0]?.address?.country,
      };
    }
    return {
      name: data?.origins?.length > 0 && data?.origins[0]?.name,
      region: data?.origins?.length > 0 && data?.origins[0]?.address?.country,
    };
  }, [data]);

  const shippingType = useMemo(() => {
    const opt = ShippingTypes.find(x => x.value === data?.shipping_type);
    return {
      name: opt?.text,
      icon: opt?.icon,
    };
  }, [data]);

  if (loading) {
    return (
      <LoaderBarContainer>
        <Loader active inline />
      </LoaderBarContainer>
    );
  }

  return (
    <TabPane attached="bottom">
      <div className="d-flex flex-wrap">
        <div className="mr-4 pr-3">
          <MuteText>
            {data?.origin_type === ShipmentTypeKeys.PO
              ? 'Purchase Order(s)'
              : 'Origin'}
          </MuteText>
          <div className="d-flex align-items-center mt-2">
            <Flag name={provider?.region || 'US'} />
            <BoldText>{provider?.name}</BoldText>
          </div>
        </div>
        <div className="mr-4 pr-3">
          <MuteText>Destination</MuteText>
          <div className="d-flex align-items-center mt-2">
            <Flag name={destination?.region || 'US'} />
            {!isFBA && <BoldText>{destination?.name}</BoldText>}
          </div>
        </div>
        <div>
          <MuteText>Shipment Type</MuteText>
          <div className="d-flex align-items-center mt-2">
            {isValidElement(shippingType?.icon) &&
              cloneElement(shippingType?.icon)}
            <BoldText className="ml-2">{shippingType?.name}</BoldText>
          </div>
        </div>
      </div>
      <InfoContainer className="mt-4">
        <div className="d-flex flex-column date-column">
          <MuteText className="d-flex align-items-center mb-1">
            Estimated Departure
          </MuteText>
          <BoldText>
            {DateUtility.formatDate(data?.ETD, DateFormat.date)}
          </BoldText>
        </div>
        <div className="d-flex flex-column date-column">
          <MuteText className="d-flex align-items-center mb-1">
            Estimated Arrival
          </MuteText>
          <BoldText>
            {DateUtility.formatDate(data?.ETA, DateFormat.date)}
          </BoldText>
        </div>
      </InfoContainer>
    </TabPane>
  );
}
