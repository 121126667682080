import styled from 'styled-components';

const Container = styled.div`
    height : 100%;
    background-color: ${({ theme }) => theme.colors.box};
    overflow: hidden;
`

const Placeholder = styled.div`
    padding-top : ${({ fixed }) => (fixed ? 0 : 75)}px;
`

export function PublicLayout({ fixed,children }) {
  return (
    <Container>
      <Placeholder fixed={fixed}>
        {children}
      </Placeholder>
    </Container>
  )
}
