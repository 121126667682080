// TODO: Group & Sort
export const APIPath = {
  // login and registration
  login: 'auth/login',
  account: 'account',
  invites: 'invites',
  users: 'users',
  customers: 'customers',
  shipments: 'shipments',
  inventory: 'inventory',
  inventoryFeed: 'inventory/feeds',
  inventoryFeedUploadUrl: 'inventory/feeds/uploadUrl',
  register: 'auth/register',
  requestEmailCode: 'auth/register/verify-email',
  verifyLogin: 'auth/login/verify',
  refreshToken: 'auth/token/refresh',
  organizationDashboard: 'dashboards',
  organizations: 'organizations',
  organizationIntegration: 'organizations/integration',
  organizationsLogoUrl: 'organizations/upload',
  orders: 'orders',
  warehouses: 'warehouses',
  zones: 'zones',
  zoneTypes: 'zones/types',
  racks: 'racks',
  rackLocations: 'racks/locations',
  rackTypes: '/racks/rack-types',
};
