import styled from 'styled-components';

export const GreenTag = styled.div`
  color: ${({ theme }) => theme.colors.green};
  font-weight: bold;
  background: rgba(0, 200, 0, 0.1);
  width: fit-content;
  padding: 1px 10px;
  border-radius: 14px;
`;
