import { AppTable } from '../../../components/AppTable';
import { BodyContainer, HeaderBar, PageTitle } from '../../../elements';
import { ShipmentListHook } from '../../../hooks';
import { ShipmentRow } from '../../../page-components';

function Header() {
  return (
    <HeaderBar className="row">
      <div className="col-6 pt-2">
        <PageTitle>Shipments</PageTitle>
      </div>
    </HeaderBar>
  );
}

export function ShipmentListScreen() {
  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
  } = ShipmentListHook();

  const columns = [
    {
      text: 'Products',
      key: 'Products',
      width: 4,
      textAlign: 'left',
    },
    {
      text: 'Details',
      key: 'content',
      width: 3,
      textAlign: 'left',
    },
    {
      text: 'Value',
      key: 'value',
      width: 3,
      textAlign: 'left',
    },
    {
      text: 'Type',
      key: 'type',
      width: 3,
      textAlign: 'left',
    },
    {
      text: 'Status',
      key: 'status',
      width: 3,
      textAlign: 'left',
    },
  ];

  return (
    <BodyContainer>
      <Header />
      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        RowComponent={<ShipmentRow />}
        pageChanged={pageChanged}
      />
    </BodyContainer>
  );
}
