import { SkupremeModal } from '../../components';
import { HoverBorderButton, StyledButton } from '../../elements';

export function FulfillOrderPopup({ open, onClose, onConfirm, loading }) {
  return (
    <SkupremeModal
      size="mini"
      title="Confirm Fulfilling Order"
      onClose={onClose}
      open={open}
    >
      <p>Are you sure you want to fulfill this order?</p>
      <div className="d-flex mt-4 justify-content-end">
        <HoverBorderButton disabled={loading} onClick={onClose}>
          Cancel
        </HoverBorderButton>
        <StyledButton
          loading={loading}
          onClick={() => onConfirm()}
          className="ml-2"
        >
          Confirm
        </StyledButton>
      </div>
    </SkupremeModal>
  );
}
