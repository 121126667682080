import { TableCell, TableRow } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DateFormat, DateUtility } from '../../utility/date';
import { CommonUtility } from '../../utility';

export function WarehouseRow({ item }) {
  return (
    <TableRow>
      <TableCell>
        <Link to={`/app/warehouses/${item._id}`}>{item.name}</Link>
      </TableCell>
      <TableCell>
        {DateUtility.formatDate(item.createdAt, DateFormat.date)}
      </TableCell>
      <TableCell>
        {CommonUtility.getShortAddress(item?.address || {})}
      </TableCell>
    </TableRow>
  );
}
