import { BrowserUtility } from '../browser-utility';
import { BaseService } from './base';
import { APIPath } from '../constants';
import { CommonConstant } from '../constants/common';
import { CRUDService } from './crud';

class User extends CRUDService {
  constructor() {
    super(APIPath.users);
  }

  login = reqData => BaseService.post(APIPath.login, reqData, false, false);

  verify = reqData =>
    BaseService.post(APIPath.verifyLogin, reqData, false, false);

  refreshToken = reqData => {
    const url = `${APIPath.refreshToken}`;
    return BaseService.post(url, reqData, false, true);
  };

  storeUser = user => {
    BrowserUtility.saveObj(CommonConstant.user, user);
  };

  getUser = () => BrowserUtility.getObj(CommonConstant.user);

  getToken = () => {
    const user = this.getUser();
    if (user) {
      return user.access_token || user.accessToken;
    }
    return '';
  };

  getRefreshToken = () => {
    const user = this.getUser();
    if (user) {
      return user.refreshToken;
    }
    return '';
  };

  logout = () => {
    BrowserUtility.remove(CommonConstant.user);
    BrowserUtility.remove(CommonConstant.currentRegion);
    BrowserUtility.remove(CommonConstant.tutorialVideos);
    BrowserUtility.remove(CommonConstant.lsSummaryView);
    BrowserUtility.remove(CommonConstant.tableFilter);
    BrowserUtility.remove(CommonConstant.loggedInTime);
  };

  isAuthenticated = () => {
    const token = this.getToken();
    return !!token;
  };

  getUserInfo = () => {
    const user = this.getUser();
    return user ? user.user : null;
  };

  register = data => BaseService.post(APIPath.register, data);

  authVerifyEmail = data => BaseService.post(APIPath.requestEmailCode, data);
}

const UserService = new User();
Object.freeze(UserService);
export { UserService };
