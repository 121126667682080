import { useEffect, useState } from 'react';
import { ZoneService } from '../utility/services';

export const WarehouseZonesHook = id => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const param = {
        warehouseId: id,
      };
      const res = await ZoneService.get(param);
      setData(res);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error, refresh: fetchData };
};

export const WarehouseZoneTypesHook = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { types } = await ZoneService.types();
      let options = Object.keys(types || {}).map(zoneKey => {
        const zoneType = types[zoneKey];
        return {
          text: zoneType.description || zoneKey,
          value: zoneKey,
          key: zoneKey,
        };
      });
      setData(options);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
};

export const ZoneHook = id => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [count, setCount] = useState({
    aisles: 0,
    racks: 0,
  });
  const [aloneRacks, setAloneRacks] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      let aisles = await ZoneService.zoneAisles(id);
      const racks = await ZoneService.zoneRacks(id);
      setCount({
        racks: racks?.total || 0,
        aisles: aisles?.total || 0,
      });

      aisles = aisles.aisles.map(aisle => {
        aisle.racks = racks.racks?.filter?.(x => x.aisleId === aisle._id);
        return aisle;
      });

      const racksNotInAny = racks.racks?.filter(
        x =>
          !aisles?.find(aisle => aisle.racks.find(rack => rack._id === x._id)),
      );
      setAloneRacks(racksNotInAny);
      setData(aisles);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error, refreshData: fetchData, count, aloneRacks };
};

export const ZoneDetailHook = id => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await ZoneService.getById(id);
      setData(res);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error, refresh: fetchData };
};
