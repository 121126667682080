import { CaretDown, CaretRight } from '@phosphor-icons/react';
import { MuteText } from '../../../elements';

export function MultipleVariantsToggle({ open, setOpen }) {
  return (
    <div className="d-flex flex-column align-items-start justify-content-center">
      <MuteText>Multiple Aliases</MuteText>
      <div
        onClick={() => setOpen(!open)}
        className="d-flex align-items-center cursor-pointer"
      >
        {open ? (
          <>
            <b>Collapse</b>
            <CaretDown className="ml-1" />
          </>
        ) : (
          <>
            <b>Expand</b>
            <CaretRight className="ml-1" />
          </>
        )}
      </div>
    </div>
  );
}
