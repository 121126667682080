import { BrowserUtility } from './browser-utility';
import { CommonConstant } from './constants/common';

export class CommonUtility {
  static getCurrency = () => {
    const temp = BrowserUtility.get(CommonConstant.currentRegion);
    return temp !== 'undefined' ? temp || 'USD' : 'USD';
  };

  static currencyFormat = (
    value,
    currency,
    options = {},
    decimalPoints = 2,
  ) => {
    const tempCurrency = currency || CommonUtility.getCurrency();
    return Number.isNaN(value || 0)
      ? value
      : new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: tempCurrency,
          maximumFractionDigits: decimalPoints,
          ...(options || {}),
        }).format(value || 0);
  };

  static isFunction(functionToCheck) {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === '[object Function]'
    );
  }

  static isString(x) {
    return Object.prototype.toString.call(x) === '[object String]';
  }

  static isNotEmpty = item =>
    item !== undefined && item !== null && item !== '' && item.length !== 0;

  static isNotNull = item => item !== undefined && item !== null;

  static numberWithCommas = x =>
    x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  static decimalWithCommas = (x, decimal = 2) => {
    if (Number.isNaN(x)) {
      return '-';
    }
    if (decimal) {
      const decimalValue =
        Math.round((x + Number.EPSILON) * 10 ** decimal) / 10 ** decimal;
      const formattedNumber = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: decimal,
      });
      return formattedNumber.format(Number(decimalValue));
    }
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  static objectToParams = obj => {
    let str = '';
    for (const key in obj) {
      if (obj[key] !== undefined && obj[key] !== null) {
        if (str !== '') {
          str += '&';
        }
        str += `${key}=${encodeURIComponent(obj[key])}`;
      }
    }
    return str;
  };

  static copyToClipboard = toSave => {
    const textArea = document.createElement('textarea');
    textArea.value = toSave;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  };

  static isValidArray = data => data && Array.isArray(data) && data.length > 0;

  static isValidNumber = data => !!data && !Number.isNaN(data || 'a');

  static isValidObject = data =>
    typeof data === 'object' && data ? Object.keys(data).length > 0 : false;

  static generateRandomId() {
    return Math.random().toString(36).slice(2, 11);
  }

  static compareUrls(url1, url2) {
    const normalizedUrl1 = url1.replace(/\/+$/, '');
    const normalizedUrl2 = url2.replace(/\/+$/, '');

    return normalizedUrl1 === normalizedUrl2;
  }

  static toTitleCase = phrase =>
    phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  static sortArrayObjectsByKey = (data, key) => {
    if (!this.isValidArray(data)) return [];
    return [...data].sort((a, b) => `${a[key]}`.localeCompare(`${b[key]}`));
  };

  static sortDropdownOptions = data => this.sortArrayObjectsByKey(data, 'text');

  static downloadCSV = (data, fileName) => {
    const temp = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = temp;
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  static getShortAddress = address => {
    let arr = [];
    if (address.state) {
      arr.push(address.state);
    }
    if (address.countryCode) {
      arr.push(address.countryCode);
    }

    return arr.join(', ');
  };

  static getLongAddress = address => {
    let arr = [];
    if (address.addressLine1) {
      arr.push(address.addressLine1);
    }
    if (address.addressLine2) {
      arr.push(address.addressLine2);
    }
    if (address.city) {
      arr.push(address.city);
    }
    if (address.districtOrCounty) {
      arr.push(address.districtOrCounty);
    }
    if (address.stateOrRegion) {
      arr.push(address.stateOrRegion);
    }
    if (address.postalCode) {
      arr.push(address.postalCode);
    }
    if (address.countryCode) {
      arr.push(address.countryCode);
    }
    if (address.phoneNumber) {
      arr.push(address.phoneNumber);
    }
    if (address.email) {
      arr.push(address.email);
    }

    return arr.join(', ');
  };

  static removeEmptyFields(obj) {
    for (let key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        // Recursively call for nested objects or arrays
        this.removeEmptyFields(obj[key]);

        // Delete the property if it's an empty object or array after recursion
        if (
          Object.keys(obj[key]).length === 0 ||
          (Array.isArray(obj[key]) && obj[key].length === 0)
        ) {
          delete obj[key];
        }
      } else if (
        obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === ''
      ) {
        // Delete the property if it's null, undefined, or an empty string
        delete obj[key];
      }
    }
    return obj;
  }

  static getSegmentByPosition(url, position) {
    const segments = url.split('/');

    if (position >= 0 && position < segments.length) {
      return segments[position];
    }
    return null;
  }

  static toCamelCase(str) {
    return str
      .toLowerCase() // Convert the entire string to lowercase
      .split(' ') // Split by spaces
      .map((word, index) => {
        if (index === 0) return word; // Keep the first word lowercase
        return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the first letter of subsequent words
      })
      .join(''); // Join them back together without spaces
  }

  static roundNumber = (num, decimal = 2) =>
    !isNaN(num)
      ? Math.round((parseFloat(num) + Number.EPSILON) * 10 ** decimal) /
        10 ** decimal
      : 0;

  static removeUnderscoreAndCapitalize(str) {
    if (this.isString(str) === false) return '';

    let words = str.split('_');

    let capitalizedWords = words.map(word => {
      let firstLetter = word.charAt(0).toUpperCase();
      let restOfWord = word.slice(1);
      return firstLetter + restOfWord;
    });

    let capitalizedStr = capitalizedWords.join(' ');

    return capitalizedStr;
  }

  static searchQueryCompare = (a, b) => {
    if (this.isString(a) && this.isString(b)) {
      return (
        a.toLowerCase().includes(b.toLowerCase()) ||
        b.toLowerCase().includes(a.toLowerCase())
      );
    }
    return false;
  };

  static tryParseJSONObject(jsonString) {
    try {
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
      return false;
    } catch {
      return false;
    }
  }
}
