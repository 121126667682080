import { Plus } from '@phosphor-icons/react';
import { useState } from 'react';
import { Menu, MenuItem } from 'semantic-ui-react';
import { StyledButton } from '../../../elements/Button';
import { SearchInput } from '../../../components/SearchInput';
import { AppTable } from '../../../components/AppTable';
import {
  InviteListHook,
  useDebouncedMemo,
  UsersListHook,
} from '../../../hooks';
import {
  AddUserPopup,
  InviteRow,
  UserRow,
} from '../../../page-components/settings';
import { CommonUtility, InvitesService, ToastMessage } from '../../../utility';
import { ConfirmationPopup } from '../../../components';

export function UsersTab() {
  const [menu, setMenu] = useState(1);
  const [search, setSearch] = useState('');

  const [loading, setLoading] = useState(false);
  const [popupLoading, setPopupLoading] = useState(false);

  const {
    data: userData,
    loading: userLoading,
    refresh: refreshUsers,
  } = UsersListHook();

  const {
    data: inviteData,
    loading: inviteLoading,
    refresh: refreshInvites,
  } = InviteListHook();

  const [addUserPopup, setAddUserPopup] = useState(false);
  const [removeUserPopup, setRemoveUserPopup] = useState(null);
  const [removeInvitePopup, setRemoveInvitePopup] = useState(null);

  const selectMenu = menuIndex => {
    setMenu(menuIndex);
  };

  const columns = [
    {
      text: 'Name',
      key: 'name',
      width: 4,
      textAlign: 'left',
    },
    {
      text: 'Email Address',
      key: 'EmailAddress',
      width: 4,
      textAlign: 'left',
    },
    {
      text: 'Role',
      key: 'role',
      width: 2,
      textAlign: 'left',
    },
    {
      text: '',
      width: 2,
      key: 'Actions',
      textAlign: 'left',
    },
  ];

  const invitationColumns = [
    {
      text: 'Name',
      key: 'name',
      width: 4,
      textAlign: 'left',
    },
    {
      text: 'Email Address',
      key: 'EmailAddress',
      width: 4,
      textAlign: 'left',
    },
    {
      text: 'Role',
      width: 2,
      key: 'role',
      textAlign: 'left',
    },
    {
      text: '',
      width: 2,
      key: 'Actions',
      textAlign: 'left',
    },
  ];

  const openPopup = () => {
    setAddUserPopup(true);
  };

  const closePopup = () => {
    setAddUserPopup(false);
  };

  const removeUser = user => {
    setRemoveUserPopup(user);
  };

  const closeRemoveUserPopup = () => {
    setRemoveUserPopup(null);
  };

  const removeInvite = user => {
    setRemoveInvitePopup(user);
  };
  const closeRemoveInvitePopup = () => {
    setRemoveInvitePopup(null);
  };

  const resendInvite = async inviteId => {
    try {
      setLoading(true);
      await InvitesService.resendInvite(inviteId);
      ToastMessage.success('Invite resent successfully');
      refreshInvites();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const onConfirmDeleteUser = async () => {
    try {
      setPopupLoading(true);
      await InvitesService.removeUser(removeUserPopup?._id);
      ToastMessage.success('User deleted successfully');
      refreshUsers();
      closeRemoveUserPopup();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setPopupLoading(false);
    }
  };
  const onConfirmDeleteInvite = async () => {
    try {
      setPopupLoading(true);
      await InvitesService.remove(removeInvitePopup?.token);
      ToastMessage.success('Invite deleted successfully');
      refreshInvites();
      closeRemoveInvitePopup();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setPopupLoading(false);
    }
  };

  const filteredUsers = useDebouncedMemo(
    () => {
      if (search) {
        return userData?.filter(
          user =>
            CommonUtility.searchQueryCompare(user.firstName, search) ||
            CommonUtility.searchQueryCompare(user.lastName, search) ||
            CommonUtility.searchQueryCompare(user.email, search),
        );
      }
      return userData;
    },
    [userData, search],
    100,
  );

  const filteredInvites = useDebouncedMemo(
    () => {
      if (search) {
        return inviteData?.filter(
          invite =>
            CommonUtility.searchQueryCompare(invite.firstName, search) ||
            CommonUtility.searchQueryCompare(invite.lastName, search) ||
            CommonUtility.searchQueryCompare(invite.email, search),
        );
      }
      return inviteData;
    },
    [inviteData, search],
    100,
  );

  return (
    <div className="py-3">
      <div className="d-flex justify-content-between align-items-center">
        <Menu pointing secondary className="m-0">
          <MenuItem
            name="users"
            active={menu === 1}
            onClick={() => selectMenu(1)}
          />
          <MenuItem
            name="open invitations"
            active={menu === 2}
            onClick={() => selectMenu(2)}
          />
        </Menu>
        <div className="d-flex justify-content-end">
          <StyledButton onClick={openPopup} className="flex-btn mr-3">
            <Plus className="mr-2" size={18} /> User
          </StyledButton>
          <SearchInput
            placeholder="Search Users"
            search={search}
            setSearch={e => setSearch(e)}
          />
        </div>
      </div>
      {menu === 1 ? (
        <AppTable
          data={filteredUsers}
          RowComponent={<UserRow remove={removeUser} />}
          columns={columns}
          noDataMessage="No user data found"
          loading={userLoading || loading}
          loadingRows={6}
        />
      ) : (
        <AppTable
          data={filteredInvites}
          RowComponent={
            <InviteRow
              removeInvite={removeInvite}
              resendInvite={resendInvite}
            />
          }
          columns={invitationColumns}
          noDataMessage="No user data found"
          loading={inviteLoading || loading}
        />
      )}
      <AddUserPopup
        refresh={refreshInvites}
        open={addUserPopup}
        onClose={closePopup}
      />
      <ConfirmationPopup
        open={!!removeUserPopup}
        onClose={closeRemoveUserPopup}
        title="Confirm Removing User"
        onConfirm={onConfirmDeleteUser}
        loading={popupLoading}
        message={`Are you sure you want to remove ${removeUserPopup?.firstName} with email ${removeUserPopup?.email}`}
      />
      <ConfirmationPopup
        open={!!removeInvitePopup}
        onClose={closeRemoveInvitePopup}
        title="Confirm Removing User"
        onConfirm={onConfirmDeleteInvite}
        loading={popupLoading}
        message={`Are you sure you want to remove ${removeInvitePopup?.firstName} with email ${removeInvitePopup?.email}`}
      />
    </div>
  );
}
