import { TableCell, TableRow } from 'semantic-ui-react';
import styled from 'styled-components';
import { Pencil } from '@phosphor-icons/react';
import {
  ActionEditDots,
  ActionMenuItem,
  DarkPopup,
  ItemImage,
  OrganisationAvatar,
} from '../../components';
import { MuteText } from '../../elements';

const ActionEditTableRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    img.m-auto {
      opacity: 0.3;
    }
    .action-container {
      opacity: 1 !important;
    }
  }
`;

export function InventoryItemRow({ item, openPopup }) {
  const location = item?.locationSummary?.[0] || {};

  return (
    <ActionEditTableRow>
      <TableCell>
        <ItemImage
          imageKey="p_images"
          displayImageKey="display_image"
          item={item.product}
        />
      </TableCell>
      <TableCell>
        <div>
          <div>{item.product.p_title}</div>
          <div className="d-flex">
            <MuteText className="mr-2">SKU</MuteText>
            <span>{item.product.p_sku}</span>
          </div>
        </div>
      </TableCell>
      <TableCell textAlign="center">
        <div className="d-flex justify-content-center">
          <DarkPopup
            trigger={
              <OrganisationAvatar
                name={item?.customerOrganization?.company_name}
                image={item?.customerOrganization?.logo?.url}
              />
            }
            position="top center"
          >
            {item?.customerOrganization?.company_name}
          </DarkPopup>
        </div>
      </TableCell>
      <TableCell textAlign="center">
        <div className="d-flex justify-content-center">
          <DarkPopup
            trigger={<OrganisationAvatar name={location?.warehouse?.name} />}
            position="top center"
          >
            {location?.warehouse?.name}
          </DarkPopup>
        </div>
      </TableCell>
      <TableCell>
        {item.locationSummary?.map(locationSummary => (
          <div>
            <div className="d-flex">
              <MuteText>Code</MuteText>
              <span className="ml-2">{locationSummary?.location.code}</span>
            </div>
            <div className="d-flex">
              <MuteText>Bay</MuteText>
              <span className="ml-2">
                {locationSummary?.location?.coordinates?.bay}
              </span>
            </div>
            <div className="d-flex">
              <MuteText>Level</MuteText>
              <span className="ml-2">
                {locationSummary?.location?.coordinates?.level}
              </span>
            </div>
            <div className="d-flex">
              <MuteText>Position</MuteText>
              <span className="ml-2">
                {locationSummary?.location?.coordinates?.position}
              </span>
            </div>
          </div>
        ))}
      </TableCell>
      <TableCell textAlign="center">
        <div className="d-flex">
          <MuteText>Total</MuteText>
          <span className="ml-2">{item.quantity.total}</span>
        </div>
        <div className="d-flex">
          <MuteText>Available</MuteText>
          <span className="ml-2">{item.quantity.available}</span>
        </div>
        <div className="d-flex">
          <MuteText>Allocated</MuteText>
          <span className="ml-2">{item.quantity.allocated}</span>
        </div>

        <ActionEditDots preventChangingBg on="hover" position="left center">
          <ActionMenuItem
            onClick={() => {
              openPopup(item);
            }}
          >
            <Pencil />
            Edit Quantity
          </ActionMenuItem>
        </ActionEditDots>
      </TableCell>
    </ActionEditTableRow>
  );
}
