import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { CaretDown, XCircle } from '@phosphor-icons/react';

const StyledDropdown = styled(Dropdown)`
  border: 0.5px solid ${({ theme }) => theme.colors.grey} !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  color: ${({ theme }) => theme.colors.black} !important;
  font-size: 12px !important;
  min-height: 38px !important;
  display: flex !important;
  align-items: center !important;

  &.supplierDropdown {
    min-width: 246px !important;
    .menu > .item {
      display: flex;
      align-items: center;
    }
  }

  input {
    margin-top: 5px;
  }

  &:focus-within {
    border-width: 1px !important;
  }

  &.selection.active.dropdown .menu {
    border-color: ${({ theme }) => theme.colors.grey} !important;
  }

  .divider.text {
    top: ${({ innerTextPos }) => innerTextPos || 0}px;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  .item {
    display: flex !important;
    align-items: center !important;
    column-gap: 5px !important;
    min-height: 40px;
  }

  img.ui.image {
    margin-bottom: unset !important;
  }

  .divider.default {
    top: 0px;
  }

  .dropdown.icon {
    top: 11px !important;
  }
  border-color: ${({ theme }) => theme.colors.grey} !important;

  &.ui.selection.dropdown.active,
  .ui.selection.dropdown.visible {
    z-index: 20;
  }
`;

const DropdownIcon = styled(CaretDown)`
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0.78571429em;
  right: 0.7em;
  z-index: 3;
  margin: -0.78571429em;
  padding: 0.7em;
  opacity: 0.8;
  transition: opacity 0.1s ease;
`;

const ClearIcon = styled(XCircle)`
  cursor: pointer;
  position: absolute;
  top: 0.78571429em;
  right: 0.7em;
  z-index: 3;
`;

export function CenterDropdownStyle({ ...props }) {
  return (
    <StyledDropdown
      icon={
        props.clearable === true && props.value ? (
          <ClearIcon size={18} />
        ) : (
          <DropdownIcon size={18} />
        )
      }
      {...props}
    />
  );
}

const DropdownCountryStyle = styled(StyledDropdown)`
  min-width: 11rem !important;
  .divider .image {
    margin-top: 0px !important;
  }

  border: 1px solid ${({ theme }) => theme.colors.semanticBorder} !important;

  a.ui.label {
    background: ${({ theme }) => theme.colors.box};
    box-shadow: none !important;
    border-radius: 15px;
    img {
      margin-right: 5px;
      display: inherit;
      width: 20px !important;
      height: unset !important;
    }
    display: flex !important;
    align-items: center;
  }
  div.divider.text {
    display: flex;
    align-items: center;
    img.ui.image {
      margin-top: unset;
      margin-bottom: unset;
    }
  }
  &.ui.multiple.search {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  div.item {
    min-height: 30px;
  }

  &&&& {
    div.item {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
`;

export function CountryDropdownStyle({ ...props }) {
  return (
    <DropdownCountryStyle
      icon={<DropdownIcon size={18} className="arrow-icon" />}
      {...props}
    />
  );
}
