import { isValidElement, useState } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { Form, Dropdown as ThemeDropdown } from 'semantic-ui-react';
import { CaretDown, Eye, EyeSlash } from '@phosphor-icons/react';
import PhoneInput from 'react-phone-input-2';
import { DangerText, StyledCheckbox } from './Common';
import { CommonUtility } from '../utility/common';
import 'react-phone-input-2/lib/style.css';
import { NumberMaskInput } from './CustomMaskInput';
import { CountryDropdownStyle } from './Dropdown';

const CustomField = styled(Form.Field)`
  margin-bottom: 1.5em !important;
  .custom-label {
    margin-bottom: 0.2rem;
  }
  &.required {
    .custom-label {
      label:after {
        margin: -0.2em 0 0 0.2em;
        content: '*';
        color: #ff4218;
      }
      .error-message {
        font-size: 12px;
        float: right;
        color: #ff4218;
      }
    }
  }

  &.mb-0 {
    margin-bottom: 0 !important;
  }
  &.mb-2 {
    margin-bottom: 1rem !important;
  }

  &.success {
    .hint {
      color: ${({ theme }) => theme.colors.success};
    }
  }
  &.large-checkbox svg {
    height: 20px;
    left: 3px;
  }
  &.supplier-lead-time {
    min-width: 135px;
  }
  @media (max-width: 1340px) {
    &.supplier-country .ui.dropdown .text {
      width: 150px;
      width: 10em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const MuteLabel = styled.label`
  color: ${({ theme }) => theme.colors.grey} !important;
  font-weight: normal !important;
`;

const InputContainer = styled.div`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  align-items: center;
  position: relative;

  &.similar-height {
    input {
      height: 40px;
    }
  }

  &.input-height {
    input {
      height: 37px;
    }
  }

  &.align-unset {
    align-items: unset;
  }

  .success {
    border-color: #a3c293 !important;
    color: #2c662d !important;
    background-color: #fcfff5 !important;
  }

  .relative {
    position: relative;
  }

  textarea {
    line-height: 1 !important;
  }
`;

export function ControlledTextFormField({
  control,
  name,
  defaultValue = '',
  required,
  error,
  inline,
  inputRight,
  label,
  hint,
  width,
  placeholder,
  fieldClass,
  inputContainerClass,
  onChange,
  helpText,
  helpPosition,
  hintTitleCase,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange: controlOnChange, value } }) => (
        <CustomField
          required={required}
          error={!!error}
          width={width}
          inline={inline || false}
          className={fieldClass}
        >
          {label && (
            <MuteLabel className={helpText ? 'd-flex align-items-center' : ''}>
              {label}
            </MuteLabel>
          )}
          <InputContainer inline={inline} className={inputContainerClass}>
            <input
              type="text"
              data-field-value={name}
              placeholder={placeholder || (isValidElement(label) ? '' : label)}
              onChange={e => {
                let trimmedValue = e.target.value;
                if (trimmedValue && trimmedValue.trimStart) {
                  trimmedValue = trimmedValue.trimStart();
                  trimmedValue = trimmedValue.replace(/ {2}$/g, ' ');
                }
                controlOnChange(trimmedValue);
                if (onChange) {
                  onChange(trimmedValue);
                }
              }}
              value={value}
              {...rest}
            />
            {inputRight}
          </InputContainer>
          {hint && <DangerText>{hint}</DangerText>}
        </CustomField>
      )}
    />
  );
}

const PasswordIcon = styled.div`
  position: absolute;
  right: 7px;
  top: 0px;
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
`;

export function PasswordFormField({
  control,
  name,
  value,
  required,
  error,
  inline,
  inputRight,
  label,
  hint,
  width,
  placeholder,
  fieldClass,
  defaultValue = '',
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange: controlOnChange, value } }) => (
        <CustomField
          required={required}
          error={!!error}
          width={width}
          inline={inline || false}
          className={fieldClass}
        >
          {label && <MuteLabel>{label}</MuteLabel>}
          <InputContainer inline={inline}>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder={placeholder || (isValidElement(label) ? '' : label)}
              onChange={e => {
                controlOnChange(e.target.value);
              }}
              data-field-value={name}
              value={value}
              {...rest}
            />
            <PasswordIcon>
              {showPassword ? (
                <EyeSlash
                  onClick={() => setShowPassword(!showPassword)}
                  size={18}
                />
              ) : (
                <Eye onClick={() => setShowPassword(!showPassword)} size={18} />
              )}
            </PasswordIcon>
          </InputContainer>
          {hint && <DangerText>{hint}</DangerText>}
        </CustomField>
      )}
    />
  );
}

const StyledPhoneInput = styled(PhoneInput)`
  .special-label {
    display: none;
  }
  .form-control {
    padding-left: 57px !important;
    height: 38px;
  }
  height: 38px;
  .country-list {
    width: 100%;
    bottom: 15px;
  }
  .flag-dropdown {
    position: relative;
    border: 0;
  }
  .selected-flag {
    margin-top: -20px;
  }
`;

export function ControlledPhoneFormField({
  control,
  name,
  defaultValue = '',
  required,
  error,
  inline,
  inputRight,
  label,
  hint,
  width,
  fieldClass,
  inputContainerClass,
  onChange,
  helpText,
  helpPosition,
  setDialCodeLength,
  countryCode,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange: controlOnChange, value } }) => (
        <CustomField
          required={required}
          error={!!error}
          width={width}
          inline={inline || false}
          className={fieldClass}
        >
          {label && (
            <MuteLabel className={helpText ? 'd-flex align-items-center' : ''}>
              {label}
            </MuteLabel>
          )}
          <InputContainer inline={inline} className={inputContainerClass}>
            <StyledPhoneInput
              country={countryCode || 'us'}
              data-field-value={name}
              value={value}
              onChange={(phone, data) => {
                controlOnChange(phone);
                if (setDialCodeLength) {
                  setDialCodeLength(data.dialCode.length);
                }
                if (onChange) {
                  onChange(phone);
                }
              }}
              enableSearch
              disableSearchIcon
              {...rest}
            />
            {inputRight}
          </InputContainer>
          {hint && <DangerText>{hint}</DangerText>}
        </CustomField>
      )}
    />
  );
}

export const StyledDropdown = styled(ThemeDropdown)`
  min-width: 5em !important;
  a {
    background: ${({ theme }) => theme.colors.box} !important;
    box-shadow: none !important;
    border-radius: 15px !important;
    padding: 7px 13px !important;
  }
  &.ui.selection.dropdown.active,
  .ui.selection.dropdown.visible {
    z-index: 20;
  }
  .visible.menu.transition {
    max-height: 40vh !important;
  }

  img.image {
    margin-top: ${props => props.imageMarginTop ?? 'auto !important'};
  }

  &.similar-height {
    height: 40px;
  }
`;

const DropdownIcon = styled(CaretDown)`
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0.78571429em;
  right: 0.7em;
  z-index: 3;
  margin: -0.78571429em;
  padding: 0.7em;
  opacity: 0.8;
  transition: opacity 0.1s ease;
`;

function CustomDropdown({ ...props }) {
  return <StyledDropdown icon={<DropdownIcon size={18} />} {...props} />;
}

export function DropdownFormField({
  required,
  loading,
  options: data,
  error,
  label,
  hint,
  control,
  name,
  placeholder,
  defaultValue = null,
  width,
  dropdownChanged,
  inline,
  fieldClass,
  helpText,
  ...rest
}) {
  const options =
    rest?.sorted === false ? data : CommonUtility.sortDropdownOptions(data);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <CustomField
          required={required}
          error={!!error}
          width={width}
          className={fieldClass}
        >
          {label && (
            <MuteLabel className={helpText ? 'd-flex align-items-center' : ''}>
              {label}
            </MuteLabel>
          )}
          <CustomDropdown
            inline={inline}
            loading={loading}
            placeholder={placeholder || (isValidElement(label) ? '' : label)}
            search
            searchInput={{ autoComplete: 'none' }}
            selectOnBlur={false}
            selection
            data-field-value={name}
            options={options}
            onChange={(e, item) => {
              onChange(item.value);
              if (dropdownChanged) {
                dropdownChanged(item);
              }
            }}
            value={value}
            {...rest}
          />
          {hint && <DangerText>{hint}</DangerText>}
        </CustomField>
      )}
    />
  );
}

export function NumberFormField({
  defaultValue = null,
  name,
  control,
  value,
  required,
  error,
  label,
  hint,
  width,
  placeholder,
  inputRight,
  maskOptions,
  fieldClass,
  inline,
  helpText,
  helpPosition,
  labelDescription,
  ...rest
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, value } }) => (
        <CustomField
          required={required}
          error={!!error}
          width={width}
          className={fieldClass}
          inline={inline || false}
        >
          {label && (
            <MuteLabel className={helpText ? 'd-flex align-items-center' : ''}>
              {label}
            </MuteLabel>
          )}
          <InputContainer inline={inline} {...rest}>
            <NumberMaskInput
              value={value}
              data-field-value={name}
              onChange={newValue => onChange(newValue)}
              maskOptions={maskOptions}
              placeholder={placeholder || (isValidElement(label) ? '' : label)}
              {...rest}
            />
            {inputRight}
          </InputContainer>
          {hint && <DangerText>{hint}</DangerText>}
        </CustomField>
      )}
    />
  );
}

export function CountryDropdownFormField({
  required,
  loading,
  options,
  error,
  label,
  hint,
  control,
  name,
  placeholder,
  defaultValue,
  width,
  dropdownChanged,
  fieldClass,
  labelError,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, onBlur } }) => (
        <CustomField
          required={required}
          error={!!error}
          width={width}
          className={fieldClass}
        >
          <div className="custom-label">
            {label && <MuteLabel>{label}</MuteLabel>}
            {labelError && (
              <span className="error-message">{labelError?.message}</span>
            )}
          </div>
          <CountryDropdownStyle
            loading={loading}
            placeholder={placeholder || (isValidElement(label) ? '' : label)}
            search
            data-field-value={name}
            searchInput={{ autoComplete: 'none' }}
            selectOnBlur={false}
            selection
            options={options}
            onChange={(e, item) => {
              onChange(item.value);
              if (dropdownChanged) {
                dropdownChanged(item);
              }
            }}
            value={value}
            onBlur={onBlur}
            {...rest}
          />
          {hint && <DangerText>{hint}</DangerText>}
        </CustomField>
      )}
    />
  );
}

export function ControlledTextAreaFormField({
  required,
  control,
  defaultValue = '',
  name,
  error,
  label,
  hint,
  width,
  placeholder,
  inputRight,
  fieldClass,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange: controlOnChange, value } }) => (
        <CustomField
          required={required}
          error={!!error}
          width={width}
          className={fieldClass}
        >
          <MuteLabel>{label}</MuteLabel>
          <InputContainer>
            <textarea
              placeholder={placeholder || (isValidElement(label) ? '' : label)}
              autoComplete="none"
              onChange={e => {
                controlOnChange(e.target.value);
              }}
              data-field-value={name}
              value={value}
              {...rest}
            />
            {inputRight}
          </InputContainer>
          {hint && <DangerText className="hint">{hint}</DangerText>}
        </CustomField>
      )}
    />
  );
}

export function CheckboxFormField({
  required,
  error,
  label,
  text,
  disabled,
  hint,
  control,
  name,
  width,
  defaultValue = false,
  fieldProps,
  fieldClass,
  helpText,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <CustomField
          required={required}
          error={!!error}
          width={width}
          className={fieldClass}
          {...fieldProps}
        >
          <div className="custom-label">
            {label && <MuteLabel>{label}</MuteLabel>}
          </div>
          <StyledCheckbox
            className={`mr-2 ${fieldClass}`}
            name={name}
            data-field-value={name}
            checked={value}
            label={text}
            disabled={disabled}
            onChange={(_, { checked }) => onChange(checked)}
            {...rest}
          />
          {hint && <DangerText>{hint}</DangerText>}
        </CustomField>
      )}
    />
  );
}
